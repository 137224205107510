import React from 'react';
import { Popup, Button, Loader, Container, Segment, Icon, Table, Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { toast, ToastOptions } from 'react-semantic-toasts';
import { store as toastStore } from 'react-semantic-toasts/build/toast';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import moment from 'moment';
import { getBpmRestService } from '../services/BpmRestService';
import { TaskInstance, ProcessDefinition, User, IdentityLink } from '../models';
import ClipboardButton  from './ClipboardButton';
import { ReactAngularBridge } from './ReactAngularBridge';
import './cam-form';
import './TaskForm.css'
import { ErrorMessage } from './ErrorMessage';
import { getCurrentUser } from '../services/CurrentUserService';
import { DevToolbar, PageDevConfig, getPageDevConfig } from './DevToolbar';
import { getAppConfig } from '../services/AppConfig';
import { getDevTemplatesBaseUrl } from './DevModeSettings';
import { concatPath } from './utils';

export interface TaskFormProps {
  taskId? : string;
  procDefKey?: string;
  historic?: boolean;
  history?: any;
  onTaskChanged?: () => void;
}

type FormType = 'embedded' | null;

interface TaskFormState {
  loading: boolean;
  loadError?: Error;
  task?: TaskInstance;
  formType?: FormType;
  formKey?: string;
  historic?: boolean;
  procDef?: ProcessDefinition;
  assignee?: string;
  userId?: string;
  candidates?: User[];
  assignDlgOpen?: boolean;
  claimDisabled?: boolean;
  formInitialized?: boolean;
  formError?: string;
  taskResult?: {id: string, name: string};
  devMode?: boolean;
  pageKey?: string;
  devConfig?: PageDevConfig;
  defaultDevTemplate?: string;
}

interface NotifOptions {
  type: 'info' | 'success' | 'warning' | 'error';
  status: string;
  message: string;
  duration: number;
}

const formatDateTime = (d: Date) => d && moment(d).format('YYYY-MM-DD HH:mm:ss');

const isUserCandidate = (user: User, links: IdentityLink[]) => links.some(link => link.userId === user.id || user.membership?.includes(link.groupId));

export class TaskForm extends React.Component<TaskFormProps, TaskFormState> {
  
  private bpmService = getBpmRestService();
  private definedFormKey: string;
  private devMode: boolean;
  private pageKey: string;
  private viewed: boolean;

  constructor (props: TaskFormProps) {
    super(props);
    this.state = {
      loading: true,
      historic: props.historic
    };
  }

  async componentDidMount() {
    getAppConfig().events.on('developerMode', this.updateDevMode);
    const devMode = this.devMode = getAppConfig().developerMode;
    this.setState({devMode});
    this.load()
  }

  async componentDidUpdate(prevProps: TaskFormProps){
    if ( this.props.taskId !== prevProps.taskId || this.props.historic !== prevProps.historic) {
      this.clearNotifications();
      this.load()
    }
  }

  componentWillUnmount() {
    getAppConfig().events.off('developerMode', this.updateDevMode);
    this.clearNotifications(); 
  }

  async load() {
    const historic = this.state.historic || this.props.historic;
    this.setState({
      loading: true,
      loadError: null,
      task: null,
      historic: historic,
      procDef: null,
      assignee: null,
      candidates: null,
      formType: null,
      formKey: null,
      pageKey: null,
      defaultDevTemplate: null,
      devConfig: null,
      formInitialized: false,
      formError: ''
    })
    try {
      const userId = getCurrentUser().getUserId();
      const task = await this.bpmService.getTask(this.props.taskId, historic);
      this.definedFormKey = task.formKey;
      const procDef = task.processDefinition;
      const pageKey = this.pageKey = `${task.processDefinitionKey}/${task.taskDefinitionKey}`; 

      this.setState({
        task,
        procDef,
        pageKey,
        assignee: task.assignee,
        userId,
        loading: false,
        loadError: null
      })

      this.updateTemplate();

      if (!historic && task.identityLinks) {
        const identityLinks = task.identityLinks;
        const svGroupIds = (await getCurrentUser().getSupervisedGroups()).map(item => item.id);
        if (svGroupIds.length > 0) {
          const candidates = (await this.bpmService.getUsersFromGroups(svGroupIds)).filter(user => isUserCandidate(user, identityLinks));
          this.setState({candidates});
        }
      }

    } catch (e) {
      this.setState({ loading: false, loadError: e });
      if ((e.response || e.cause?.response)?.status === 404)
        this.taskChanged();
    }
  }

  private updateTemplate() {
    let formType: FormType;
    let formKey: string;
    let formInitialized = false;
    const defaultDevTemplate = this.definedFormKey?.replace(/^embedded:/, '').replace(/^deployment:/, '');
    const devConfig = this.devMode ? getPageDevConfig('taskForm',this.pageKey) : null;

    if (devConfig?.template?.enabled) {
      formType = 'embedded';
      formKey = devConfig.template.url || (defaultDevTemplate ? concatPath(getDevTemplatesBaseUrl(), defaultDevTemplate) : null);
    } else if (/^embedded:/.test(this.definedFormKey)) {
      formType = 'embedded';
      formKey = this.definedFormKey.replace(/^embedded:/, '');
    } else {
      formType = null;
      formKey = this.definedFormKey;
      formInitialized = true; // no form
    }

    this.setState({
      formType,
      formKey,
      defaultDevTemplate,
      formInitialized,
      devConfig
    })
  }

  private updateDevMode = (devMode: boolean) => {
    this.devMode = devMode;
    this.setState({devMode});
    if (getPageDevConfig('taskForm', this.pageKey)?.template?.enabled) {
      this.refreshTemplate();
    }
  }

  private devConfigChanged = () => {
    this.refreshTemplate();
  }

  private refreshTemplate = () => {
    this.setState({formInitialized: false, formType: null, formKey: null}, this.updateTemplate);
  }

  private taskChanged() {
    if (this.props.onTaskChanged)
      this.props.onTaskChanged();
  }

  private onFormCompletionCallback() {
    this.taskChanged();
    this.props.history ? this.props.history.goBack() : window.close();
  }
  
  private onFormInitialized() {
    this.setState({
      formInitialized: true,
      formError: ''
    });
    if (!this.viewed) {
      this.bpmService.setTaskViewed(this.state.task.id, true);
      this.viewed = true;
    }
  }

  private onFormInitializationFailed(err: Error) {
    this.setState({
      formInitialized: true,
      formError: `Form initialization error: ${err.message}`
    });
  }

  private notifs: ToastOptions[] = [];

  private notification(notif: ToastOptions) {
    const ref = {notif: null};
    const delNotif = () => this.notifs.splice(this.notifs.indexOf(ref.notif), 1)
    toast(notif, delNotif, null, delNotif);
    const item = toastStore.data[toastStore.data.length - 1]
    ref.notif = item;
    this.notifs.push(item);
  }

  private clearNotifications() {
    this.notifs.forEach((item) => toastStore.remove(item));
  }

  private async claim() {
    this.setState({claimDisabled: true})
    try {
      await this.bpmService.claimTask(this.state.task.id, this.state.userId);
      this.setState({assignee: this.state.userId});
      this.taskChanged();
    } catch(e) {
      this.notification({
        type: 'error',
        title: 'Error',
        description: `Claim failed: ${e.message}`,
        time: 10000
      });
    } finally {
      this.setState({claimDisabled: false})
    }
  }

  private async assign(userId: string) {
    this.setState({claimDisabled: true})
    try {
      await this.bpmService.assignTask(this.state.task.id, userId);
      this.setState({assignee: userId, assignDlgOpen: false});
      this.taskChanged();
    } catch(e) {
      this.notification({
        type: 'error',
        title: 'Error',
        description: `Assign failed: ${e.message}`,
        time: 10000
      });
    } finally {
      this.setState({claimDisabled: false})
    }
  }

  private async unclaim() {
    this.setState({claimDisabled: true})
    try {
      await this.bpmService.unclaimTask(this.state.task.id);
      this.setState({assignee: null});
      this.taskChanged();
    } catch(e) {
      this.notification({
        type: 'error',
        title: 'Error',
        description: `unclaim failed: ${e.message}`,
        time: 10000
      });
    } finally {
      this.setState({claimDisabled: false})
    }
  }

  private isDisabled() {
    return this.state.assignee !== this.state.userId
      && !this.state.historic;
  }

  private renderHeader() {
    const {task} = this.state;
    if (!task) {
      return (
        <>-</>
      )
    }
    const text =  task.name || task.taskDefinitionKey;
    const href = `${window.location.href.split('#')[0]}#/task/${task.id}`;
    return (
      <>
        {text}
        <ClipboardButton
          text={`${text} (${href})`}
          html={`<a target="_blank" rel="noopener noreferrer" href="${href}">${text}</a>`}
        />
      </>
    )
  }

  private renderProcessLink() {
    const {task, procDef} = this.state;
    const text = procDef && (procDef.name || procDef.key);
    const path = `#/process-info/${task.processInstanceId}`;
    const href = `${window.location.href.split('#')[0]}${path}`;
    return (
      <>
        <Popup
          trigger={
            <span className='task-info-label'>
              <Icon name='sitemap' size='small'/>
            </span>
          }
          content={'Process'}
          position='top center'
          size="tiny"
          inverted
        />
        <span className='task-info-label'>
          {text}
        </span>
        <Popup
          trigger={
            <a target='_blank' rel='noopener noreferrer' href={path}>
              <Icon name="external alternate" size='small' style={{paddingLeft: '0.4em'}}/>
            </a>
          }
          content={'View Process Info'}
          position='top center'
          size="tiny"
          inverted
        />
        <ClipboardButton
          text={`${text} (${href})`}
          html={`<a target="_blank" rel="noopener noreferrer" href="${href}">${text}</a>`}
        />
      </>
    )
  }


  private renderAssignee() {
    return (
      <>
        <Popup
          trigger={
            <span className='task-info-label'>
              <Icon name='user' size='small'/>
            </span>
          }
          content='Assignee'
          position='top center'
          size="tiny"
          inverted  
        />
        { this.state.assignee ? 
          <>
            <span>{this.state.assignee}</span>
            <Popup
              trigger={
                <button onClick={()=>this.unclaim()} className="btn-link" disabled={this.state.claimDisabled}>
                  <Icon name='remove' size='small'/>
                </button>              
              }
              content={this.state.assignee === this.state.userId ? 'Unclaim' : 'Unassign'}
              position='top right'
              size="tiny"
              inverted      
            />
          </>
          :
          <>
            <button className="btn-link" onClick={()=>this.claim()} disabled={this.state.claimDisabled}>
              Claim
            </button>
            { this.state.candidates ?
              <>
                <span className='inline-divider'/>
                <Popup
                  trigger={
                    <button className='btn-link' disabled={this.state.claimDisabled}>
                      Assign
                    </button>
                  }
                  position='top right'
                  wide='very'
                  on='click'
                  eventsEnabled
                  onClose={() => this.setState({assignDlgOpen: false})}
                  onOpen={() => this.setState({assignDlgOpen: true})}
                  open={this.state.assignDlgOpen}  
                >
                  <Table compact striped selectable>
                    <Table.Body>
                      {this.state.candidates?.map(item => (
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.firstName} {item.lastName}</Table.Cell>
                          <Table.Cell>
                            <button className="btn-link" onClick={()=>this.assign(item.id)} disabled={this.state.claimDisabled}>
                              {item.id}
                            </button>
                          </Table.Cell>
                        </Table.Row>)
                      )}
                    </Table.Body>
                  </Table>
                </Popup>
              </> : ''
            }
          </>
        }
      </>
    )
  }

  private renderInfoItem(item: {lab: any, val: any, withClipboard?: boolean, clipboardText?: string, clipboardHtml?: string}) {
    return (
      <Table.Row key={item.lab} >
        <Table.Cell className="table-label">{item.lab}</Table.Cell>
        <Table.Cell>
          {item.val !== undefined && item.val != null ? item.val : "-"}
          {(item.clipboardText || (item.withClipboard && item.val)) &&
            <ClipboardButton text={item.clipboardText || item.val} html={item.clipboardHtml}/>}</Table.Cell>
      </Table.Row>
    )
  }

  private renderSysInfo() {
    const {task, taskResult} = this.state;
    function taskStateToText() {
      if (!task.endTime) {
        return 'Active';
      } else if (task.deleteReason === 'completed') {
        return (
          <>
            Completed{
              taskResult && (
                <>
                  <span>: </span>
                  <span style={{fontStyle:'italic'}}>
                    {taskResult.name || taskResult.id || '-'}
                  </span>
                </>
              )
            }
          </>
        )
      } else {
        return 'Closed automatically';
      }
    }
  
    return (
      <>
        <Table compact celled>
          <Table.Body>
            {this.renderInfoItem({lab: 'Task state', val: taskStateToText()})}
            {this.renderInfoItem({lab: 'Start date', val: formatDateTime(task.startTime)})}
            {this.renderInfoItem({lab: 'End date', val: formatDateTime(task.endTime) || '-'})}
            {task.deleteReason === 'completed' ?
               this.renderInfoItem({lab: 'Performer', val: task.performer || '-', withClipboard: true}) :
               this.renderInfoItem({lab: 'Assignee', val: task.assignee || '-', withClipboard: true})
            }
           </Table.Body>
        </Table>
      </>
    )
  }

  render() {
    return (<>
      {this.state.devMode ? 
        <DevToolbar
          pageType='taskForm'
          pageKey={this.state.pageKey}
          defaultTemplate={this.state.defaultDevTemplate}
          onChange={this.devConfigChanged}
          onRefreshTarget={this.refreshTemplate}
        /> : ''
      }
      {this.state.loading ?
        <Loader active />  
      :
        <Container fluid className='form-page-container'>
          <div className='app-toolbar'>
            <div>
              { this.props.history && this.props.history.length > 1 &&
                <Popup
                  trigger={
                    <Button circular basic size='medium' icon='arrow left' className='app-toolbar-btn btn-level-up'
                      onClick={ () => { this.props.history.goBack() } }
                    />
                  }
                  content='Back to list'
                  position='bottom left'
                  size="tiny"
                  inverted
                />
              }
              <div className='app-page-header'>
                {this.renderHeader()}
              </div>
            </div>
          </div>

          <ErrorMessage error={this.state.loadError || this.state.formError}/>

          { !this.state.loadError &&
          <>
            <div className='task-info-panel'>
              <div>
                {this.renderProcessLink()}
              </div>
              { !this.state.historic && <>
                <div>
                  <Popup
                    trigger={
                      <span className='task-info-label'>
                        <Icon name='calendar alternate' size='small'/>
                        created {moment.duration(this.state.task?.startTime?.getTime() - Date.now()).humanize(true)}
                      </span>
                    }
                    content={`Created on ${this.state.task?.startTime?.toString()}`}
                    position='top center'
                    size="tiny"
                    inverted      
                  />
                </div>
                <div>
                  { this.renderAssignee() }
                </div>
              </> }
            </div>
            { this.state.historic &&
            <Segment>
              { this.renderSysInfo() }  
            </Segment>
            }
            <Segment className={`form-container ${this.isDisabled() ? 'disabled-form' : ''}`}>
              <Loader active={!this.state.formInitialized} />
              <div style={{display: !this.state.formInitialized ? 'none' : ''}}>
                {
                  !this.state.formType &&
                  <Message warning content='Form not provided!'/>
                }
                { this.state.formType === 'embedded' &&
                  <ReactAngularBridge 
                    appName='cam.tasklist.form'
                    template='<div cam-tasklist-form params="params" handlers="handlers" options="options"/>'
                    bindings={{
                      params: {
                        taskId: this.props.taskId,
                        formKey: this.state.formKey,
                        historic: this.state.historic,
                        authentication: {name: this.state.userId},
                        taskResultConsumer: this.state.historic ? (res: {id: string, name: string}) => this.setState({taskResult: res}) : null
                      },
                      handlers: {
                        onFormCompletionCallback: () => this.onFormCompletionCallback(),
                        onFormInitialized: () => this.onFormInitialized(),
                        onFormInitializationFailed: (err: Error) => this.onFormInitializationFailed(err),
                        onNotification: (notifOpt: NotifOptions) => this.notification({
                          type: notifOpt.type,
                          title: notifOpt.status,
                          description: notifOpt.message,
                          time: notifOpt.duration || 0
                        }),
                        onClearNotifications: () => this.clearNotifications()
                      },
                      options: {
                        disableCompleteButton: this.isDisabled()
                      }
                    }}
                  />
                }
              </div>
            </Segment>
          </>
          }
        </Container>
      }
    </>)
  }
}
  
