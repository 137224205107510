import React from 'react';
import { NotificationService } from '../services/BpmNotificationService';
import { TaskDef, TaskListItem, TaskGroupViewedItem, TagItem, TaskListProcessing, TaskListTemplate, ExtColDef, TaskGroupMetadata } from '../models';
import { Popup, Button, Label, Container, DropdownItemProps } from 'semantic-ui-react';
import { AgGridReact } from '@ag-grid-community/react';
import { GridApi, ColDef, ValueGetterParams, GridReadyEvent, ColumnState, ColumnEvent, ICellRendererParams } from '@ag-grid-community/core';
import { getBpmRestService } from '../services/BpmRestService';
import { getAppConfig } from '../services/AppConfig';
import ModalWindow from './TasklistProcessing';
import { TaskForm } from './TaskForm'
import './ag-grid-modules';
import 'semantic-ui-css/semantic.min.css';
import './ag-grid.css';
import '../styles.css';
import './TasklistGroup.css';
import { ErrorMessage } from './ErrorMessage';
import { getCurrentUser } from '../services/CurrentUserService';
import TasklistAssignment from './TasklistAssignment';
import { Toaster } from '../services/Toaster';
import { formatDateTimeM, formatAge, compareDates, compareFilterDates, highlightDueDate, formatDateVar, formatDateTimeS, concatPath } from './utils';
import { isNumber } from 'angular';
import moment from 'moment';
import { DevToolbar, PageDevConfig, getPageDevConfig } from './DevToolbar';
import { getDevTemplatesBaseUrl } from './DevModeSettings';


function scopedEval(ctx: {[key: string]: any}, expr: string) {
  const evaluator = Function.apply(null, [...Object.keys(ctx), 'expr', "return eval('expr = undefined;' + expr)"]);
  return evaluator.apply(null, [...Object.values(ctx), expr]);
}

function compileHandlers(c: ColDef, ctx: {[key: string]: any}) {
  function isFn(s: any) {
    return typeof(s) === 'string' && /^[\s\n]*((\((.|\n)*\)[ \t]*=>)|(\w+[ \t]*=>)|(function[\s\n]*\())/.test(s);
  }
  function isPredefFn(s: any) {
    return typeof(s) === 'string' && typeof(ctx[s]) === 'function';
  }
  if (c.cellClass && isFn(c.cellClass)) {
    c.cellClass = scopedEval(ctx, c.cellClass as string);
  }
  if (c.cellStyle && isFn(c.cellStyle)) {
    c.cellStyle = scopedEval(ctx, c.cellStyle as any);
  }
  if (c.valueGetter && (isFn(c.valueGetter) || isPredefFn(c.valueGetter))) {
    c.valueGetter = scopedEval(ctx, c.valueGetter as string);
  }
  if (c.valueFormatter && (isFn(c.valueFormatter) || isPredefFn(c.valueFormatter))) {
    c.valueFormatter = scopedEval(ctx, c.valueFormatter as string);
  }
  if (c.tooltipValueGetter && (isFn(c.tooltipValueGetter) || isPredefFn(c.tooltipValueGetter))) {
    c.valueGetter = scopedEval(ctx, c.tooltipValueGetter as any);
  }
  if (c.cellRenderer && (isFn(c.cellRenderer) || isPredefFn(c.cellRenderer))) {
    c.cellRenderer = scopedEval(ctx, c.cellRenderer as string);
  }
}

function mergeColDefs(baseColDefs: ColDef[], addColDefs: ExtColDef[]): ColDef[] {
  const res: ColDef[] = [];
  const base = [...baseColDefs]
  const add = [...addColDefs];
  
  for(let i = 0, a = add[i]; i < add.length; a = add[++i]) {
    if (!a.colId) continue;
    // merge colDefs by colId
    for(let j = 0, b = base[j]; j < base.length; b = base[++j]) {
      if (b.colId === a.colId) {
        if (isNumber(a.insertAt)) {
          add[i] = {...b, ...a};
          base[j] = null;
        } else {
          base[j] = {...b, ...a};
          add[i] = null;  
        }
      }
    }
  }
  // put the base items and insert additional items that have insertAt property
  for(let i = 0, b = base[i]; i < base.length; b = base[++i]) {
    if (!b) continue;
    for(let j = 0, a = add[j]; j < add.length; a = add[++j]) {
      if(a && isNumber(a.insertAt) && a.insertAt <= i) {
        res.push(a)
        add[j] = null;
      }
    }
    res.push(b);
  }
  // put the rest of additional items
  for(let j = 0, a = add[j]; j < add.length; a = add[++j]) {
    if(a) {
      res.push(a)
    }
  }
  return res;
}


export interface TasklistGroupProps {
  groupId?: string;
  procDefKey?: string;
  taskDefKey?: string;
  userId?: string;
  claimedOnly?: boolean;
  taskId?: string;
  query?: string;
  history?: any;
}

type TaskGroupViewedMap = {[key: string]: TaskGroupViewedItem}

interface TasklistGroupState {
  groupDef?: TaskGroupMetadata;
  list?: TaskListItem[];
  defaultColDef: ColDef;
  gridColDefs?: ColDef<TaskListItem>[];
  loading: boolean;
  reloadDisabled?: boolean;
  loadError?: Error;
  procDefName: string;
  taskDefName: string;
  oldViewed: TaskGroupViewedMap;
  showModal: boolean;
  tagsList: TagItem[];
  hasCustomCols?: boolean;
  tableLoading: boolean;
  showFilter?: boolean;
  listProcessing?: TaskListProcessing;
  assigneeGroupId?: string;
  assigneeOptions?: DropdownItemProps[];
  showAssign?: boolean;
  showUnassign?: boolean;
  hasSelectedRows?: boolean;
  hasSelectedAssigned?: boolean;
  devMode?: boolean;
  pageKey?: string;
  devConfig?: PageDevConfig;
  defaultDevTemplate?: string;
}

export class TasklistGroup extends React.Component<TasklistGroupProps, TasklistGroupState> {
  private devMode: boolean;
  private pendingDevMode: boolean;
  private pageKey: string;
  private toaster = new Toaster();
  private notifService: NotificationService;
  private lastViewed: Date | null = null;
  private lastSentToAgent: Date | null = null;
  private varNames: string[] = [];
  private needBusinessKey: boolean;
  private gridApi: GridApi = null;
  private filterState: any;
  private taskDefs: TaskDef[];
  private metaLoaded: boolean;
  private dirty: boolean
  private gridColState: ColumnState[];
  private usersMap: {[key: string]: string} = {};

  private defaultColDef: ColDef = {
    autoHeight: true,
    cellClass: 'cell-wrap-text',
    resizable: true,
    filter: true,
    floatingFilter: false,
    filterParams: {
      newRowsAction: 'keep',
      suppressMiniFilter: true,
      buttons: ['reset']
    },
    suppressMenu: true
  };

  private selectionColDef: ColDef = {
    colId: 'selected',
    headerName: '',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    width: 50,
    showDisabledCheckboxes: true,
    checkboxSelection: true,
    filter: false
  };

  defaultGridColDefs: ColDef<TaskListItem>[] =
    [
      {
        colId: 'assignee',
        headerName: 'Assignee',
        headerTooltip: 'Task assignee',
        valueGetter: params => this.usersMap[params.data.assignee] || params.data.assignee,
        sortable: true,
        width: 110,
        tooltipValueGetter: params => params.data.assignee,
        tooltipComponentParams: {parentContext: this.context},
        filter: 'agTextColumnFilter',
      },
      {
        colId: 'name',
        headerName: 'Task name',
        field: 'name',
        width: 190, 
        sortable: true,
        valueGetter: params => this.getTaskName(params),
        cellRenderer: (params: ICellRendererParams) => this.renderTaskName(params),
        cellClass: 'cell-wrap-text',
        filter: 'agTextColumnFilter'
      },
      {
        colId: 'created',
        headerName: 'Created',
        field: 'created',
        sortable: true,
        width: 160,
        valueFormatter: params => formatAge(params.value),
        tooltipValueGetter: params => formatDateTimeM(params.value),
        comparator: (d1, d2) => compareDates(d1, d2),
        filter: 'agDateColumnFilter',
        filterParams: { 
          newRowsAction: 'keep',
          comparator: compareFilterDates,
          buttons: ['reset'],
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
      },
      {
        hide: true,
        colId: 'due',
        headerName: 'Due',
        field: 'due',
        sortable: true,
        width: 160,
        valueFormatter: params => formatAge(params.value),
        tooltipValueGetter: params => formatDateTimeM(params.value),
        comparator: (d1, d2) => compareDates(d1, d2),
        cellStyle: params => highlightDueDate(params.value),
        filter: 'agDateColumnFilter',
        filterParams: { 
          newRowsAction: 'keep',
          comparator: compareFilterDates,
          buttons: ['reset'],
          inRangeInclusive: true,
          suppressAndOrCondition: true
        }
      }
    ];

  constructor(props: TasklistGroupProps) {
    super(props);
    this.state = {
      defaultColDef: this.defaultColDef,
      loading: true,
      procDefName: '',
      taskDefName: '',
      oldViewed: null,
      showModal: false,
      tagsList: [],
      tableLoading: false
    };
  }

  async componentDidMount() {
    getAppConfig().events.on('developerMode', this.updateDevMode);
    const devMode = this.devMode = this.pendingDevMode = getAppConfig().developerMode;
    this.setState({devMode});
    this.update();
  }

  componentDidUpdate(prevProps: TasklistGroupProps): void {
    if (this.props.groupId !== prevProps.groupId || this.props.procDefKey !== prevProps.procDefKey || this.props.taskDefKey !== prevProps.taskDefKey || this.props.userId !== prevProps.userId || this.props.claimedOnly !== prevProps.claimedOnly) {
      this.update();
      this.toaster.clear();
    } else if (!this.props.taskId && this.devMode !== this.pendingDevMode) {
      this.updateDevMode(this.pendingDevMode)
    } else if (this.dirty && !this.props.taskId) {
      this.dirty = false;
      this.loadList();
    }
  }

  componentWillUnmount() {
    getAppConfig().events.off('developerMode', this.updateDevMode);
    this.toaster.clear();
  }

  async update() {
    this.metaLoaded = false;
    this.filterState = null;
    this.varNames = null;
    this.setState({
      loading: true,
      tableLoading: true,
      gridColDefs: null, 
      tagsList: null,
      hasCustomCols: false,
      listProcessing: null,
      assigneeGroupId: null,
      defaultDevTemplate: null,
      devConfig: null,
      assigneeOptions: null,
      list: null
    });
    try {
      if (this.props.groupId || (this.props.procDefKey && this.props.taskDefKey)) {
        const pageKey = this.props.groupId ? this.props.groupId : `${this.props.procDefKey}/${this.props.taskDefKey}`;
        let groupDef = this.state.groupDef;
        if (this.pageKey != pageKey) {
          this.setState({
            pageKey: null,
            groupDef: null,
            procDefName: '',
            taskDefName: '',
          });
          groupDef = await getBpmRestService().getTaskGroupMetadata(this.props.groupId, this.props.procDefKey, this.props.taskDefKey);
          this.taskDefs = groupDef.taskDefKeys;
          this.pageKey = pageKey;
        }

        const devConfig = this.devMode ? getPageDevConfig('taskList', this.pageKey) : null;
        const defaultDevTemplate = groupDef.resourceName;

        this.setState({
          pageKey,
          groupDef,
          procDefName: groupDef.processName,
          taskDefName: groupDef.taskName,
          defaultDevTemplate,
          devConfig
        });
      
        let template: TaskListTemplate;
        if (devConfig?.template?.enabled) {
          const url = devConfig.template.url || (defaultDevTemplate ? concatPath(getDevTemplatesBaseUrl(), defaultDevTemplate) : null);
          template = await getBpmRestService().getTaskListCustomTemplate(url);
        } else if (groupDef.deploymentId && groupDef.resourceId) {
          template = await getBpmRestService().getTaskListTemplate(groupDef.deploymentId, groupDef.resourceId)
        } else {
          template = null;
        }
    
        this.varNames = this.extractVarNames(template);
        const colDefs = template?.colDefs || [];
        const tagsList = template?.tags || [];
        const hasCustomCols = colDefs.length > 0 || tagsList.length > 0;
        const listProcessing = template?.processing;
        const assignerGroupId = template?.assignment?.assignerGroupId;
        let assigneeGroupId: string = null;
        if (assignerGroupId && (await getCurrentUser().getUserGroups()).some(item => item.id === assignerGroupId)) {
          assigneeGroupId = template.assignment.assigneeGroupId || assignerGroupId.replace(/-teamlead$/, '-team');
        }
        // set handlers
        this.compileHandlers(colDefs);
        this.setTagsRenderers(colDefs)
        // merge
        const gridColDefs = [];
        if (listProcessing || assigneeGroupId) {
          gridColDefs.push(this.selectionColDef);
        }
        mergeColDefs(this.defaultGridColDefs, colDefs).forEach(item => {gridColDefs.push(item)});
    
        this.setState({
          gridColDefs,
          tagsList,
          hasCustomCols,
          listProcessing,
          assigneeGroupId
        });

      } else {
        this.pageKey = null;
        this.taskDefs = null;
        this.setState({
          pageKey: null,
          groupDef: null,
          procDefName: '',
          taskDefName: '',
          gridColDefs: [],
          tagsList: [],
          hasCustomCols: false,
          listProcessing: null,
          assigneeGroupId: null,
          defaultDevTemplate: null,
          devConfig: null,
          assigneeOptions: null,
          list: []
        });
      }

      this.metaLoaded = true;
      this.loadList();
    } catch (e) {
      this.setState({ loadError: e, list: [] });
    } finally {
      this.setState({ loading: false, tableLoading: false});
    }
  }

  private updateDevMode = (devMode: boolean) => {
    if (this.props.taskId) {
      this.pendingDevMode = devMode;
    } else {
      this.devMode = this.pendingDevMode = devMode;
      this.setState(
        {devMode},
        () => {
          if (getPageDevConfig('taskList', this.pageKey)?.template?.enabled) {
            this.refreshTemplate();
          }
        }
      );
    }
  }

  private devConfigChanged = () => {
    this.refreshTemplate();
  }

  private refreshTemplate = () => {
    this.update();
  }

  private extractVarNames(template: TaskListTemplate) {
    function addVar(v: string) {
      if (v && !vars.includes(v)) {
        vars.push(v);
      }
    }
    const vars: string[] = [];
    template?.colDefs?.forEach(item => {
      if (item.field?.includes(',')) {
        const vars = item.field.split(',');
        vars.forEach(v => addVar(v.trim()));
        item.field = vars[0] as any;
      } else {
        addVar(item.field);
      }
      item.tags?.forEach(tag => addVar(tag.field||tag.colId));
    });
    template?.tags?.forEach(tag => {addVar(tag.field||tag.colId)});
    return vars;
  }

  private compileHandlers(colDefs: ColDef[]) {
    const ctx = {
      'taskLinkRenderer': this.renderAsTaskLink,
      'renderAsTaskLink': this.renderAsTaskLink,
      'formatDateTimeM': formatDateTimeM,
      'formatDateTimeS': formatDateTimeS,
      'formatDateVar': formatDateVar,
      'formatAge': formatAge,
      'moment': moment
    }
    colDefs.forEach(col => {
      compileHandlers(col, ctx);
    })
  }

  private setTagsRenderers(colDefs: ExtColDef[]) {
    colDefs.forEach(col => {
      const {tags, cellRenderer} = col;
      if (tags && tags.length > 0) {
       if (cellRenderer && typeof(cellRenderer) === 'function') {
          col.cellRenderer = (params: ICellRendererParams) => (
            <>
              { cellRenderer(params) }
              { this.renderTags(tags, params.data) }
            </>
          );
        } else {
          col.cellRenderer = (params: ICellRendererParams) => this.renderTags(tags, params.data);
        }
      }
    })
  }

  handleModal = (showModal: boolean, updateList: boolean) => {
    this.setState({showModal: showModal}, () => {
        if (updateList) {
          this.loadList();
        }
      }
    )
  }

  async loadList(silent = false) {
    if (!(this.props.groupId || (this.props.procDefKey && this.props.taskDefKey))) {
      this.setState({ list: [] });
      return;
    }
    if (!this.metaLoaded) {
      this.update();
      return;
    }

    if(!silent) {
      this.setState({ loading: true, reloadDisabled: true });
      setTimeout(() => this.setState({ reloadDisabled: false }), 4000);
    }

    try {
      const list = await getBpmRestService().getTaskGroup(this.taskDefs, this.varNames.join(','), this.needBusinessKey, this.props.claimedOnly );
      const usersMap = this.usersMap;
      const userIds = [];
      list.forEach((item) => {
        if(item.variables) {
          Object.entries(item.variables).forEach(entry => {item[entry[0]] = entry[1]});
        }
        if (item.assignee && !usersMap[item.assignee]) {
          userIds.push(item.assignee);
        }
      })
      if (userIds.length > 0) {
        const users = await getBpmRestService().getUsers(userIds);
        users.forEach(user => {usersMap[user.id] = (user.firstName + (user.lastName ? ' ' + user.lastName : '')) || user.id})
      }
      this.setState({
        list: list,
        loadError: null
      });
    } catch (e) {
      this.setState({
        loadError: e,
        list: []
      });
    } finally {
      this.setState({ loading: false, hasSelectedRows: false, hasSelectedAssigned: false })
    }
  }

  private async loadAssigneeOptions() {
    if (this.state.assigneeOptions) {
      return;
    }
    try {
      const teamId = this.state.assigneeGroupId;
      if (teamId) {
        const userOptions = (await getBpmRestService().getUsersFromGroup(teamId))
          .map(user => ({key: user.id, value: user.id, text: `${user.firstName} ${user.lastName} (${user.id})`}));
        this.setState({assigneeOptions: userOptions})
      }
    } catch (e) {
      this.setState({ loadError: e})
    }  
  }

  private toggleFilter = () => {
    const showFilter = !this.state.showFilter;
    const defaultColDef = {...this.defaultColDef, floatingFilter: showFilter};
    const colState = this.gridApi?.getColumnState();
    const gridColDefs = colState ? colState.map(s => {
        const d = this.state.gridColDefs.find(d => d.colId === s.colId);
        d.width = s.width;
        return d;
      }) : this.state.gridColDefs;
    this.setState({showFilter, defaultColDef, gridColDefs}, () => {
      this.gridApi?.applyColumnState({state: colState, applyOrder: true});
    });
    if (this.gridApi) {
      if (showFilter) {
        this.gridApi.setFilterModel(this.filterState);
      } else {
        this.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(null);
      }
    }
  }

  private gridReady = (evt: GridReadyEvent) => {
    this.gridApi = evt.api;
    this.gridApi.applyColumnState({state: this.gridColState, applyOrder: true});
  }

  private selectRow = () => {
    const rows = this.gridApi?.getSelectedRows();
    this.setState({hasSelectedRows: rows?.length > 0, hasSelectedAssigned: rows.some(item => item.assignee)});
    rows.some
  }

  private colStateChanged = (evt: ColumnEvent) => {
    this.gridColState = evt.api.getColumnState();
  }

  private getListItemClassName = (): string[] => {
    const classes = [];
    classes.push('prioritized');
    return classes;
  }

  private getTaskName(params: ValueGetterParams<TaskListItem, any>) {
    return (this.state.hasCustomCols ?
      params.data.name?.replace(/\[.*?\]/, '').replace(/\(.*?\(.*?\).*?\)|\(.*?\)/, '').trim() || params.data.name
      : params.data.name) || params.data.taskDefKey || params.data.id;
  }

  private getTaskLink(data: TaskListItem) {
    return `#/tasklist/${this.props.claimedOnly ? 'claimed/' : ''}task/${data?.id}${this.props.query}`;
  }

  private renderTags(tags: TagItem[], data: TaskListItem) {
    if (tags.length === 0) {
      return '';
    }
    return (
      <Label.Group className='tag-label-group' size='tiny'>
        {
          tags.map((tag) => {
            const val = data?.[tag.field || tag.colId];
            if (!val) {
              return '';              
            }
            return (
              <Label key={tag.field || tag.colId} tag color={tag.color}>
                {tag.prefix? `${tag.prefix} ${val}` : val}
              </Label>
            )
          })
        }
      </Label.Group>
    )
  }

  private renderTaskName(params: ICellRendererParams<TaskListItem, string>) {
    return (
      <>
        <a  href={this.getTaskLink(params.data)}>{params.value}</a>
        { this.renderTags(this.state.tagsList, params.data) }
      </>
    )
  }

  renderAsTaskLink = (params: ICellRendererParams<TaskListItem, string>, content: any) => {
    return <a  href={this.getTaskLink(params.data)}>{content || params.value}</a>;
  }

  render() {
    return (
      <>
      {this.props.taskId ?
      <TaskForm
        taskId={this.props.taskId}
        history={this.props.history}
        onTaskChanged={() => this.dirty = true}
      /> : ''
      }
      
      {this.state.devMode ?
        <DevToolbar
          style={{display: this.props.taskId ? 'none' : ''}}
          pageType='taskList'
          pageKey={this.state.pageKey}
          defaultTemplate={this.state.defaultDevTemplate}
          onChange={this.devConfigChanged}
          onRefreshTarget={this.refreshTemplate}
        /> : ''
      }

      <Container fluid className='page-container' style={{display: this.props.taskId ? 'none' : ''}}>

        <div className='app-toolbar'>
          <div>
            <Popup
              trigger={
                <Button circular basic icon='refresh' size='medium' className='app-toolbar-btn'
                  disabled={ this.state.reloadDisabled }
                  loading = { this.state.loading }
                  onClick={ () => { this.loadList() } }
                />
              }
              content='Refresh the list'
              position='bottom left'
              size="tiny"
              inverted      
            />
            <Popup
              trigger={
                <Button circular basic icon='arrow up' size='medium' className='app-toolbar-btn btn-level-up'
                  href={this.props.claimedOnly ? '#/tasklist/claimed/groups' : '#/tasklist/groups'}
                />
              }
              content='Go to aggregated list'
              position='bottom left'
              size="tiny"
              inverted      
            />
            <div className='app-page-header'>
              {this.state.procDefName} {this.state.procDefName && this.state.taskDefName ? '/' : ''} {this.state.taskDefName}
            </div>
          </div>
          <div>
            <Popup
              trigger={
                <Button 
                  circular
                  icon='filter'
                  size='medium'
                  className='app-toolbar-btn'
                  color='linkedin'
                  basic={!this.state.showFilter}
                  onClick={() => this.toggleFilter()}
                />
              }
              content={this.state.showFilter ? 'Close filter' : 'Open filter'}
              position='bottom right'
              size="tiny"
              inverted      
            />
          </div>
        </div>

        <ErrorMessage error={this.state.loadError}/>

        <div className='ag-theme-alpine app-tasklist-grid'
          style={{
            height: `calc(100vh - ${
              117
              + (this.state.listProcessing || this.state.assigneeGroupId ? 44 : 0)
              + (this.state.devMode ? 34 : 0)
            }px)`
          }}
        >
          {!this.state.tableLoading ? <>
          <AgGridReact
            onGridReady={this.gridReady}
            onSortChanged={this.colStateChanged}
            onColumnResized={this.colStateChanged}
            onColumnMoved={this.colStateChanged}
            onRowSelected={this.selectRow}
            getRowClass={this.getListItemClassName}
            pagination={false}
            domLayout='normal'
            tooltipShowDelay={500}
            rowSelection='multiple'
            enableCellTextSelection={true}
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.state.gridColDefs}
            rowData={this.state.list}
          />
          {this.state.listProcessing || this.state.assigneeGroupId ?
          <div className='processing-bar'>
            <Popup
              trigger={
                <span>
                  <Button
                    disabled={!this.state.hasSelectedRows || this.state.loading}
                    color='green'
                    onClick={() => {
                      this.loadAssigneeOptions();
                      this.setState({showAssign: true})
                    }}
                  >
                    Assign
                  </Button>
                </span>
              }
              content={this.state.hasSelectedRows ?
                (this.state.hasSelectedAssigned ? 'Assign/reassign' : 'Assign') + ' selected tasks' :
                'No tasks selected'}
              position='top left'
              offset={[0, 10]}
              size="tiny"
              inverted      
            />
            <Popup
              trigger={
                <span>
                  <Button
                    disabled={!this.state.hasSelectedAssigned || this.state.loading}
                    onClick={() => {
                      this.setState({showUnassign: true})
                    }}
                  >
                    Unassign
                  </Button>
                </span>
              }
              content={this.state.hasSelectedAssigned ? 'Unassign selected tasks' : 'No assigned tasks selected'}
              position='top left'
              offset={[0, 10]}
              size="tiny"
              inverted      
            />
          </div> : ''}
          </> :
          <div>Initialization...</div>
          }
        </div>
        <TasklistAssignment
          open={this.state.showAssign || this.state.showUnassign}
          mode={this.state.showAssign ? 'reassign' : 'unassign'}
          toaster={this.toaster}
          tasks={() => this.gridApi.getSelectedRows()}
          assigneeOptions={this.state.assigneeOptions}
          onClose={(changed) => {
            this.setState({showAssign: false, showUnassign: false});
            if (changed) {
              this.loadList();
            }
          }}
        />
        {this.state.showModal &&
        <ModalWindow
          showModal={this.state.showModal}
          handleModal={this.handleModal}
          list={this.state.list}
          fields={this.state.listProcessing?.fields || []}
          buttons={this.state.listProcessing?.buttons || []}
          selectedRows={this.gridApi && this.gridApi.getSelectedRows() || []}
        />}
      </Container>
      </>
    );
  }
}
