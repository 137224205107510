import { ExtColDef } from '../models';

export const varColDefs: ExtColDef[] = [
  {
    headerName: 'Company number',
    field: 'companyNumber',
    tooltipField: 'companyNumber',
    width: 170,
    sortable: true,
    insertAt: 1
  },
  {
    headerName: 'Company name',
    field: 'companyName',
    width: 300,
    sortable: true,
    cellClass: 'cell-wrap-text',
    insertAt: 1
  },
  {
    headerName: 'Stage',
    field: 'processStatus',
    dictionaryKey: 'proc-state',
    tooltipField: 'processStatus',
    width: 165,
    sortable: true,
    cellClass: 'cell-wrap-text',
    insertAt: 2
  },
];
