import { getAppConfig } from './AppConfig';
import { BaseRestService } from './BaseRestService';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export abstract class BaseBpmRestService extends BaseRestService {

  protected config = getAppConfig();

  protected getBaseUrl() { return this.config.bpmApiUrl + '/rest'; }

}
