'use strict';

var template =
`<style>
* {
  box-sizing: border-box;
}
[cam-tasklist-form] {
  font-family: 'IBMPlexSans','open_sansregular',Helvetica,Arial,Verdana,sans-serif;
  font-size: 14px;
  line-height: 1.45;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
[ng-click], [onclick] {
  cursor: pointer;
}
[cam-tasklist-form] .form-actions {
  margin-top: 15px;
  padding-top: 1em;
  border-top: outset;
}
.form-actions {
  text-align: center;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary {
  color: #fff;
  background-color: #e0ab5b;
  border-color: #e0ab5b;
}
textarea.form-control {
  height: auto;
}
.form-control {
  border: 1px solid #aaa;
  color: #555;
  box-shadow: none;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  background-color: #fff;
  border-radius: 2px;
  transition: border-color ease-in-out .15s;
}
.form-control:focus {
  border-color: #0b2d5f;
  outline: 0;
}
.form-control.ng-dirty.ng-invalid, .has-error .form-control {
  color: #a94442;
}
.form-control[disabled], .form-control[readonly], .disabled-form .form-control, fieldset[disabled] .form-control {
  color: #777;
  background-color: #eee;
}
.form-control[type=file] {
  border: none !important;
}
.form-control[type=file].ng-invalid {
  color: #a94442;
  background-color: #f9efef;
}
input[type="file"] {
  display: block;
}
</style>

<div>
  <div cam-tasklist-form-embedded></div>
  <div class="form-actions" ng-show="showCompleteButton()">
    <button class="btn btn-default"
      type="submit"
      ng-click="save($event)"
      tooltip-placement="top"
      uib-tooltip="Save changes"
      ng-disabled="!$dirty">
      Save
    </button>
    <button class="btn btn-primary"
      type="submit"
      ng-click="complete()"
      ng-disabled="disableCompleteButton()"
    >
      {{params.taskId ? 'Complete' : 'Submit'}}
    </button>
  </div>
</div>
`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
var noop = function() {};

export default function() {
  return {
    restrict: 'A',

    scope: {
      /*
       * current params are:
       * - taskId
       * - processDefinitionId
       * - formKey
       * - historic
       */
      params: '=',

      /*
       * current options are:
       * - hideCompleteButton: to hide the complete button inside the form directive
       * - disableCompleteButton: to disable or enable the complete button inside
       *   the form directive
       * - disableForm: to disable or enable the form
       * - disableAddVariableButton: to disable or enable the 'Add Variable' button
       *   inside a generic form
       */
      options: '=',

      handlers: '=',
    },

    template: template,

    controller: [
      '$scope',
      'camAPI',
      'Notifications',
      function($scope, camAPI, Notifications) {

        const apply = () => {
          var phase = $scope.$root.$$phase;
          if (phase !== '$apply' && phase !== '$digest') {
            $scope.$apply();
          }
        };

        // setup
        $scope.completionHandler = noop;
        $scope.saveHandler = noop;

        $scope.$loaded = false;
        $scope.completeInProgress = false;

        // handle tasklist form
        $scope.$watch('params', function() {
          $scope.$root.authentication = $scope.params.authentication;
          // structure may be [deployment:]formKey
          let formUrl;
          if ($scope.params.formKey.indexOf('deployment:') === 0) {
            if ($scope.params.taskId) {
              formUrl = ($scope.params.historic ? 'ext/history/' : '') + 'task/' + $scope.params.taskId + '/deployed-form';
            } else {
              formUrl = 'process-definition/' + $scope.params.processDefinitionId + '/deployed-start-form';
            }
          } else {
            formUrl = $scope.params.formKey;
          }
          $scope.asynchronousFormKey.key = formUrl;
          $scope.asynchronousFormKey.loaded = true;
          $scope.loadingState = 'DONE';
          $scope.taskRemoved = false;
          apply();
        });

        $scope.asynchronousFormKey = {
          loaded: false,
          failure: false
        };

        // completion /////////////////////////////////////////////
        var completionCallback = function(err, result) {
          if (err) {
            if (err.message !== false) {
              Notifications.addError({
                status: "Error",
                message: err.message || 'Form completion error'
              });
            }
          } else {
            if ($scope.handlers.onFormCompletionCallback)
              $scope.handlers.onFormCompletionCallback(result);
          }
          $scope.completeInProgress = false;
          apply();
        };

        var complete = ($scope.complete = function() {
          Notifications.clearAll();
          $scope.completeInProgress = true;
          $scope.completionHandler(completionCallback);
        });
        
        if ($scope.handlers.onFormCompletion)
          $scope.handlers.onFormCompletion(complete);

        $scope.showCompleteButton = function() {
          return (
            !$scope.options?.hideCompleteButton && !$scope.params.historic && $scope.$loaded
          );
        };

        var disableCompleteButton = ($scope.disableCompleteButton = function() {
          return (
            $scope.taskRemoved ||
            $scope.completeInProgress ||
            $scope.$invalid ||
            ($scope.options && $scope.options.disableCompleteButton)
          );
        });

        var attemptComplete = function attemptComplete() {
          var canComplete = !disableCompleteButton();
          return canComplete && complete();
        };

        // save ///////////////////////////////////////////////////

        $scope.save = function(evt) {
          $scope.saveHandler(evt);
        };

        // API ////////////////////////////////////////////////////

        this.notifyFormInitialized = function() {
          $scope.$loaded = true;
          apply();
          if ($scope.handlers.onFormInitialized)
            $scope.handlers.onFormInitialized();
        };

        this.notifyFormInitializationFailed = function(error) {
          $scope.$loaded = true;
          $scope.$invalid = true;
          apply();
          if ($scope.handlers.onFormInitializationFailed)
            $scope.handlers.onFormInitializationFailed(error);
        };

        this.notifyFormCompleted = function(err) {
          if ($scope.handlers.onFormCompletion)
            $scope.handlers.onFormCompletion(err);
        };

        this.notifyFormValidated = function(invalid) {
          $scope.$invalid = invalid;
          if ($scope.handlers.onFormValidation)
            $scope.handlers.onFormValidation(invalid);
          apply();
        };

        this.notifConsumer = {
          add: (notif) => {
            if ($scope.handlers.onNotification) 
              $scope.handlers.onNotification(notif);
          },
          clear: () => {
            if($scope.handlers.onClearNotifications)
            $scope.handlers.onClearNotifications();
          }
        }
        Notifications.registerConsumer(this.notifConsumer);
        $scope.$on("$destroy", () => {
          Notifications.unregisterConsumer(this.notifConsumer);
        });

        this.notifyFormDirty = function(dirty) {
          $scope.$dirty = dirty;
          apply();
        };

        this.getOptions = function() {
          return $scope.options || {};
        };

        this.getParams = function() {
          return $scope.params || {};
        };

        this.registerCompletionHandler = function(fn) {
          $scope.completionHandler = fn || noop;
        };

        this.registerSaveHandler = function(fn) {
          $scope.saveHandler = fn || noop;
        };

        this.attemptComplete = attemptComplete;
      }
    ]
  };
};
