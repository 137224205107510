
import React from 'react';
import { Card, Container, Image } from 'semantic-ui-react';
import reports from './img/reports.svg'
import { getReportsRestService } from '../services/ReportsRestService';
import { getAppConfig } from '../services/AppConfig';
import './ReportList.css'

export class ReportList extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
  }

  private async openReportsApp(): Promise<void> {
    try {
      const otp = await getReportsRestService().getOTP();
      const reportsUrl = `${getAppConfig().reportsUrl}/underwriter/login?otp=${otp}`
      window.open(reportsUrl, '_blank')
    } catch(error) {
      console.error(`OTP has not been received: ${error}`);
    }
  }
  
  render() {
    return (
      <Container fluid className='page-container'>
      <div>
        <div className='app-toolbar'>
          <div className='app-page-header with-bottom-padding'>My Reports</div>
        </div>  
        <div>
          <Card.Group>
            <Card>
              <div className='report-img-container'>
                <Image src={reports} wrapped ui={false} />
              </div>
              <Card.Content>
                <Card.Header>Reports</Card.Header>
                <Card.Description>
                  <a onClick={() => this.openReportsApp()} rel="noreferrer">Click here to watch reports</a>
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </div>
      </div>
      </Container>
    );
  }

}
