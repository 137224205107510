import moment from "moment";

const tzOffset = (new Date('2024-01-01')).getTimezoneOffset();
const tzPlaceholder = tzOffset * tzOffset > 0 ? ' Z' : '';
const dateFormatM = 'YYYY-MM-DD HH:mm' + tzPlaceholder;
const dateFormatS = 'YYYY-MM-DD HH:mm:ss' + tzPlaceholder;
const dateFormatD = 'YYYY-MM-DD';

export const inputDateFormat = 'YYYY-MM-DD HH:mm';
export const displayDateFormat = 'YYYY-MM-DD';
export const displayTimeFormat = 'HH:mm' + tzPlaceholder;

export const formatDateTimeM = (d: Date|string): string => d && moment(d).format(dateFormatM);

export const formatDateTimeS = (d: Date|string): string => d && moment(d).format(dateFormatS);

export const formatDateVar = (d: Date|string) =>  {
  if (!d) {
    return;
  }
  const m = moment(d);
  const utc = m.utcOffset(0);
  if (utc.hours() || utc.minutes() || utc.seconds() || utc.milliseconds) {
    return m.format(dateFormatS);
  }
  return utc.format(dateFormatD);
}

export const formatAge = (d: Date) => d ? moment.duration(moment(d).diff(Date.now())).humanize(true) : '';

export const compareDates = (d1: any, d2: any) => d1 && !d2 ? -1 : !d1 && d2 ? 1 : d1 > d2 ? 1 : d1 < d2 ? -1 : 0;

export const compareFilterDates = (fltDate: Date, itemDate: any) => {
  if (fltDate.getHours() === 0) {
    fltDate.setHours(12);
  }
  const d1 = (typeof itemDate != 'string' ? itemDate.toISOString() : itemDate)?.substring(0,10);
  const d2 = fltDate.toISOString().substring(0,10);
  return d1 > d2 ? 1 : (d1 < d2 ? -1 : 0);
};

export const highlightDueDate = (d: Date|string) => {
    if (!d) {
      return null;
    }
    const diff = moment(d).diff(Date.now(), 'minute');
    return diff <= 0 ? {color: 'red'} : {};
}

export function concatPath(s1: string, s2: string): string {
  if (s1?.endsWith('/') && s2.startsWith('/')) {
    return s1.slice(0, -1) + s2;
  }
  if (s1?.endsWith('/') || s2.startsWith('/')) {
    return s1 + s2;
  }
  return s1 + '/' + s2;
}