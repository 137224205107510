import Axios, { Method, AxiosError } from 'axios';
import { AuthService, getAuthService } from './AuthService';
import { AppConfig, getAppConfig } from './AppConfig';

export interface RequestConfig {
  headers?: any;
  query?: any;
  body?: any;
}

export interface HttpClient {
  request<T = any>(method: Method, url: string, config: RequestConfig): Promise<T>;
}

interface ErrorResponse {
  type?: string;
  code?: string;
  message?: string;
}

export class HttpClientError extends Error {
  cause: AxiosError;
  constructor (cause: AxiosError){
    const msg = (cause.response?.data as ErrorResponse)?.message || cause.message;
    super(msg);
    this.cause = cause;
  }
}

class HttpClientWithAuth implements HttpClient {

  constructor (
    private auth: AuthService,
    private config: AppConfig
  ) {}

  request<T = any>(method: Method, url: string, config: RequestConfig): Promise<T> {
    if (this.config.authReqUrlPattern?.test(url)) {
      return this.authorizedReq(method, url, config, 0);
    } else {
      return this.unauthorizedReq(method, url, config);
    }
  }

  private authorizedReq<T = any>(method: Method, url: string, config: RequestConfig, attempts: number): Promise<T> {
    return this.auth.getBearerToken().then(
      token => {
        const headers = {...config.headers, Authorization: 'Bearer ' + token};
        return Axios.request({
          method: method,
          url: url,
          params: config.query,
          headers: config.body === null ? headers :  { ...headers, 'Content-Type': 'application/json; charset=utf-8' },
          data: config.body
        }).then(
          (resp) => {
            return resp.data || resp
          }
        ).catch(
          (err: AxiosError) => {
            if (err.response && err.response.status === 401 && attempts === 0) {
              console.log(`Reauthenticate and retry ${method} ${url}`);
              this.auth.reset();
              return this.authorizedReq(method, url, config, attempts + 1);
            } else {
              console.log('error:', method, url, err);
              return Promise.reject(new HttpClientError(err));
            }
          }
        );
      }
    ).catch(
      (err: Error)=> {
        return Promise.reject(err);
      }
    )
  }

  private unauthorizedReq<T = any>(method: Method, url: string, config: RequestConfig): Promise<T> {
    return Axios.request({
      method: method,
      url: url,
      params: config.query,
      headers: config.body === null ? config.headers :  { ...config.headers, 'Content-Type': 'application/json; charset=utf-8' },
      data: config.body
    }).then(
      (resp) => {
        return resp.data || resp
      }
    ).catch(
      (err: AxiosError) => {
        console.log('error:', method, url, err);
        return Promise.reject(new HttpClientError(err));
      }
    );
  }

}

let instance: HttpClient;
export const getHttpClient = (auth = getAuthService()) => {
  return instance || (instance = new HttpClientWithAuth(auth, getAppConfig()) as HttpClient);
};
