import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Popup, Dropdown, Grid, GridRow, GridColumn, Input, Button, PopupHeader } from "semantic-ui-react";
import moment from "moment";
import './DateRangePicker.css'

export type DateRangeKind = 'today' | 'last' | 'after' | 'between';
export type DateRangeUnits = 'minute' | 'hour' | 'day' | 'month';

export interface DateRange {
  kind: DateRangeKind;
  duration?: number;
  units?: DateRangeUnits;
  startDate?: Date;
  endDate?: Date;
}

export interface DateRangePickerProps{
  value: DateRange;
  inputDateFormat: string;
  displayDateFormat: string;
  displayTimeFormat: string;
  header: string;
  onChange: (value: DateRange) => void;
}

interface DateRangePickerState{
  value: DateRange;
  open?: boolean;
  mountNode?: HTMLElement;
}


export function formatDateRange(val: DateRange, dateFormat: string, timeFormat: string): string {
  function formatDate(d: Date) {
    const fmt = (d.getHours() === 0 && d.getMinutes() === 0 && d.getSeconds() === 0 && d.getMilliseconds() === 0) ?
      dateFormat : dateFormat + ' ' + timeFormat;
    return moment(d).format(fmt)
  }
  if (val?.kind === 'today') {
    return 'Today';
  }
  if (val?.kind === 'last') {
    return `Last ${val.duration?.toString()} ${val.units}${val.duration > 1 ? 's' : ''}`;
  }
  if (val?.kind === 'after') {
    return `After ${formatDate(val.startDate)}`;
  }
  if (val?.kind === 'between') {
    return `Between ${formatDate(val.startDate)} and ${formatDate(val.endDate)}`;
  }
  return '';
}

export function dateRangeToDates(val: DateRange): [Date, Date] {
  if (val?.kind === 'today') {
    return [moment().startOf('day').toDate(), undefined];
  }
  if (val?.kind === 'last') {
    return [moment().subtract(val.duration, val.units as moment.unitOfTime.Base).toDate(), undefined];
  }
  if (val?.kind === 'after') {
    return [val.startDate, undefined];
  }
  if (val?.kind === 'between') {
    return [val.startDate, val.endDate];
  }
  return [undefined, undefined];
}


export class DateRangePicker extends React.Component<DateRangePickerProps, DateRangePickerState> {

  constructor(props: any) {
    super(props);
    this.state = {
      value: {...(props.value || {})}
    }
  }
    
  async componentDidMount() {
  }
    
  async componentDidUpdate(oldProps: DateRangePickerProps) {
    if (this.props.value !== oldProps.value) {
      this.setState({value: this.props.value});
    }
  }

  private updateValue(val: Partial<DateRange>) {
    const value = {...this.state.value, ...val};
    this.setState({value});
  }

  private setValue(value: DateRange) {
    this.setState({value}, this.submit);
  }

  private isValid(value: DateRange): boolean {
    return value && (
      (value.kind === 'today') ||
      (value.kind === 'last' && value.duration > 0 && !!value.units) ||
      (value.kind === 'after' && !!value.startDate) ||
      (value.kind === 'between' && !!value.startDate && !!value.endDate));
  }

  private submit = () => {
    const val = {...this.state.value};
    if (typeof val.startDate === 'string') {
      val.startDate = val.startDate ? moment(val.startDate, this.props.inputDateFormat).toDate() : null;
    }
    if (typeof val.endDate === 'string') {
      val.endDate = val.endDate ? moment(val.endDate, this.props.inputDateFormat).toDate() : null;
    }
    this.setState({open: false}, () => this.props.onChange(val));
  } 

  private formatInputDate(d: Date | string): string {
    if (typeof d === 'string') {
      return d;
    }
    return d ? moment(d).format(this.props.inputDateFormat) : ''
  }

  private renderOption = (val: DateRange) => {
    return (
      <button
        className='btn-link'
        onClick={() => this.setValue(val)}
      >
        {formatDateRange(val, this.props.displayDateFormat, this.props.displayTimeFormat)}
      </button>
    )
  }

  private updateMountNode = (mountNode: any) => {
    if (mountNode) {
      this.setState({mountNode});
    }
  };

  render() {
    const value = this.state.value;
    const {inputDateFormat, displayDateFormat, displayTimeFormat} = this.props;
    return (
      <Popup 
        trigger={
          <Dropdown compact button text={formatDateRange(this.props.value, displayDateFormat, displayTimeFormat)}/>
        }
        position='bottom left'
        on='click'
        eventsEnabled
        onOpen={() => this.setState({open: true, value: this.props.value})}
        onClose={() => this.setState({open: false})}
        open={this.state.open}
        className='date-range-picker'
      >
        <PopupHeader>{this.props.header}</PopupHeader>
        <div ref={this.updateMountNode}> 
          <Grid>
            <GridRow columns={1}>
              <GridColumn>
                <Dropdown
                  selection
                  options={[
                    {key: 'today', value: 'today', text: 'Today'},
                    {key: 'last', value: 'last', text: 'Last'},
                    {key: 'after', value: 'after', text: 'After'},
                    {key: 'between', value: 'between', text: 'Between'},
                  ]} 
                  value={value.kind}
                  onChange={(evt, data) => this.updateValue({kind: data.value as DateRangeKind})}
                  style={{minWidth: '6.5em', marginRight:'4px'}}
                />
                { value.kind === 'last' ? <>
                  <Input 
                    type='number'
                    value={value.duration?.toString()}
                    onChange={(evt, data) => this.updateValue({duration: parseInt(data.value)})}
                    style={{maxWidth:'5em', marginRight:'4px'}}
                  />
                  <Dropdown
                    selection
                    compact
                    options={[
                      {key: 'minute', value: 'minute', text: 'minutes'},
                      {key: 'hour', value: 'hour', text: 'hours'},
                      {key: 'day', value: 'day', text: 'days'},
                      {key: 'month', value: 'month', text: 'months'}
                    ]}
                    value={value.units}
                    onChange={(evt, data) => this.updateValue({units: data.value as DateRangeUnits})}
                    style={{marginRight:'4px'}}
                  />
                </> : '' }
                { (value.kind === 'after' || value.kind === 'between') ? <>
                  <DateInput
                    localization='en'
                    closable
                    clearable
                    autoComplete='off'
                    dateFormat={inputDateFormat}
                    placeholder={inputDateFormat}
                    iconPosition='right'
                    value={this.formatInputDate(value.startDate)}
                    onChange={(e, data: {value: string}) => this.updateValue({startDate: data.value as any})}
                    mountNode={this.state.open && this.state.mountNode}
                    style={{width: '12em', marginRight:'4px'}}
                  />
                </> : '' }
                { value.kind === 'between' ? <>
                  <DateInput
                    localization='en'
                    closable
                    clearable
                    autoComplete='off'
                    dateFormat={inputDateFormat}
                    placeholder={inputDateFormat}
                    iconPosition='right'
                    value={this.formatInputDate(value.endDate)}
                    onChange={(e, data: {value: string}) => this.updateValue({endDate: data.value as any})}
                    mountNode={this.state.open && this.state.mountNode}
                    style={{width: '12em', marginRight:'4px'}}
                  />
                </> : '' }
                <Button 
                  positive
                  icon='check'
                  disabled={!this.isValid(this.state.value)}
                  onClick={this.submit}
                />
              </GridColumn>
            </GridRow>
            <GridRow columns={2}>
              <GridColumn>
                <this.renderOption kind='today'/>
              </GridColumn>
              <GridColumn>
                <this.renderOption kind='last' duration={24} units='hour'/>
              </GridColumn>
            </GridRow>
            <GridRow columns={2}>
              <GridColumn>
                <this.renderOption kind='last' duration={7} units='day'/>
              </GridColumn>
              <GridColumn>
                <this.renderOption kind='last' duration={1} units='month'/>
              </GridColumn>
            </GridRow>
            <GridRow columns={2}>
              <GridColumn>
                <this.renderOption kind='last' duration={6} units='month'/>
              </GridColumn>
              <GridColumn>
                <this.renderOption kind='last' duration={12} units='month'/>
              </GridColumn>
            </GridRow>
          </Grid>
        </div>
      </Popup>
    );
  }
//ref={mountNode =>
            //this.mountNode = mountNode;
            //if (mountNode && this.state.mountNode !== mountNode) {
            //  setTimeout(() => {
            //    this.setState({mountNode});
            //  }, 0); 
            //}
        //  }
}
