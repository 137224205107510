
import React from 'react';
import { getBpmRestService } from '../services/BpmRestService';
import { ColDef, ColumnEvent, ColumnState, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { TaskInstance } from '../models';
import { Popup, Button, Container } from 'semantic-ui-react'
import { AgGridReact } from '@ag-grid-community/react';
import { TaskForm } from './TaskForm'
import { ErrorMessage } from './ErrorMessage';
import { getCurrentUser } from '../services/CurrentUserService';
import './ag-grid-modules';
import './ag-grid.css';
import 'semantic-ui-css/semantic.min.css';
import '../styles.css';
import './Supervisor.css'
import './TasklistGroup.css';
import { formatDateTimeM, compareDates, compareFilterDates, inputDateFormat, displayDateFormat, displayTimeFormat } from './utils';
import { DateRange, DateRangePicker, dateRangeToDates } from './DateRangePicker';

export interface CompletedTasksProps {
  taskId?: string;
  query?: string;
  history?: any;
}

interface CompletedTasksState {
  userId?: string;
  tasks?: TaskInstance[];
  loadError?: Error;
  loading?: boolean;
  reloadDisabled?: boolean;
  showFilter?: boolean;
  defaultColDef: ColDef;
  gridColDefs: ColDef[];
  dateRange?: DateRange;
}

export class CompletedTasks extends React.Component<CompletedTasksProps, CompletedTasksState> {
  private localStorageKey = 'tasklist/CompletedTasks';
  private gridApi: GridApi<TaskInstance>;
  private filterState: any;
  private ProcDefMap: {[key: string]: string};
  private gridColState: ColumnState[];

  private defaultColDef: ColDef = {
    autoHeight: true,
    cellClass: 'cell-wrap-text',
    resizable: true,
    filter: true,
    floatingFilter: false,
    filterParams: {
      newRowsAction: 'keep',
      suppressMiniFilter: true,
      buttons: ['reset']
    },
    suppressMenu: true
  };

  private columnDefs: ColDef<TaskInstance>[] = [
    {
      colId: 'procDefName',
      headerName: 'Process name',
      valueGetter: params => this.ProcDefMap[params.data.processDefinitionKey],
      width: 280,
      sortable: true,
      cellClass: 'cell-wrap-text',
      filter: 'agTextColumnFilter'
    },
    {
      colId: 'name',
      headerName: 'Task name',
      field: 'name',
      width: 520,
      sortable: true,
      cellRenderer: params => this.getListItemLink(params),
      cellClass: 'cell-wrap-text',
      filter: 'agTextColumnFilter'
    },
    {
      colId: 'startTime',
      headerName: 'Created',
      field: 'startTime',
      sortable: true,
      width: 210,
      valueFormatter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2),
      filter: 'agDateColumnFilter',
      filterParams: {
        newRowsAction: 'keep',
        comparator: compareFilterDates,
        buttons: ['reset'],
        inRangeInclusive: true,
        suppressAndOrCondition: true
      }
    },
    {
      colId: 'endDate',
      headerName: 'Completed',
      field: 'endTime',
      sortable: true,
      width: 210,
      valueFormatter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2),
      filter: 'agDateColumnFilter',
      filterParams: { 
        newRowsAction: 'keep',
        comparator: compareFilterDates,
        buttons: ['reset'],
        inRangeInclusive: true,
        suppressAndOrCondition: true,
      }
    }
  ];

  private defaultDateRange: DateRange = {
    kind: 'last',
    duration: 1,
    units: 'month'
  };

  constructor(props: any) {
    super(props);
    const dateRange = this.getSavedDateRange() || this.defaultDateRange;
    this.state = {
      defaultColDef: this.defaultColDef,
      gridColDefs: this.columnDefs,
      dateRange
    }
  }

  async componentDidMount() {
    if (!this.props.taskId) {
      this.update();
    }
  }

  async componentDidUpdate(prevProps: CompletedTasksProps) {
    if (!this.props.taskId && prevProps.taskId) {
      this.update();
    }
  }

  async update() {
    try {
      if (!this.ProcDefMap) {
        const resp = await getBpmRestService().getProcessDefinitions();
        this.ProcDefMap = {};
        resp.forEach(item => this.ProcDefMap[item.key] = item.name)
      }
      if (!this.state.tasks) {
        const userId = getCurrentUser().getUserId();
        this.setState({userId}, this.loadList);
      }
    } catch (e) {
      this.setState({ loadError: e });
    }
  }

  private async loadList() {
    this.setState({ loading: true, reloadDisabled: true });
    setTimeout(() => this.setState({ reloadDisabled: false }), 4000);
    try {
      const dates = dateRangeToDates(this.state.dateRange);
      const tasks = await getBpmRestService().getCompletedTasks(this.state.userId, dates[0], dates[1]);
      this.setState({tasks});
    } catch (e) {
        this.setState({loadError: e, tasks: []})
    } finally {
        this.setState({loading: false});
    }
  }

  private getSavedDateRange(): DateRange {
    const str = localStorage.getItem(this.localStorageKey + '/dateRange');
    if (str) {
      const obj: DateRange = JSON.parse(str);
      if(obj.startDate) obj.startDate = new Date(obj.startDate);
      if(obj.endDate) obj.endDate = new Date(obj.endDate);
      return obj;
    }
    return null;
  }

  private saveDateRange() {
    const obj = this.state.dateRange;
    localStorage.setItem(this.localStorageKey + '/dateRange', JSON.stringify(obj));
  }

  private getListItemLink(params: any) {
    const href = `#/tasklist/completed/task/${params.data?.id}${this.props.query || ''}`;
    return <a href={href}>{params.value}</a>;
  }

  private updateDateRange = (dateRange: DateRange) => {
    this.setState(
      {dateRange},
      () => {
        this.saveDateRange();
        this.loadList();
      }
    )
  }

  private toggleFilter = () => {
    const showFilter = !this.state.showFilter;
    const defaultColDef = {...this.defaultColDef, floatingFilter: showFilter};
    const colState = this.gridApi?.getColumnState();
    const gridColDefs = colState ? colState.map(s => {
        const d = this.state.gridColDefs.find(d => d.colId === s.colId);
        d.width = s.width;
        return d;
      }) : this.state.gridColDefs;
    this.setState({showFilter, defaultColDef, gridColDefs}, () => {
      this.gridApi?.applyColumnState({state: colState, applyOrder: true});
    });
    if (this.gridApi) {
      if (showFilter) {
        this.gridApi.setFilterModel(this.filterState);
      } else {
        this.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(null);
      }
    }
  }

  private gridReady = (evt: GridReadyEvent) => {
    this.gridApi = evt.api;
    this.gridApi.applyColumnState({state: this.gridColState, applyOrder: true});
  }

  private colStateChanged = (evt: ColumnEvent) => {
    this.gridColState = evt.api.getColumnState();
  }

  render() {
    return (
      <>
      { this.props.taskId &&
      <TaskForm
        taskId={this.props.taskId}
        historic={true}
        history={this.props.history}
      /> }
      
      <Container fluid className='page-container' style={{display: this.props.taskId ? 'none' : ''}}>
        <div className='app-toolbar'>
          <div>
            <Popup
              trigger={
                <Button circular basic icon='refresh' size='medium' className='app-toolbar-btn'
                  disabled = { this.state.reloadDisabled }
                  loading = { this.state.loading }
                  onClick = { () => { this.loadList() } }
                />
              }
              content='Refresh the list'
              position='bottom left'
              size="tiny"
              inverted      
            />
            <div className='app-page-header'>
              Tasks you have completed
            </div>
            <DateRangePicker
              value={this.state.dateRange}
              inputDateFormat={inputDateFormat}
              displayDateFormat={displayDateFormat}
              displayTimeFormat={displayTimeFormat}
              header='Select the time range when the task was completed'
              onChange={this.updateDateRange}
            />
          </div>
          <div>
            <Popup
              trigger={
                <Button 
                  circular
                  icon='filter'
                  size='medium'
                  className='app-toolbar-btn'
                  color='linkedin'
                  basic={!this.state.showFilter}
                  onClick={() => this.toggleFilter()}
                />
              }
              content={this.state.showFilter ? 'Close filter' : 'Open filter'}
              position='bottom right'
              size="tiny"
              inverted      
            />
          </div>
        </div>

        <ErrorMessage error={this.state.loadError}/>

        <div className='ag-theme-alpine app-tasklist-grid' style={{height: 'calc(100vh - 160px)'}}>
          <AgGridReact
            onGridReady={this.gridReady}
            onSortChanged={this.colStateChanged}
            onColumnResized={this.colStateChanged}
            onColumnMoved={this.colStateChanged}
            pagination={false}
            domLayout='normal'
            tooltipShowDelay={500}
            enableCellTextSelection={true}
            defaultColDef = {this.state.defaultColDef}
            columnDefs = {this.state.gridColDefs}
            rowData={this.state.tasks}
          /> 
        </div>
      </Container>
      </>
    );
  }


}