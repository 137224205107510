import { Method } from 'axios';
import { getHttpClient } from './HttpClientWithAuth';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export abstract class BaseRestService {

  private httpClient = getHttpClient();

  private expandUrl(url: string): string {
    if (/^(http|https|file):\/\//.test(url)) {
      return url;
    }
    const baseUrl = this.getBaseUrl();
    const sep = baseUrl && url ? (baseUrl.charAt(baseUrl.length - 1) !== '/' && url.charAt(0) !== '/' ? '/' : '') : '';
    return  baseUrl + sep + url;
  }

  private request<T = any>(method: Method, url: string, query: any, body: any = null): Promise<T> {
    return this.httpClient.request(method, this.expandUrl(url), {query, body});
  }

  protected abstract getBaseUrl(): string;

  protected get<T = any>(endpoint: string, queryParams: any = null): Promise<T> {
    return this.request('get', endpoint, queryParams);
  }

  protected delete<T = any>(endpoint: string, queryParams: any = null): Promise<T> {
    return this.request('delete', endpoint, queryParams);
  }

  protected post<T = any>(endpoint: string, queryParams: any,  body: any): Promise<T> {
    return this.request('post', endpoint, queryParams, body);
  }

  protected put<T = any>(endpoint: string, queryParams: any, body: any): Promise<T> {
    return this.request('put', endpoint, queryParams, body);
  }

}
