/*
  options-source directive for select element
  
  Attributes:
    options-source  - url of dictionary resource
    option-enabled  - disable option if value of specified field in dictionary item set to false (optional)
    option-disabled - disable option if value of specified field in dictionary item set to true (optional)
    option-visible  - hide option if value of specified field in dictionary item set to false (optional)
    option-hidden   - hide option if value of specified field in dictionary item set to true (optional, default: 'deleted')
  
  Remark:
    Either ng-model or cam-variable-name directive must also be specified.

  Example:
  <select
    cam-variable-name="tidPrefix"
    options-source="{{camForm.appConfig.processesApiUrl}}/v2/dossier/dictionary/d_way4_terminal_id_prefix?sort=value"
    option-enabled="isActive"
  ></select>
*/

import angular from "../../camunda-bpm-sdk-js/vendor/angular";

export default function optionsSourceDirective($http, $log, Notifications) {
  return {
    require: ['select'],
    link: function(scope, element, attrs, ctrl) {
 
      function loadOptions(url: string) {
        const {optionValue, optionText, optionEnabled, optionDisabled, optionVisible, optionHidden} = attrs;
        $http({
          method: 'GET',
          url: url
        }).then(
          (resp: {data: any[]}) => {
            // remove all options
            for (let i = element[0].options.length - 1; i >= 0; i--) {
              const key = element[0].options[i].value;
              if (key) {
                element[0].remove(i);                
                ctrl[0].removeOption(key);           
              }
            }
            // add options from response
            resp.data.forEach(item => {
              const key = optionValue ? item[optionValue] : (item['id'] || item['key'])?.toString();
              const opt = new Option(item[optionText||'value'], key);
              if (optionEnabled || optionDisabled || optionVisible || optionHidden) {
                opt.disabled = optionEnabled && !item[optionEnabled] || optionDisabled && !item[optionDisabled];
                opt.style.display = optionVisible && !item[optionVisible] || optionHidden && item[optionHidden] ? 'none' : '';
              } else {
                // hide deleted items by default
                opt.style.display = item['deleted'] ? 'none' : '';
              }
              element[0].options.add(opt);
              ctrl[0].addOption(key, angular.element(opt));
            }); 
          },
          function(err){
            if ($log) {
              $log.error(err);
            } else {
              console.log(err);
            }
            if (Notifications) {
              Notifications.addError({
                status: "Error",
                message: "Failed to get dictionary from '" + url + "'\n(Status code: " + err.status + ")"
              });
            }
          }
        );
      }
      
      attrs.$observe('optionsSource', (newValue: string) => {
        loadOptions(newValue);
      })

    }
  }
}
