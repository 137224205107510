import React from 'react';
import { Component } from 'react';
import { IIdleTimer, IIdleTimerProps, withIdleTimer } from 'react-idle-timer';

class IdleTimerComponent extends Component<IIdleTimer & IIdleTimerProps> {
  render () {
    return <></>;
  }
}

export const IdleTimer = withIdleTimer(IdleTimerComponent);