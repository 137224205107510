import React from 'react';
import moment from 'moment';
import { Button, Divider } from 'semantic-ui-react';
import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';
import './BpmnViewer.css';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const getTime = (dt, sep = '-', withMillisec = false) => {
  if (!dt) return '';
  try {
    if (dt && typeof dt === 'string') dt = new Date(dt);
  } catch (error) {
    return dt;
  }
  const month = dt.getMonth() + 1;
  const year = withMillisec ? String(dt.getFullYear()).substr(2) : dt.getFullYear();
  const time =
    (dt.getDate() < 10 ? '0' : '') +
    dt.getDate() +
    sep +
    (month < 10 ? '0' : '') +
    month +
    sep +
    year +
    ' ' +
    (dt.getHours() < 10 ? '0' : '') +
    dt.getHours() +
    ':' +
    (dt.getMinutes() < 10 ? '0' : '') +
    dt.getMinutes() +
    ':' +
    (dt.getSeconds() < 10 ? '0' : '') +
    dt.getSeconds();
  return time;
};

export const millisecToString = (t: number, withMillisec = false) => {
  if (!t) return '';
  const milli = Math.trunc(t);
  const words = [
    ['hour', 'hours', 'hours', 'h'],
    ['min', 'mins', 'mins'],
    ['sec', 'secs', 'secs'],
    ['day', 'days', 'days'],
  ];

  function get(n: any, idx: any, milliseconds?: any) {
    if (isNaN(n)) return '';
    const result = milliseconds ? n + '.' + milliseconds : n;
    if (n >= 11 && n <= 19) return result + ' ' + words[idx][2] + ' ';
    const k = n % 10;
    if (k < 1) return result + ' ' + words[idx][2] + ' ';
    if (k === 1) return result + ' ' + words[idx][0] + ' ';
    if (k <= 4) return result + ' ' + words[idx][1] + ' ';
    if (k >= 5) return result + ' ' + words[idx][2] + ' ';
    return result + ' ' + words[idx][3];
  }
  const duration = moment.duration(milli);

  const milliseconds = duration.milliseconds();
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const day = duration.days();
  let result = '';
  if (day > 0) {
    result += get(day, 3);
  }
  if (hours > 0 || result) {
    result += get(hours, 0);
  }
  if (minutes > 0 || result) {
    result += get(minutes, 1);
  }
  if (withMillisec) result += get(seconds, 2, milliseconds);
  else result += get(seconds, 2);
  return result;
};

type Props = {
  xml: string;
  activity?: any[];
  incidents?: any[];
  onRefresh?: () => void;
  loading: boolean;
};

type MarkerType = {
  element: string;
  marker: string;
};

type State = {
  imported: boolean;
  error: any;
  warnings?: any;
  modal: string;
  action: any;
  markers: MarkerType[];
  counts: string[];
};

class BpmnViewer extends React.Component<Props, State> {

  private bpmnViewer: any;
  private viewerRef: any;
  private selectedRef: any;

  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.selectedRef = React.createRef();
    this.state = {
      imported: false,
      error: null,
      modal: '',
      action: null,
      markers: [],
      counts: []
    };
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    this.bpmnViewer.destroy();
  }

  componentDidMount() {
    console.log('BpmnViewer.componentDidMount()');
    this.bpmnViewer = new BpmnJS({ container: this.viewerRef.current });
    this.bpmnViewer.on('import.done', event => {
      console.log('import.done', event);
      const { error, warnings } = event;
      if (error) return this.handleError(error);
      return this.handleImported(warnings);
    });
    this.importDiagram();
  
    window.addEventListener('keydown', this.handleKeydown);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('BpmnViewer.componentDidUpdate()', prevProps);
    if (prevProps.xml !== this.props.xml) {
      this.importDiagram();
    }
    if (
      this.state.imported !== prevState.imported ||
      JSON.stringify(this.props.activity) !== JSON.stringify(prevProps.activity) ||
      JSON.stringify(this.props.incidents) !== JSON.stringify(prevProps.incidents)
    ) {
      this.refreshDiagram();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.state) !== JSON.stringify(nextState) ||
      JSON.stringify(this.props.incidents) !== JSON.stringify(nextProps.incidents) ||
      JSON.stringify(this.props.activity) !== JSON.stringify(nextProps.activity) ||
      this.props.loading !== nextProps.loading
    );
  }

  private removeLogo() {
    const logo = document.getElementsByClassName('bjs-powered-by');
    if (!logo) return;
    while (logo.length > 0) {
      logo[0].parentNode.removeChild(logo[0]);
    }
  }

  importDiagram() {
    try {
      if (this.state.imported) this.setState({ imported: false });
      this.bpmnViewer.importXML(this.props.xml).then(() => {
        console.log('importXML done');
        this.removeLogo();
        this.bpmnViewer.get('canvas').zoom('fit-viewport', 'auto');
        const eventBus = this.bpmnViewer.get('eventBus');

        eventBus.on('element.click', evt => {
          this.handleTaskClick(evt);
        });

      });
    } catch (e) {
      console.error(e);
    }
  }

  private refreshDiagram() {
    console.log('BpmnViewer.refreshDiagram');
    try {
      if (!this.bpmnViewer) return;
      if (this.selectedRef.current) {
        const overlays = this.bpmnViewer.get('overlays');
        if (overlays) overlays.remove(this.selectedRef.current);
      }
      const canvas = this.bpmnViewer.get('canvas');

      this.state.markers.forEach(x => {
        if (canvas.hasMarker(x.element, x.marker)) {
          canvas.removeMarker(x.element, x.marker);
        }
      });

      const overlays = this.bpmnViewer.get('overlays');
      overlays.clear();

      const markers: Array<MarkerType> = [];
      const counts: Array<string> = [];
      const addMarker = (element: string, marker: string, activityCount: number, incidentsCount: number) => {
        canvas.addMarker(element, marker);
        markers.push({ element, marker });
        if (incidentsCount) {
          counts.push(
            overlays.add(element, {
              position: {
                bottom: 0,
                right: 0,
              },
              html: `<div style="width: 20px; background: #ff3939; color: #ff9999; border-radius: 50%; height: 20px; text-align: center; margin-top: -42px; margin-left: 2px;">${incidentsCount}</div>`,
            }),
          );
        }
        if (activityCount) {
          counts.push(
            overlays.add(element, {
              position: {
                bottom: 0,
                right: 0,
              },
              html: `<div style="width: 20px; background: #1e88e5; color: #bbdefb; border-radius: 50%; height: 20px; text-align: center; margin-top: -20px; margin-left: 2px;">${activityCount}</div>`,
            }),
          );
        }
      };

      const elements = canvas._elementRegistry._elements;
      const { activity, incidents } = this.props;
      for (const bpmnElement in elements) {
        let activityCount,
          incidentsCount = 0;
        if (Array.isArray(incidents)) {
          const arr = incidents.filter(x => x.activityId === bpmnElement);
          incidentsCount = arr.length;
          if (incidentsCount > 0) {
            const taskActivity = activity.filter(x => x.activityId === bpmnElement);
            activityCount = taskActivity.length;
            addMarker(bpmnElement, 'task-incident', activityCount, incidentsCount);
            continue;
          }
        }
        if (Array.isArray(activity)) {
          const taskActivity = activity.filter(x => x.activityId === bpmnElement);
          activityCount = taskActivity.length;

          if (taskActivity.length > 0) {
            const lastActivity = taskActivity[taskActivity.length - 1];
            const { activityType, endTime, canceled } = lastActivity;
            if (activityType === 'subProcess') continue;
            if (endTime && !canceled) {
              addMarker(bpmnElement, 'task-finished', activityCount, incidentsCount);
              continue;
            } else if (endTime && canceled) {
              addMarker(bpmnElement, 'task-canceled', activityCount, incidentsCount);
              continue;
            } else if (!endTime && !canceled) {
              addMarker(bpmnElement, 'task-active', activityCount, incidentsCount);
              continue;
            }
          }
        }
      }
      this.setState({ markers, counts });
    } catch (e) {
      console.error(e);
    }
  }

  getTaskInfoDOMElement(bpmnElement: string, textContent: string, shiftToRight: boolean) {
    const rootEl: HTMLDivElement = document.createElement('div');
    rootEl.className = `element-info ui bottom ${shiftToRight ? 'left' : 'right'} popup transition visible tiny`;

    const headerEl = rootEl.appendChild(document.createElement('div'));
    headerEl.className = 'ui dividing header';
    headerEl.style.display = 'flex';
    const headerLabel = headerEl.appendChild(document.createElement('div'));

    const headerName = headerLabel.appendChild(document.createElement('div'));
    headerName.appendChild(document.createTextNode(textContent));
    headerName.className = 'header-name';

    const headerId = headerLabel.appendChild(document.createElement('div'));
    headerId.appendChild(document.createTextNode(bpmnElement));
    headerId.className = 'header-id';

    const headerBtn = headerEl.appendChild(document.createElement('div'));
    const btn = headerBtn.appendChild(document.createElement('button'));
    btn.className = 'ui mini icon button';
    btn.innerHTML = '<i aria-hidden="true" class="close icon"></i>';
    btn.onclick = () => this.closeTaskInfo();

    const { activity, incidents } = this.props;

    if (Array.isArray(incidents)) {
      const taskIncidents = incidents.filter(x => x.activityId === bpmnElement);
      for (const incident of taskIncidents) {
        const tableEl = document.createElement('table');
        tableEl.className = 'ui celled definition very compact table';
        const bodyEl = document.createElement('tbody');
        tableEl.appendChild(bodyEl);

        let rowEl = document.createElement('tr');
        rowEl.className = 'negative';
        let tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode(incident.incidentType));
        rowEl.appendChild(tdEl);
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode(incident.id));
        rowEl.appendChild(tdEl);
        bodyEl.appendChild(rowEl);

        rowEl = document.createElement('tr');
        rowEl.className = 'negative';
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode('time'));
        rowEl.appendChild(tdEl);
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode(getTime(incident.incidentTimestamp)));
        rowEl.appendChild(tdEl);
        bodyEl.appendChild(rowEl);

        rowEl = document.createElement('tr');
        rowEl.className = 'negative';
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode('message'));
        rowEl.appendChild(tdEl);
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode(incident.incidentMessage));
        rowEl.appendChild(tdEl);
        bodyEl.appendChild(rowEl);

        rootEl.appendChild(tableEl);
      }
    }

    const taskActivity = activity.filter(x => x.activityId === bpmnElement);
    for (const task of taskActivity) {
      const tableEl = document.createElement('table');
      tableEl.className = 'ui celled definition very compact table';
      const bodyEl = document.createElement('tbody');
      tableEl.appendChild(bodyEl);

      let rowEl = document.createElement('tr');
      let tdEl = document.createElement('td');
      tdEl.appendChild(document.createTextNode(task.activityType));
      rowEl.appendChild(tdEl);
      tdEl = document.createElement('td');
      tdEl.setAttribute('colspan', '2');
      tdEl.appendChild(document.createTextNode(task.id));
      rowEl.appendChild(tdEl);
      bodyEl.appendChild(rowEl);

      rowEl = document.createElement('tr');
      tdEl = document.createElement('td');
      tdEl.appendChild(document.createTextNode('start / end'));
      rowEl.appendChild(tdEl);
      tdEl = document.createElement('td');
      tdEl.className = 'six wide';
      tdEl.appendChild(document.createTextNode(getTime(task.startTime) || '-'));
      rowEl.appendChild(tdEl);
      tdEl = document.createElement('td');
      tdEl.className = 'six wide';
      tdEl.appendChild(document.createTextNode(getTime(task.endTime) || '-'));
      rowEl.appendChild(tdEl);
      rowEl.appendChild(tdEl);
      bodyEl.appendChild(rowEl);

      if (task.durationInMillis) {
        rowEl = document.createElement('tr');
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode('duration'));
        rowEl.appendChild(tdEl);
        tdEl = document.createElement('td');
        tdEl.setAttribute('colspan', '2');
        tdEl.appendChild(document.createTextNode(millisecToString(task.durationInMillis, true)));
        rowEl.appendChild(tdEl);
        bodyEl.appendChild(rowEl);
      }
      if (task.canceled) {
        rowEl = document.createElement('tr');
        tdEl = document.createElement('td');
        tdEl.className = 'negative';
        tdEl.setAttribute('colspan', '3');
        tdEl.appendChild(document.createTextNode('canceled'));
        rowEl.appendChild(tdEl);
        bodyEl.appendChild(rowEl);
      }

      if (task.assignee) {
        rowEl = document.createElement('tr');
        tdEl = document.createElement('td');
        tdEl.appendChild(document.createTextNode('assignee'));
        rowEl.appendChild(tdEl);
        tdEl = document.createElement('td');
        tdEl.setAttribute('colspan', '2');
        tdEl.appendChild(document.createTextNode(task.assignee));
        rowEl.appendChild(tdEl);
        bodyEl.appendChild(rowEl);
      }

      rootEl.appendChild(tableEl);
    }
    return rootEl;
  }

  closeTaskInfo() {
    if (this.selectedRef.current) {
      this.bpmnViewer.get('overlays').remove(this.selectedRef.current);
      this.selectedRef.current = undefined;
    }
  }

  handleTaskClick(evt) {
    const element = evt.element;
  
    this.closeTaskInfo();

    const type = element.type;
    if (type === 'bpmn:Participant' || type === 'bpmn:Collaboration' || type === 'bpmn:Process')
      return;

    const elRect: DOMRect = evt.originalEvent.delegateTarget.getBoundingClientRect();
    const viewRect: DOMRect = evt.originalEvent.delegateTarget.nearestViewportElement.getBoundingClientRect();
    const x = elRect.right - viewRect.x;
    const right = x < viewRect.width / 2;

    const overlayHtml = this.getTaskInfoDOMElement(element.id, element.businessObject?.name || evt.gfx?.textContent, right);

    this.selectedRef.current = this.bpmnViewer.get('overlays').add(evt.element.id, {
      position: {
        bottom: 0,
        right: 0,
      },
      html: overlayHtml,
      scale: false
    });
  }

  handleKeydown(evt) {
    if (evt.key === 'Escape') {
      this.closeTaskInfo();
    }
  }

  handleError(e) {
    this.setState({ error: e });
  }

  handleImported(warnings) {
    this.setState({ imported: true, warnings });
  }

  handleDiagramReset() {
    if (!this.bpmnViewer) return;
    this.bpmnViewer.get('canvas').zoom('fit-viewport', 'auto');
  }

  handleDiagramZoomIn() {
    if (!this.bpmnViewer) return;
    const currentScale = this.bpmnViewer.get('canvas').viewbox().scale;
    this.bpmnViewer.get('canvas').zoom(currentScale + 0.3);
  }

  handleDiagramZoomOut() {
    if (!this.bpmnViewer) return;
    const currentScale = this.bpmnViewer.get('canvas').viewbox().scale;
    if (currentScale > 0.2) {
      this.bpmnViewer.get('canvas').zoom(currentScale - 0.1);
    }
  }

  private handleRefresh() {
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  render() {
    return (
      <div className="flex-space-between-start height-full">
        <div className="bpmn-diagram-container" ref={this.viewerRef} />
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10 }}>
          { this.props.onRefresh && (
            <>
              <Button.Group vertical basic>
                <Button
                  icon="refresh"
                  onClick={() => {
                    this.handleRefresh();
                  }}
                />
              </Button.Group>
              <Divider /> 
            </>
          )}
          <Button.Group vertical basic>
            <Button
              icon="crosshairs"
              onClick={() => {
                this.handleDiagramReset();
              }}
            />
            <Button
              icon="zoom-in"
              onClick={() => {
                this.handleDiagramZoomIn();
              }}
            />
            <Button
              icon="zoom-out"
              onClick={() => {
                this.handleDiagramZoomOut();
              }}
            />
          </Button.Group>
        </div>
      </div>
    );
  }
}

export default BpmnViewer;
