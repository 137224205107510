'use strict';

import angular from '../../camunda-bpm-sdk-js/vendor/angular';
import camTasklistFormEmbedded from './cam-tasklist-form-embedded';
import camTasklistForm from './cam-tasklist-form';
import CamSDK from '../../camunda-bpm-sdk-js/lib/angularjs/index';
import HttpClient from './cam-http-client';
import { getAppConfig } from '../../services/AppConfig';
import { getAuthService } from '../../services/AuthService';
import interceptors from './http-connector';
import notifications from './notifications';
import download from './http-download';
import optionsSourceDirective from './options-source';

const formModule = angular.module('cam.tasklist.form', ['cam.embedded.forms']);

formModule.config(['$provide', '$httpProvider', function ($provide, $httpProvider) {
  $provide.factory('CamHttpInterceptor', ['$q', '$injector', function($q, $injector) {
    return interceptors($q, $injector, getAuthService(), getAppConfig().bpmApiUrl);
  }]);
  $httpProvider.interceptors.push('CamHttpInterceptor');
}]);

formModule.factory('camAPI', [
  function() {
    return new CamSDK.Client({
      apiUri: getAppConfig().bpmApiUrl,
      HttpClient: HttpClient,
      engine: ''
    });
  }
]);

formModule.value('CamForm', CamSDK.Form);

formModule.directive('camTasklistFormEmbedded', camTasklistFormEmbedded);
formModule.directive('camTasklistForm', camTasklistForm);

formModule.service('Notifications', notifications);

formModule.factory('$exceptionHandler', ['$log', 'Notifications', function($log, Notifications) {
  return function(err, cause) {
    Notifications.addError({
      status: "Error",
      message: `Unexpected error ocurred: ${err.message}`,
      duration: 5000
    });
    $log.error(err, cause);
  };
}]);

formModule.factory('downloadFile', ['$http', '$log', 'Notifications', function($http, $log, Notifications) {
  return function(url, filename) {
    download({$http, $log, Notifications}, {url, filename});
  };
}]);

formModule.directive('downloadFile', ['downloadFile', function(downloadFile) {
  return {
    link: function(scope, element, attr) {
      element[0].onclick = function(event) {
        event.preventDefault();
        downloadFile(attr.href, attr.download);
      }
    }
  }
}]);

formModule.directive('optionsSource', ['$http', '$log', 'Notifications', optionsSourceDirective]);

export default formModule;
