import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'es6-promise'
import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App'
import 'semantic-ui-css/semantic.min.css'

const root = createRoot(document.getElementById('root'));
root.render(<App />);
