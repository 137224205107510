/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH
 * under one or more contributor license agreements. See the NOTICE file
 * distributed with this work for additional information regarding copyright
 * ownership. Camunda licenses this file to you under the Apache License,
 * Version 2.0; you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

'use strict';

module.exports = {
  DIRECTIVE_CAM_FORM: 'cam-form',
  DIRECTIVE_CAM_VARIABLE_NAME: 'cam-variable-name',
  DIRECTIVE_CAM_VARIABLE_TYPE: 'cam-variable-type',
  DIRECTIVE_CAM_FILE_DOWNLOAD: 'cam-file-download',
  DIRECTIVE_CAM_CHOICES: 'cam-choices',
  DIRECTIVE_CAM_SCRIPT: 'cam-script',
  DIRECTIVE_CAM_ERROR_CODE: 'cam-error-code',
  DIRECTIVE_CAM_ERROR_MESSAGE: 'cam-error-message',
  DIRECTIVE_CAM_ESCALATION_CODE: 'cam-escalation-code'
};
