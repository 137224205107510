import React from 'react';
import { Button, Form, InputOnChangeData, Modal } from 'semantic-ui-react';
import './DevModeSettings.css';

const localStorageKey = 'tasklist/DevModeSettings';

const defaultTemplatesBaseUrl = 'http://localhost:8080';

export interface DevModeConfig {
  template?: {
    baseUrl?: string;
  }
}

export function getDevModeConfig(): DevModeConfig | null {
  const s = localStorage.getItem(localStorageKey);
  if (s) {
    return s ? JSON.parse(s) : null;
  }
}

function saveDevModeConfig(config: DevModeConfig) {
  localStorage.setItem(localStorageKey, JSON.stringify(config));
}

export function getDevTemplatesBaseUrl(): string {
  return getDevModeConfig()?.template?.baseUrl || defaultTemplatesBaseUrl;
}

export interface DevModeSettingsProps {
  open: boolean;
  onClose: () => void;
  onChange: () => void;
}

interface DevModeSettingsState {
  config?: DevModeConfig;
  modified?: boolean;
}

export class DevModeSettings extends React.Component<DevModeSettingsProps, DevModeSettingsState> {
  
  constructor (props: DevModeSettingsProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.loadConfig();
  }

  async componentDidUpdate(oldProps: DevModeSettingsProps) {
    if (this.props.open !== oldProps.open) {
      this.loadConfig();
    }
  }

  private loadConfig() {
    const config = getDevModeConfig() || {};
    config.template = config.template || {};
    config.template.baseUrl = config.template.baseUrl || defaultTemplatesBaseUrl;
    this.setState({config, modified: false})
  }

  private templateBaseUrlChanged = (evt: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const config = {...this.state.config, template: {...this.state.config?.template, baseUrl: data.value}};
    this.setState({config, modified: true});
  }

  private applyAndClose = () => {
    if (this.state.modified) {
      saveDevModeConfig(this.state.config);
      this.props.onChange && this.props.onChange();
      this.setState({modified: false})
      this.props.onClose && this.props.onClose();
    }
  }

  private close = () => {
    this.props.onClose && this.props.onClose();
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.close}
        className='dev-mode-settings'
        size='tiny'
        centered={false}
      >
        <Modal.Header>Global Settings</Modal.Header>
        <Modal.Content>
          <Form
            size='tiny'
            onSubmit={this.applyAndClose}
          >
            <Form.Input
              label='Templates base URL:'
              value={this.state.config?.template?.baseUrl}
              onChange={this.templateBaseUrlChanged}
              autoFocus
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            compact
            disabled={!this.state.modified}
            onClick={this.applyAndClose}
            size='tiny'
          >
            OK
          </Button>
          <Button
            negative
            basic
            compact
            onClick={this.close}
            size='tiny'
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}