import axios, { AxiosError } from 'axios';
import EventEmitter from 'events';
import { getAppConfig } from './AppConfig';
import { ExternalLoginProps } from '../components/ExternalLogin';
import moment from 'moment';

interface AuthResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  require_auth: boolean;
  scope: string;
  token_type: string;
  user_type: string;
}

interface AuthData {
  userName: string
  expireAt: number;
  access_token: string;
  refresh_token: string;
}

// standalone app storage key
const sk_authData = 'auth-data';
const sk_authRefreshLocker = 'auth-refresh-locker';
// dashboard storage keys
const sk_dashboard_accessToken = 'dashboard_accessToken';
const sk_dashboard_refreshToken = 'dashboard_refreshToken';
const sk_email = 'email';
const sk_temporaryEmail = 'temporaryEmail';
const sk_permissions = 'permissions';
const sk_roles = 'roles';
const sk_dashboard_email = 'dashboard_email';
const sk_auth_token_expires_at = 'auth_token_expires_at';

// refresh the token 4 minutes before expiration;  
const refreshBeforeExpiration = 4 * 60000;

export type AuthEventType = 'userChanged' | 'stateChanged' | 'login' | 'logout';

export interface AuthEventEmitter extends EventEmitter {
  on(eventName: AuthEventType, listener: (...args: any[]) => void): this;
  off(eventName: AuthEventType, listener: (...args: any[]) => void): this;
  emit(eventName: AuthEventType, ...args: any[]): boolean;
}

export interface LoginData {
  userName: string;
  password: string;
  otp: string, hide: ()=>void;
}

export class AuthService {

  private config = getAppConfig();
  readonly events: AuthEventEmitter = new EventEmitter;
  private get serviceUrl() { return this.config.authServGetUrl; }
  private get serviceUrl2F() { return this.config.authServGetUrl2F || this.config.authServGetUrl; }
  private authData: AuthData;
  private refreshTimer: NodeJS.Timeout;
  private logoutTimer: NodeJS.Timeout;
  private tabId = crypto.randomUUID();
  private isIdle: boolean;
  showLoginDlg: (params: {errMsg?: string, otpMode?: boolean}) => Promise<LoginData>;
  showExternalLoginDlg: (params: null | ExternalLoginProps) => void;
  
  constructor() {
    this.handleNewData(this.readAuthData());
    addEventListener('storage', this.storageListener);
  }

  private storageListener = (evt: StorageEvent) => {
    const k = evt.key;
    if (k === sk_authData ||
      k === sk_auth_token_expires_at ||
      k === sk_dashboard_accessToken ||
      k === sk_dashboard_email ||
      k === sk_dashboard_refreshToken
    ) {
      this.handleNewData(this.readAuthData());
    }
  }

  private handleNewData(newData: AuthData) {
    const oldData = this.authData;
    const oldAuthenticated = this.isAuthenticated();
    const oldExpired = this.isExpired();
    this.authData = newData;
    const isAuthenticated = this.isAuthenticated();
    if (oldData?.expireAt !== newData?.expireAt || oldAuthenticated !== isAuthenticated) {
      this.scheduleRefresh();
      this.scheduleLogout();
    }
    if (oldData?.userName !== newData?.userName) {
      this.events.emit('userChanged', newData?.userName);
    }
    if (oldAuthenticated !== isAuthenticated ) {
      this.events.emit(isAuthenticated ? 'login' : 'logout'); 
      this.events.emit('stateChanged');
    } else if (oldExpired !== this.isExpired()) {
      this.events.emit('stateChanged');
    }
  }

  private readAuthData(): AuthData {
    if (this.config.isStandalone) {
      return JSON.parse(localStorage.getItem(sk_authData));
    } 
    const userName = JSON.parse(localStorage.getItem(sk_dashboard_email));
    return userName ? {
      userName,
      access_token: JSON.parse(localStorage.getItem(sk_dashboard_accessToken)),
      refresh_token: JSON.parse(localStorage.getItem(sk_dashboard_refreshToken)),
      expireAt: new Date(localStorage.getItem(sk_auth_token_expires_at)).getTime()
    } : null;
  }

  private saveAuthData(authData: AuthData | null): void {
    if (this.config.isStandalone) {
      localStorage.setItem(sk_authData, JSON.stringify(authData));
    } else {
      localStorage.setItem(sk_dashboard_email, JSON.stringify(authData.userName));
      localStorage.setItem(sk_dashboard_accessToken, JSON.stringify(authData.access_token));
      localStorage.setItem(sk_dashboard_refreshToken, JSON.stringify(authData.refresh_token));
      localStorage.setItem(sk_auth_token_expires_at,  moment(new Date(authData.expireAt)).format());
    }
    this.handleNewData(authData);
  }

  private deleteAuthData(): void {
    if (this.config.isStandalone) {
      localStorage.removeItem(sk_authData);
    } else {
      localStorage.setItem(sk_dashboard_accessToken, '""');
      localStorage.setItem(sk_dashboard_refreshToken, '""');
      localStorage.setItem(sk_email, '""');
      localStorage.setItem(sk_temporaryEmail, '""');
      localStorage.setItem(sk_permissions, '""');
      localStorage.setItem(sk_roles, '""');
    }
    this.handleNewData(null);
  }

  private async submit(userName: string, password: string): Promise<{authData: AuthData, requireOtp: boolean}> {
    try {
      const resp = await axios.post<AuthResponse>(this.serviceUrl, new URLSearchParams({
          grant_type: 'password',
          username: userName,
          password: password,
          scope: this.config.authScope
        })
      );
      return {
        authData: {
          userName: userName,
          expireAt: Date.now() + resp.data.expires_in * 1000,
          access_token: resp.data.access_token,
          refresh_token: resp.data.refresh_token
        },
        requireOtp: resp.data.require_auth
      }
    } catch (e) {
      const err = e as AxiosError<any>;
      throw new Error(`Authentication request failed: ${err.response?.data?.message || err.message}`);
    }
  }

  private async submit2F(authData: AuthData, otp: string): Promise<AuthData> {
    try {
      const resp = await axios.put<AuthResponse>(this.serviceUrl2F, new URLSearchParams({
          grant_type: 'authorize_code',
          '2fa_code': otp
        }), {headers: {Authorization: 'Bearer ' + authData.access_token}}
      );
      return {
        userName: authData.userName,
        expireAt: Date.now() + resp.data.expires_in * 1000,
        access_token: resp.data.access_token,
        refresh_token: resp.data.refresh_token
      }
    } catch (e) {
      const err = e as AxiosError<any>;
      throw new Error(`Authentication request failed: ${err.response?.data?.message || err.message}`);
    }
  }

  private async refresh(userName: string, refreshToken: string): Promise<AuthData> {
    const resp = await axios.post<AuthResponse>(this.serviceUrl, new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: this.config.authScope
      })
    );
    return {
      userName: userName,
      expireAt: Date.now() + resp.data.expires_in * 1000,
      access_token: resp.data.access_token,
      refresh_token: resp.data.refresh_token
    }
  }

  private async deleteToken(): Promise<void> {
    if (this.authData?.access_token) {
      axios.delete(this.config.authServDeleteUrl, {headers:{Authorization: `Bearer ${this.authData.access_token}`}});
    }
  } 

  private scheduleLogout() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    if (this.isAuthenticated()) {
      const timeout = this.authData.expireAt - Date.now();
      if (timeout >= 0) {
        this.logoutTimer = setTimeout(() => {
          this.logoutTimer = null;
          if (!this.isAuthenticated()) {
            this.events.emit('logout');
          } else {
            this.scheduleLogout();
          }
        }, timeout);
      }
    }
  }

  private scheduleRefresh(attempt: number = 0) {

    const canRefresh = () => this.authData && this.authData.refresh_token && this.authData.expireAt > Date.now() &&
      !this.isIdle;

    const handleRefresh = () => {
      this.refreshTimer = null;
      console.log(new Date(), 'refresh', this.authData);
      // acquire lock, then wait 2s 
      localStorage.setItem(sk_authRefreshLocker, this.tabId);
      setTimeout(async () => {
        // skip if current page isn't a last locker
        if (localStorage.getItem(sk_authRefreshLocker) !== this.tabId) {
          console.log('lock is not acquired');
          // A successful blocker may be closed before the refresh is completed. Therefore we reschedule refresh.
          this.scheduleRefresh();
          return;
        }
        if (!canRefresh()) {
          console.log('can not refresh');
          return;
        }
        if(this.authData.expireAt - Date.now() > refreshBeforeExpiration) {
          console.log('already refreshed');
          this.scheduleRefresh();
          return;
        }
        try {
          // request new token
          const authData = await this.refresh(this.authData.userName, this.authData.refresh_token);
          this.saveAuthData(authData);
        } catch(err) {
          console.log(err);
          if (err instanceof AxiosError && err.response?.status === 401) {
            console.log('invalid refresh token');
          } else {
            // schedule another attempt
            this.scheduleRefresh(attempt + 1);
          }
        }
      }, 2000)
    }

    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
    if (!canRefresh()) {
      console.log('refresh not scheduled');
      return;
    }
    //token will expire after maxTimeout
    const maxTimeout = this.authData.expireAt - Date.now();
    // calc incremental delay for retry (min 2s max 30s)
    const backoff = Math.min(2000 * Math.pow(2, attempt), 30000, maxTimeout - 1000);
    // calc timeout as 5m before expiration (if les then 5m remain then backoff)
    const timeout = Math.max(maxTimeout - refreshBeforeExpiration, backoff);
    if (timeout > 0) {
      this.refreshTimer = setTimeout(handleRefresh, timeout);
      console.log('refresh scheduled to:', new Date(Date.now() + timeout));
    } else {
      console.log('refresh not scheduled');
    }
  }

  private async login2F(authData1: AuthData): Promise<AuthData>{
    let params = await this.showLoginDlg({otpMode: true});
    for(;;) 
    {
      if (!params) {
        return null;            
      }
      try {
        const authData = await this.submit2F(authData1, params.otp);
        if (params.hide) {
          params.hide();
        }
        return authData;
      } catch(e) {
        params = await this.showLoginDlg({errMsg: e.message || 'Authentication failed', otpMode: true});
      }
    }
  }

  private async login(): Promise<AuthData>{
    if (!this.showLoginDlg) {
      throw new Error('No UI for login provided');
    }

    let params = await this.showLoginDlg({});
    for(;;) {
      if (!params) {
        throw new Error('You are not logged in.');
      }
      try {
       const resp = await this.submit(params.userName, params.password);

       let authData: AuthData;
       if (resp.requireOtp) {
          authData = await this.login2F(resp.authData);
          if (authData === null) {
            params = await this.showLoginDlg({});
            continue;
          }
        } else {
          authData = resp.authData
        }

        if (params.hide) {
          params.hide();
        }
        return authData;

      } catch(e) {
        params = await this.showLoginDlg({errMsg: e.message || 'Authentication failed'});
      }
    }
  }

  private async externalLogin(): Promise<AuthData>{
    if (!this.showExternalLoginDlg) {
      throw new Error('No UI for login provided');
    }
    return new Promise<AuthData>((resolve, reject) => {
      let loginPage: Window;
      function openLoginPage() {
        loginPage = window.open('/login?redirect=/blank', '_blank');
      }

      const checkLogged = () => {
        const authData = this.authData;
        if (authData && authData.expireAt > Date.now()) {
          try {
            loginPage?.close();
          } catch(e) {
            console.log(e);
          }
          this.showExternalLoginDlg(null);
          resolve(authData);
          return true;
        } else {
          return false;
        } 
      }

      const storageListener = (e: StorageEvent)=> {
        if (e.key === sk_dashboard_email && e.newValue) {
          if (checkLogged()) {
            window.removeEventListener('storage', storageListener);
          }
        }
      }

      const waitLogin = () => {
        if (!checkLogged()) {
          window.addEventListener('storage', storageListener)
        }
      }

      this.showExternalLoginDlg({
        caption: 'Login via Dashboard',
        message: '',
        openLoginPage: openLoginPage,
        onClose: () => {
          this.showExternalLoginDlg(null);
          reject('You are not logged in.');
        }
      })

      waitLogin();
    });
  }

  private async getAuthData(): Promise<AuthData> {
    const storedData = this.authData;

    if (storedData && storedData.expireAt > Date.now()) {
      return storedData;
    }

    // do login
    let authData: AuthData;
    if (this.config.isStandalone) {
      authData = await this.login();
      this.saveAuthData(authData);
    } else {
      authData = await this.externalLogin();
    }
    return authData;
  }

  private promise: Promise<AuthData>;

  private getOrWaitAuthData(): Promise<AuthData> {
    if (!this.promise) {
      this.promise = new Promise(
        (resolve, reject) => {
          this.getAuthData().then(
            data => resolve(data)
          ).catch(
            err => reject(err)
          )
        }
      );
      this.promise.then(() => this.promise = null).catch(() => this.promise = null);
    }
    return this.promise;
  }

  setIdle(value: boolean) {
    this.isIdle = value;
    this.scheduleRefresh();
  }

  async getBearerToken(): Promise<string> {
    const authData = await this.getOrWaitAuthData();
    return authData.access_token;
  }

  getUserId(): string {
    return this.authData?.userName;
  }

  isAuthenticated(): boolean {
    return this.authData && this.authData.userName && this.authData.expireAt > Date.now();
  }

  isExpired(): boolean {
    return this.authData && this.authData.userName && this.authData.expireAt <= Date.now();
  }

  reset(): void {
    this.deleteAuthData();
  }

  async logout(): Promise<void> {    
    await this.deleteToken();
    this.deleteAuthData();
  }

  async requestLogin() {
    await this.getOrWaitAuthData();
  }

}

let instance: AuthService;
export const getAuthService = () => {
  return instance || (instance = new AuthService());
};