import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import './ClipboardButton.css';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
async function copyToClipboard ({ text, html }: { text: string, html: string }) {
  const clipboardData: any = {};
  const add = (type: string, data: string) => {
    const blob = new Blob([data], { type });
    clipboardData[type] = blob;
  }
  add('text/plain', text);
  if (html) {
    add('text/html', html);
  }
  return navigator.clipboard.write([new ClipboardItem(clipboardData)]);
};

let componentMounted = false;

const ClipboardButton = ({ text, html, title }: { text: string, html?: string; title?: string }) => {
  const [copied, setCopied] = React.useState(false);
  React.useEffect(() => {
    componentMounted = true;
    return () => {
      componentMounted = false;
    };
  });
  if (copied)
    return (
      <Popup
        trigger={<Icon color="green" name="check circle outline" />}
        content="copied"
        size="tiny"
        inverted
        open
        position="top center"
      />
    );

  if (text===undefined || text===null || text==='')
    return <></>

  return (
    <Icon
      name="copy outline"
      title={title || (html && /^<a /.test(html) ? 'Copy link to clipboard' : 'Copy to clipboard')}
      className='copy-btn'
      onClick={async () => {
        await copyToClipboard({text, html});
        setCopied(true);
        setTimeout(() => componentMounted && setCopied(false), 2000);
      }}
    />
  );
};

export default ClipboardButton;
