import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { Menu, Container, Icon, Modal, Popup, Dropdown, DropdownMenu, DropdownItem, Loader, Message, DropdownHeader, DropdownDivider, Dimmer} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { SemanticToastContainer } from 'react-semantic-toasts';
import './App.css';
import { TasklistGroups } from './components/TasklistGroups';
import { TasklistGroup, TasklistGroupProps } from './components/TasklistGroup';
import { TaskForm, TaskFormProps } from './components/TaskForm'
import { FindProcess } from './components/FindProcess';
import { ProcessInfo } from './components/ProcessInfo';
import { LoginPage } from './components/LoginPage';
import { Login } from './components/Login';
import { LoginData, getAuthService } from './services/AuthService';
import { ReportList } from './components/ReportList';
import { Supervisor, SupervisorProps } from './components/Supervisor';
import { getCurrentUser } from './services/CurrentUserService';
import { Group, ProcessDefinition } from './models';
import { getBpmRestService } from './services/BpmRestService';
import { StartForm } from './components/StartForm';
import { CompletedTasks, CompletedTasksProps } from './components/CompletedTasks';
import { getAppConfig } from './services/AppConfig';
import { ExternalLogin, ExternalLoginProps } from './components/ExternalLogin';
import { IdleTimer } from './components/IdleTimer';
import { IIdleTimer } from 'react-idle-timer';

const queryParams = (qs: string) => (qs || '')
      .slice(1)
      .split('&')
      .map(s => s.split('='))
      .reduce((r, p) => { r[p[0]] = p[1]; return r; }, {});

interface AppProps {
}
   
interface AppState {
  showLogin?: boolean;
  loginLoading?: boolean;
  loginErrMsg?: string;
  loginOtpMode?: boolean;
  loginCancelable?: boolean;
  loginCallback?: (params: any) => void;
  externalLogin?: ExternalLoginProps;
  userId?: string;
  authenticated?: boolean;
  expired?: boolean;
  supervisedGroups?: Group[];
  loadError?: Error;
  startableProcesses?: ProcessDefinition[];
  loadProcessesError?: Error;
  allowDevMode?: boolean;
  devMode?: boolean;
  isStandalone: boolean;
  dimmed?: boolean;
}

export default class App extends React.Component<AppProps, AppState> {

  private auth = getAuthService();

  private idleTimer: IIdleTimer;

  constructor(props: AppProps) {
    super(props);
    this.state = {
      isStandalone: getAppConfig().isStandalone
    }
    // provide login dialog to auth service
    this.auth.showLoginDlg = this.showLoginDlg;
    this.auth.showExternalLoginDlg = this.showExternalLoginDlg;
  }

  async componentDidMount() {
    if (!this.auth.showLoginDlg){
      // was unmounted without destroying
      this.auth.showLoginDlg = this.showLoginDlg;
    }
    if (!this.auth.showExternalLoginDlg){
      // was unmounted without destroying
      this.auth.showExternalLoginDlg = this.showExternalLoginDlg;
    }

    this.updateUserInfo();
    this.setState({authenticated: this.auth.isAuthenticated(), expired: this.auth.isExpired()});
    this.auth.events.on('userChanged', this.onUserChanged);
    this.auth.events.on('stateChanged', this.onAuthStateChanged);
    const allowDevMode = getAppConfig().mode === 'development';
    if (allowDevMode) {
      getAppConfig().events.on('developerMode', this.updateDevMode);
      this.updateDevMode();
    }
    this.setState({allowDevMode});
  }

  componentWillUnmount() {
    this.auth.showLoginDlg = null;
    this.auth.showExternalLoginDlg = null;
    this.auth.events.off('userChanged', this.onUserChanged);
    this.auth.events.off('stateChanged', this.onAuthStateChanged);
    getAppConfig().events.off('developerMode', this.updateDevMode);
  }

  private showLoginDlg = async (params: {errMsg?: string, otpMode: boolean}) => {
    return new Promise<LoginData>((resolve) => {
      this.setState({
        showLogin: true,
        loginErrMsg: params.errMsg,
        loginOtpMode: params.otpMode,
        loginLoading: false,
        loginCallback: async (params: any) => {
          if (params) {
            this.setState({
              loginLoading: true
            });      
            resolve(params ? {
              ...params,
              hide: () => {
                this.setState({
                  showLogin: false,
                  loginCallback: null
                })                
              }
            } : null);
          } else {
            this.setState({
              showLogin: false,
              loginCallback: null
            });
            resolve(null);
          }
        }
      });
    });
  }

  private showExternalLoginDlg = (props: null | ExternalLoginProps) => {
    this.setState({
      externalLogin: props
    });
  }

  private onUserChanged = (userId: string) => {
    this.setState({userId});
    this.updateUserInfo();
  }

  private onAuthStateChanged = () => {
    this.setState({authenticated: this.auth.isAuthenticated(), expired: this.auth.isExpired()});
  }

  private async updateUserInfo() {
    const supervisedGroups = await getCurrentUser().getSupervisedGroups();
    const userId = getCurrentUser().getUserId();
    this.setState({userId, supervisedGroups});
  }
  
  private async logout(props: RouteComponentProps) {
    await this.auth.logout();
    props.history.push('/login');
  }

  private loadStartableProcesses = async () => {
    if (this.state.startableProcesses) {
      return;
    }
    try {
      this.setState({startableProcesses: null, loadProcessesError: null});
      const startableProcesses = await getBpmRestService().getStartableProcesses();
      this.setState({startableProcesses});
    } catch (e) {
      this.setState({loadProcessesError: e});
    }
  }

  private updateDevMode = () => {
    this.setState({devMode: getAppConfig().developerMode});
  }

  private toggleDevMode  = () => {
    const devMode = !this.state.devMode;
    getAppConfig().setDeveloperMode(devMode);
  }

  private onIdle = () => {
    console.log('idle');
    this.auth.setIdle(true);
    this.setState({dimmed: true});
  }

  private onActive = () => {
    this.setState({dimmed: false});
    this.auth.setIdle(false);
    console.log('active');
  }

  private onPrompt = () => {
    this.setState({dimmed: true});
    console.log('prompt');
  }

  private onAction = (evt: Event, timer: IIdleTimer) => {
    const dimmed = this.state.dimmed;
    if (timer.isPrompted()) {
      timer.activate();
      console.log('activate when in prompted state', timer.isPrompted());
    }
    if (dimmed) {
      this.onWokeUp();
    }
  }

  private onWokeUp = () => {
    this.setState({dimmed: false});
    if (!this.auth.isAuthenticated()) {
      this.auth.requestLogin();
    }
  }
 
  private renderContent(props: RouteComponentProps, content: React.ReactNode ): JSX.Element {
    const activeItem: string = props.location.pathname;
    const query: string = props.location.search || '';
    return (
      <>
        {this.state.loadError ? 
          <Message error content={this.state.loadError.message}/> : ''
        }
        <Menu pointing secondary>
          <Menu.Item className='logo'>
            <i/>
            <div>
              <div>task</div>
              <div>list</div>
            </div>
          </Menu.Item>
          <Menu.Item
            active={/^\/tasklist/.test(activeItem) && !/^\/tasklist\/(claimed|supervisor|completed)/.test(activeItem)}
            href='#/tasklist/groups'
          >
            Tasks
          </Menu.Item>
          <Menu.Item
            active={/^\/tasklist\/claimed/.test(activeItem)}
            href='#/tasklist/claimed/groups'
          >
            My tasks
          </Menu.Item>

          {this.state.supervisedGroups && this.state.supervisedGroups.length > 0 ? (
            this.state.supervisedGroups.length > 1 ?
              <Dropdown item text='My team&apos;s tasks' className={/^\/tasklist\/supervisor/.test(activeItem) ? 'active' : ''}>
                <DropdownMenu>
                  {this.state.supervisedGroups.map(group =>
                    <DropdownItem
                      key={group.id}
                      text={group.name}
                      href={`#/tasklist/supervisor?teamId=${group.id}`}
                      icon={query.includes(`teamId=${group.id}`) ? 'check' : ''}
                    />
                  )}
                </DropdownMenu>
              </Dropdown>
            :
              <Menu.Item
                active={/^\/tasklist\/supervisor/.test(activeItem)}
                href={`#/tasklist/supervisor?teamId=${this.state.supervisedGroups[0].id}`}
              >
                My team&apos;s tasks
              </Menu.Item>
          ) : ''}

          <Menu.Item
            active={/^\/tasklist\/completed/.test(activeItem)}
            href='#/tasklist/completed'
          >
            Completed tasks
          </Menu.Item>

          <Dropdown item scrolling text='Create' onOpen={this.loadStartableProcesses} className={/^\/start-process/.test(activeItem) ? 'active' : ''}>
            <DropdownMenu>
              {this.state.startableProcesses ? 
                this.state.startableProcesses.length > 0 ?
                  <>
                    <DropdownHeader content='Select a process to start'/>
                    <DropdownDivider />
                    {this.state.startableProcesses.map(item =>
                      <DropdownItem
                        key={item.key}
                        text={item.name}
                        href={`#/start-process/${item.key}`}
                      />
                    )}
                  </>
                :
                  <Message info>
                    You have no processes available to start
                  </Message>
              :
                this.state.loadProcessesError ?
                  <Message error content={this.state.loadProcessesError.message}/>
                :              
                  <DropdownItem disabled>
                    <Loader active inline='centered' size='small'/>
                  </DropdownItem>
              }
            </DropdownMenu>
          </Dropdown>

          <Menu.Item
            active={/^\/find-process/.test(activeItem)}
            href='#/find-process'
          >
            <Icon name='search' color='grey'/>
            Search
          </Menu.Item>

          <Menu.Menu position='right'>
            <Dropdown
              item
              trigger={
                <Icon
                  name='user'
                  fitted
                  color={this.state.authenticated ? 'green' : 'grey'}
                />
              }
            >
              <DropdownMenu direction='left'>
                <DropdownHeader>
                  <span className='user-id'>
                    {this.state.userId || 'Not logged in'}
                  </span>
                </DropdownHeader>
                {this.state.expired ?
                  <DropdownHeader>
                    <span className='warning'>
                      Session expired
                    </span>
                  </DropdownHeader>
                : ''}
                <DropdownDivider />
                {this.state.allowDevMode ?
                  <DropdownItem
                    onClick={this.toggleDevMode}
                    icon={this.state.devMode ? 'check' : null}
                    text={this.state.devMode ? 'Developer mode' : 'Enable developer mode'}
                  /> : ''
                }
              </DropdownMenu>
            </Dropdown>
            <Menu.Item
              name='logout'
              onClick={ () => this.logout(props)}
              disabled={!this.state.userId}
            >
              <Popup
                trigger = {<Icon name='log out'/>}
                content='Log out'
                position='bottom left'
                offset={[4, 0]}
                size="tiny"
                inverted
              />
            </Menu.Item>
          </Menu.Menu>

        </Menu>

        <Container fluid style={{ paddingLeft: '1em', paddingRight: '1em'}}>
            {content}
        </Container>

        <Modal open={this.state.showLogin}
          onClose={
            () => {
              this.state.loginCallback(null);
            }
          }
          closeIcon={!/^\/login$/.test(activeItem)}
          closeOnDimmerClick = {false}
          size='tiny'
        >
          <Modal.Content>
            <Login
              errorMessage={this.state.loginErrMsg}
              loading={this.state.loginLoading}
              otpMode={this.state.loginOtpMode}
              callback={this.state.loginCallback}
            />
          </Modal.Content>
        </Modal>

        <Modal open = {!!this.state.externalLogin}
          onClose={
            () => {
              this.state.externalLogin?.onClose();
            }
          }
          closeIcon={!/^\/login$/.test(activeItem)}
          closeOnDimmerClick = {false}
          size='tiny'
        >
          <Modal.Content>
            <ExternalLogin {...this.state.externalLogin}/>
          </Modal.Content>
        </Modal>
      </>
    );
  }

  render(): JSX.Element {
    return (
      <>
        <Router>
          <Switch>
            <Route path='/' exact render={(props: RouteComponentProps) => this.renderContent(props, <Redirect to='/tasklist/groups' />)} />
            <Route path='/login' render={(props: RouteComponentProps) => this.renderContent(props, <LoginPage {...props} />)} />
            <Route path='/tasklist' exact render={(props: RouteComponentProps) => this.renderContent(props, <Redirect to='/tasklist/groups' />)} />
            <Route path='/tasklist/groups' render={(props: RouteComponentProps) => this.renderContent(props, <TasklistGroups claimedOnly={false}/>)} />
            <Route path='/tasklist/claimed/groups' render={(props: RouteComponentProps) => this.renderContent(props, <TasklistGroups claimedOnly={true}/>)} />
            <Route path='/reports' render={(props) => this.renderContent(props, <ReportList/>)} />

            <Route path='/tasklist/supervisor' exact render={(props: RouteComponentProps) => this.renderContent(props,
              <Supervisor
                {...queryParams(props.location.search) as SupervisorProps}
                query={props.location.search}            
              />)}
            />

            <Route path='/tasklist/supervisor/task/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <Supervisor
                taskId={props.match.params.id}
                query={props.location.search}
                history={props.history}
              />)}
            />

            <Route path='/tasklist/group' render={(props: RouteComponentProps) => this.renderContent(props,
              <TasklistGroup
                {...queryParams(props.location.search) as TasklistGroupProps}
                query={props.location.search}
              />)}
            />
            
            <Route path='/tasklist/claimed/group' render={(props: RouteComponentProps) => this.renderContent(props,
              <TasklistGroup
                {...queryParams(props.location.search) as TasklistGroupProps}
                claimedOnly={true}
                query={props.location.search}
              />)}
            />
            
            <Route path='/tasklist/task/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <TasklistGroup 
                {...queryParams(props.location.search) as TasklistGroupProps}
                taskId={props.match.params.id}
                query={props.location.search}
                history={props.history}
              />)}
            />
            
            <Route path='/tasklist/claimed/task/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <TasklistGroup
                {...queryParams(props.location.search) as TasklistGroupProps} 
                claimedOnly={true}
                taskId={props.match.params.id}
                query={props.location.search} 
                history={props.history}
              />)}
            />

            <Route path='/tasklist/completed' exact render={(props: RouteComponentProps) => this.renderContent(props,
              <CompletedTasks
                {...queryParams(props.location.search) as CompletedTasksProps}
                query={props.location.search}
              />)}
            />

            <Route path='/tasklist/completed/task/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <CompletedTasks
                {...queryParams(props.location.search) as CompletedTasksProps}
                taskId={props.match.params.id}
                query={props.location.search} 
                history={props.history}
              />)}
            />

            <Route path='/task/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <TaskForm
                {...queryParams(props.location.search) as TaskFormProps} 
                taskId={props.match.params.id}
              />)} />

            <Route path='/start-process/:key' exact render={(props: RouteComponentProps) => this.renderContent(props,
              <StartForm
                procDefKey={props.match.params.key}
                history={props.history}
              />)} />

            <Route path='/find-process' exact render={(props: RouteComponentProps) => this.renderContent(props, <FindProcess/>)} />
            
            <Route path='/find-process/process-info/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <FindProcess processInfoProps={{procInstId: props.match.params.id, history: props.history, ...queryParams(props.location.search)}} />)} />

            <Route path='/process-info/:id' render={(props: RouteComponentProps) => this.renderContent(props,
              <ProcessInfo procInstId= {props.match.params.id} history= {props.history} {...queryParams(props.location.search)} />)} />

            <Route render={(props: RouteComponentProps) => this.renderContent(props, <div className='error_msg'>Not found</div>)} />
          </Switch>
        </Router>

        <Dimmer active={this.state.dimmed} onClickOutside={this.onWokeUp} page/>

        <SemanticToastContainer/>

        <IdleTimer
          timeout={15 * 60000}
          promptBeforeIdle= {59000}
          throttle={500}
          crossTab={true}
          syncTimers={250}
          onIdle={this.onIdle}
          onActive={this.onActive}
          onPrompt={this.onPrompt}
          onAction={this.onAction}
        />

      </>
    );
  }

}