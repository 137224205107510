import EventEmitter from "events";

export type AppConfigEventType = 'developerMode';

export interface AppConfigEventEmitter extends EventEmitter {
  on(eventName: AppConfigEventType, listener: (...args: any[]) => void): this;
  off(eventName: AppConfigEventType, listener: (...args: any[]) => void): this;
  emit(eventName: AppConfigEventType, ...args: any[]): boolean;
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class AppConfig {
  readonly mode: string;
  readonly backendMode: string;
  readonly isStandalone: boolean;
  readonly authServGetUrl: string;
  readonly authServGetUrl2F: string;
  readonly authServDeleteUrl: string;
  readonly resetPasswordPage: string;
  readonly authScope: string;
  readonly authReqUrlPattern: RegExp;
  readonly bpmApiUrl: string;
  readonly processesApiUrl: string;
  readonly bpmNotifServUrl: string;
  readonly reportsUrl: string;

  constructor() {
    const mode = process.env.REACT_APP_MODE;
    const authServGetUrl = process.env.REACT_APP_AUTH_SERVICE_URL;
    const authServGetUrl2F = process.env.REACT_APP_AUTH_SERVICE_URL_2F;
    const authServDeleteUrl = process.env.REACT_APP_AUTH_SERVICE_URL_LOGOUT;
    const resetPasswordPage = process.env.REACT_APP_RESET_PASSWORD_PAGE
    const authScope = process.env.REACT_APP_AUTH_SCOPE;
    const authScopeExternal = process.env.REACT_APP_AUTH_SCOPE_EXTERNAL;
    const authReqUrlPattern = process.env.REACT_APP_AUTHORIZE_REQ_URL_PATTERN;
    const bpmApiUrl = process.env.REACT_APP_BPM_SERVICE_URL;
    const processesApiUrl = process.env.REACT_APP_PROCESSES_API_URL;
    const bpmNotifServUrl = process.env.REACT_APP_NOTIF_SERVICE_URL;
    const reportsUrl = process.env.REACT_APP_REPORTS;
  
    const baseUrl = window.location.href.replace(/#.*$/, '').replace(/\/$/, '');
    const hostUrl = baseUrl.replace(/\/[^:]*$/, '');
    const isStandalone = !/\/tasklist\/?$/.test(baseUrl);

    function expandUrl(url: string): string {
      if (!url) {
        return '';
      }
      if (/^https?:\/\//.test(url)) {
        return url;
      }
      if (/^\//.test(url)) {
        return hostUrl + url;
      }
      return baseUrl + '/' + url;
    }

    this.mode = mode;
    this.isStandalone = isStandalone;
    this.authServGetUrl = expandUrl(authServGetUrl);
    this.authServGetUrl2F = expandUrl(authServGetUrl2F);
    this.authServDeleteUrl = expandUrl(authServDeleteUrl);
    this.resetPasswordPage = resetPasswordPage;
    this.authScope = isStandalone ? authScope : authScopeExternal;
    this.authReqUrlPattern = authReqUrlPattern ? new RegExp(authReqUrlPattern, 'i') : null;
    this.bpmApiUrl = expandUrl(bpmApiUrl);
    this.backendMode = /test/.test(this.bpmApiUrl) ? 'test' : 'production';;
    this.processesApiUrl = expandUrl(processesApiUrl);
    this.bpmNotifServUrl = expandUrl(bpmNotifServUrl);
    this.reportsUrl = expandUrl(reportsUrl);

    console.log(`loaded environment: ${JSON.stringify(this)}`);
  }

  private localStorageKey = 'tasklist/AppConfig';

  events: AppConfigEventEmitter = new EventEmitter;

  private _developerMode: boolean;
  get developerMode(): boolean {
    if (this._developerMode === undefined) {
      const s = localStorage.getItem(`${this.localStorageKey}/developerMode`);
      this._developerMode = s ? !!(JSON.parse(s)) : false;
    }
    return this._developerMode;
  };

  setDeveloperMode(value: boolean) {
    this._developerMode = value;
    localStorage.setItem(`${this.localStorageKey}/developerMode`, JSON.stringify(value));
    this.events.emit('developerMode', value);
  }

}

let instance: AppConfig;
export const getAppConfig = () => {
  return instance || (instance = new AppConfig());
};
