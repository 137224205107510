import { ToastOptions, toast } from 'react-semantic-toasts';
import { store as toastStore } from 'react-semantic-toasts/build/toast';

export class Toaster {
    private items: any[];

    add(item: ToastOptions) {
      if (!this.items) {
        this.items = [];
      }
      const ref = {item: null};
      const delNotif = () => this.items.splice(this.items.indexOf(ref.item), 1)
      toast(item, delNotif, null, delNotif);
      const newItem = toastStore.data[toastStore.data.length - 1]
      ref.item = newItem;
      this.items.push(newItem);
    }
  
    clear() {
      this.items?.forEach(item => toastStore.remove(item));
    }  
}