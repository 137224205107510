import React from 'react';
import '../styles.css';
import './TasklistGroups.css';
import { TaskGroup } from '../models/TaskList';
import { Popup, Button, Container } from 'semantic-ui-react'
import { AgGridReact } from '@ag-grid-community/react';
import './ag-grid-modules';
import { GridApi, GridOptions } from '@ag-grid-community/core';
import './ag-grid.css';
import { getBpmRestService } from '../services/BpmRestService';
import { ErrorMessage } from './ErrorMessage';


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

interface TasklistGroupsState {
  list?: TaskGroup[];
  gridProps: GridOptions;
  loading: boolean;
  loadError?: Error;
  reloadDisabled?: boolean;
  agentConnected: boolean;
  connectErr: string;
}

interface TasklistGroupsProps {
  claimedOnly: boolean
}

const cleanupTaskName = (s: string) => s?.replaceAll(/\(.*?\$\{.*?\}.*?\)|\[.*?\$\{.*?\}.*?\]|\$\{.*?\}/g, '')

export class TasklistGroups extends React.Component<TasklistGroupsProps, TasklistGroupsState> {
  private lastViewed: Date | null = null;
  private gridApi: GridApi = null;

  constructor(props: any) {
    super(props);
    this.state = {
      gridProps: this.getGridProps(),
      loading: false,
      agentConnected: true,
      connectErr: ''
    };
  }

  getGridProps(): GridOptions {
    return {
      getRowClass: params => this.getListItemClassName(params),
      onGridReady: evt => this.gridReady(evt),
      domLayout: 'autoHeight',
      tooltipShowDelay: 500,
      defaultColDef: {
        autoHeight: true,
        suppressMenu: true,
        cellClass: 'cell-wrap-text',
        resizable: true
      },
      columnDefs: [
        {
          headerName: 'Process name',
          field: 'processName',
          width: 250,
          sortable: true,
          cellRenderer: params => this.getListItemLink(params, params.data.processName),
          cellClass: 'cell-wrap-text'
        },
        {
          headerName: 'Task name',
          valueGetter: params =>
            `${params.data.procDefName}\n${cleanupTaskName(params.data.taskName)}`,
          width: 350,
          sortable: true,
          cellRenderer: params => this.getListItemLink(params, cleanupTaskName(params.data.taskName)),
          cellClass: 'cell-wrap-text'
        },
        {
          headerName: 'Total',
          headerTooltip: 'Number of tasks in a group',
          field: 'taskCount',
          sortable: true,
          width: 80
        },
        {
          headerName: 'Unassigned',
          headerTooltip: 'Number of unclaimed tasks in a group',
          field: 'unassignedCount',
          sortable: true,
          width: 130
        },
      ]
    }
  }

  async componentDidMount() {
    this.loadList();
  }

  componentDidUpdate(prevProps: TasklistGroupsProps): void {
      if (prevProps.claimedOnly !== this.props.claimedOnly) {
        this.setState({gridProps: this.getGridProps()}, () => this.loadList())
      }
  }

  componentWillUnmount() {
  }

  private async loadList(silent = false) {
    if (!silent) {
      this.setState({ loading: true, reloadDisabled: true });
      setTimeout(() => this.setState({ reloadDisabled: false }), 4000);
    }
    try {
      const list = await getBpmRestService().getTaskGroups(this.props.claimedOnly);
      setTimeout(() => this.setState({ loading: false }), 250);
      this.setState({ list: list, loadError: null});
      const lastViewed =
        list && list.length
          ? list
              .map(gr => gr.maxLinkTime)
              .reduce((max, curr) => (!max || max < curr ? curr : max))
          : null;

      if (lastViewed != null && (this.lastViewed < lastViewed || this.lastViewed == null)) {
        this.lastViewed = lastViewed;
      }

    } catch (e) {
      this.setState({
        loading: false,
        loadError: e,
        list: []
      });
    }
  }

  private gridReady(evt: any) {
    this.gridApi = evt.api;
  }

  private getListItemLink(params: any, text: string): JSX.Element {
    const item: TaskGroup = params.data;
    const query = item.groupId ? `groupId=${item.groupId}` : `procDefKey=${item.procDefKey}&taskDefKey=${item.taskDefKey}`
    if (this.props.claimedOnly) {
      return <a href={`#/tasklist/claimed/group?${query}`}>{text}</a>;
    }
    return <a href={`#/tasklist/group?${query}`}>{text}</a>;
  }

  private getListItemClassName(params: any): string[] {
    const item: TaskGroup = params.data;
    return item
      ? [
        item.maxLinkTime > item.lastViewed || !item.lastViewed
            ? 'unviewed_group'
            : '',
        item.unviewedCount > 0 ? 'unviewed_task' : '',
        item.maxPriority > 50 ? 'prioritized' : ''
      ]
      : [];
  }

  render() {
    return (
      <>
        <Container fluid className='page-container'>
          <div>
            <div className='app-toolbar'>
              <div>
                <Popup
                  trigger={
                    <Button circular basic icon='refresh' size='medium' className='app-toolbar-btn'
                      disabled={ this.state.reloadDisabled }
                      loading = { this.state.loading }
                      onClick={ () => { this.loadList() } }
                    />
                  }
                  content='Refresh the list'
                  position='bottom left'
                  size="tiny"
                  inverted          
                />
                <div className='app-page-header'>{this.props.claimedOnly ? 'Tasks assigned to me' : 'All available tasks'}</div>
              </div>
            </div>

            <ErrorMessage error={this.state.loadError}/>

            <div className='ag-theme-alpine app-tasklist-grid'>
              <AgGridReact {...this.state.gridProps} rowData={this.state.list} />
            </div>
          </div>
        </Container>
      </>
    );
  }
}
