import React from 'react';
import { Message } from 'semantic-ui-react';
import './ErrorMessage.css';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const ErrorMessage = ({header, error}: {header?: string, error: Error | string}) => {
  if (!error) {
    return <></>
  }
  return (
    <Message negative>
      <Message.Header>{header || 'Error'}</Message.Header>
      {typeof error === 'string' ? error : error.message}
    </Message>
  );
};
