import React from "react";
import { Grid, Header, Form, Segment, Message, Button, Popup, Icon } from 'semantic-ui-react';
import './Login.css';
import { getAppConfig } from "../services/AppConfig";

export interface LoginProps {
  errorMessage: string,
  loading: boolean;
  otpMode: boolean;
  callback: (params: {userName: string, password: string} | {otp: string}) => void;
}

interface LoginState {
  showPassword?: boolean;
  validationMessage?: string;
  userName?: string;
  password?: string;
  otp?: string;
}

export class Login extends React.Component<LoginProps, LoginState> {

  otpInpRef = React.createRef<HTMLInputElement>();
  nameInpRef = React.createRef<HTMLInputElement>();
  passInpRef = React.createRef<HTMLInputElement>();

  constructor (props: any) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      otp: ''
    };
  }

  private submit = async ()  => {
    if (this.props.otpMode) {
      if (this.state.otp) {
        this.props.callback({otp: this.state.otp});
      } else {
        this.setState({validationMessage: 'Please enter the verification code'});
        setTimeout(() => this.otpInpRef.current && this.otpInpRef.current.focus()); 
      }
    } else {
      if (this.state.userName && this.state.password) {
        this.props.callback({userName: this.state.userName, password: this.state.password});
      } else {
        if (!this.state.userName) {
          this.setState({validationMessage: 'Please fill required fields'});
          setTimeout(() => this.nameInpRef.current && this.otpInpRef.current.focus());
        } else {
          this.setState({validationMessage: 'Please enter your password'});
          setTimeout(() => this.passInpRef.current && this.otpInpRef.current.focus());
        }
      }
    }
  }

  private handleValueChange = (state: LoginState) => {
    this.setState(state);
    if (this.props.otpMode ? this.state.otp : (this.state.userName && this.state.password)) {
      this.setState({validationMessage: null});
    } 
  }

  render() {
    return (
      <>
        <Grid textAlign='center'  verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 400 }}>
            <Header as='h2' color='black' textAlign="center">
              <i className='logo-icon'/>
              {this.props.otpMode ? 'Two-Factor Authentication' : 'Log in to your account'}
            </Header>
            <Form>
              <Segment loading={this.props.loading} basic>
                {this.props.errorMessage &&
                  <Message negative>
                    {this.props.errorMessage}
                  </Message>
                }
                {this.state.validationMessage &&
                  <Message negative>
                    {this.state.validationMessage}
                  </Message>
                }
                {this.props.otpMode ?
                <>
                  {<Form.Input>
                    <a href='javascript:;' onClick={() => this.props.callback(null)}>Back to Log In</a>
                  </Form.Input>}
                  <Popup
                    trigger={
                      <Form.Input
                        label={<label className='light'>Enter the verification code</label>}
                        input={{ref: this.otpInpRef}}
                        autoFocus
                        fluid
                        placeholder='Verification code'
                        autoComplete='off'
                        type='text'
                        name='otp'
                        value={this.state.otp}
                        onChange={(e) => this.handleValueChange({otp: e.target.value})}
                        error={this.state.validationMessage && !this.state.otp}
                      />
                    }
                    content={<>
                        Please enter the verification code generated by your authenticator app.
                      </>
                    }
                    position='top center'
                    size='tiny'
                    wide
                    inverted
                    mouseEnterDelay={500}
                  />
                </>
                :
                <>
                  <Form.Input
                    input={{ref: this.nameInpRef}}
                    autoFocus
                    fluid
                    icon='user'
                    iconPosition='left'
                    placeholder='User name'
                    name='userName'
                    value={this.state.userName}
                    onChange={(e) => this.handleValueChange({userName: e.target.value})}
                    error={this.state.validationMessage && !this.state.userName}
                  />
                  <Form.Input
                    fluid
                    iconPosition='left'
                    placeholder='Password'
                    autoComplete='on'
                    type={this.state.showPassword ? 'text': 'password'}
                    name='password'
                    value={this.state.password}
                    onChange={(e) => this.handleValueChange({password: e.target.value})}
                    error={this.state.validationMessage && !this.state.password}
                  >
                    <Icon name='lock' />
                    <input ref={this.passInpRef}/>
                    <Icon 
                      name={this.state.showPassword? 'eye slash' : 'eye'}
                      link
                      className="right"
                      onClick={() => this.setState({showPassword: !this.state.showPassword})}
                    />
                  </Form.Input>
                  <Form.Field className="reset-password">
                    <a rel="noreferrer" href={getAppConfig().resetPasswordPage} target="_blank">Forgot Password?</a>
                  </Form.Field>
                </>
                }
                <Form.Input>
                  <Button
                    fluid
                    color='linkedin'
                    size='large'
                    onClick={() => this.submit()}
                    active={false}
                  >
                    Log in
                  </Button>
                </Form.Input>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </>
    )
  }

}