import React from 'react';
import {
  Table,
  Popup,
  Segment,
  Dimmer,
  Loader,
  Form,
  DropdownItemProps,
  Button,
  Icon
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import './ag-grid-modules';
import { ColDef } from '@ag-grid-community/core';
import './ag-grid.css';
import { DictionaryItem, ProcessInstance, ProcInstListReq } from '../models';
import { ProcessInfo, ProcessInfoProps } from './ProcessInfo';
import { getVarColDefs, addColDefs } from './ProcListUtils';
import { getBpmRestService } from '../services/BpmRestService';
import { getAuthService } from '../services/AuthService';
import './FindProcess.css';
import { ErrorMessage } from './ErrorMessage';
import { formatDateTimeM, compareDates, inputDateFormat } from './utils';

const isEmptyObject = (obj: any) => {
  for (const k in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, k) && obj[k]) {
      return false;
    }
  }
  return true;
}

const isEmptyRequest = (r: FindProcessRequest) => {
  return !(
    (r.value && r.value !== '*')
    ||r.processDefinitionKey
    ||r.startedBy
    ||r.startedBefore
    ||r.startedAfter
    ||r.finishedBefore
    ||r.finishedAfter
    ||r.stage
  );
}

interface FindProcessRequest {
  processDefinitionKey?: string;
  startedBy?: string;
  startedAfter?: string;
  startedBefore?: string;
  finishedAfter?: string;
  finishedBefore?: string;
  value?: string;
  stage?:string;
  includeSubprocesses?: boolean;
}

interface FindProcessProps {
  processInfoProps?: ProcessInfoProps;
}

interface FindProcessState {
  searchRequest: FindProcessRequest;
  colDefs?: ColDef[];
  procStages?: DictionaryItem[];
  searchResult?: ProcessInstance[];
  processDefinitions?: DropdownItemProps[];
  formLoading?: boolean;
  formLoadError?: Error;
  searchLoading?: boolean;
  searchError?: Error;
  history: FindProcessRequest[];
  historyIsOpen?: boolean;
}

export class FindProcess extends React.Component<FindProcessProps, FindProcessState> {
  private historyStorageKey = 'tasklist/FindProcess/history';
  private historyLoaded: boolean;
  private currUser: string;
  private procStageToName: {[key: string]: string};
  private procStateToName: {[key: string]: string};
  private procStageToStates: {[key: string]: string[]};
  private gridColState: any;
  private defaultColDef: ColDef = {
    autoHeight: true,
    resizable: true
  }
  private initColDefs: ColDef[] = [
    {
      headerName: 'Process type',
      field: 'processDefinitionName',
      width: 300,
      sortable: true,
      cellRenderer: params => {
          const item: ProcessInstance = params.data;
          return <a href={`#/find-process/process-info/${item.id}`}>{params.value}</a>;
        },
      cellClass: 'cell-wrap-text'
    },
    {
      headerName: 'Initiator',
      field: 'startUserId',
      tooltipField: 'startUserId',
      width: 200,
      sortable: true,
    },
    {
      headerName: 'Start date',
      field: 'startTime',
      sortable: true,
      width: 160,
      valueFormatter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2)
    },
    {
      headerName: 'End date',
      field: 'endTime',
      sortable: true,
      width: 160,
      valueFormatter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2)
    },
    {
      headerName: 'Business key',
      field: 'businessKey',
      tooltipField: 'businessKey',
      width: 160,
      sortable: true,
    },
  ];
  
  constructor(props: FindProcessProps) {
    super(props);
    this.state = {
      searchRequest: {},
      history: []
    };
  }

  async componentDidMount() {
    if(this.props.processInfoProps) {
      return;
    }
    this.loadHistory();
    this.loadProcessDefinitions();
    this.loadDictionaries();
    this.currUser = getAuthService().getUserId();
  }

  componentDidUpdate(prevProps: FindProcessProps) {
    if(this.props.processInfoProps || !prevProps.processInfoProps) {
      return;
    }

    if(!this.historyLoaded) {
        this.loadHistory();
    }
    
    if(!this.state.processDefinitions) {
      this.loadProcessDefinitions();
    }

    if(!this.state.procStages) {
      this.loadDictionaries();
    }
  }

  private loadHistory() {
    const s = localStorage.getItem(this.historyStorageKey);
    if (s) {
      this.setState({ history: JSON.parse(s)});
    }
    this.historyLoaded = true;
  }

  private async loadProcessDefinitions() {
    try {
      const resp = await getBpmRestService().getProcessDefinitions();
      this.setState({
        formLoading: !this.state.procStages,
        processDefinitions: resp
          .sort((v1, v2) => v1.name >= v2.name ? 1 : -1)
          .map(item => ({
            key: item.id,
            text: item.name || item.key,
            value: item.key,
          })
        )
      });
    } catch (err) {
      this.setState({
        formLoading: !this.state.procStages,
        formLoadError: err
      })
    }
  }

  private async loadDictionaries() {
    try {
      const resp = await getBpmRestService().getDictionaries(['proc-state-stage', 'proc-stage'], true);
      this.procStageToName = {};
      resp['proc-stage'].forEach(item => {
        this.procStageToName[item.key] = item.value;
      })
      this.procStateToName = {};
      this.procStageToStates = {}
      resp['proc-state-stage'].forEach(item => {
        this.procStateToName[item.key] = this.procStageToName[item.value] ?? item.value;
        (this.procStageToStates[item.value] = this.procStageToStates[item.value] ?? []).push(item.key)
      })
      this.setState({
        formLoading: !this.state.processDefinitions,
        procStages: resp['proc-stage']
          .map(item => ({
            key: item.key,
            text: item.value || item.key,
            value: item.key
          })
        )
      });
    } catch (err) {
      this.setState({
        formLoading: !this.state.processDefinitions,
        formLoadError: err
      })
    }
  }

  private getColDefs(list: ProcessInstance[]): ColDef[] {
    const addList = getVarColDefs(list, {'proc-state': this.procStateToName});
    return addList && addList.length ? addColDefs(this.initColDefs, addList) : this.initColDefs;
  } 

  private prepareRequest(s: FindProcessRequest) {
    const r: ProcInstListReq = {} as ProcInstListReq;
    s.value = s.value?.trim();
    s.startedBy = s.startedBy?.trim();
    s.startedBefore = s.startedBefore?.trim();
    s.startedAfter = s.startedAfter?.trim();
    s.finishedBefore = s.finishedBefore?.trim();
    s.finishedAfter = s.finishedAfter?.trim();
    if (s.value) {   
      const or: ProcInstListReq = {
        variables: [{
          name: '*',
          operator: /\*/.test(s.value) ? 'like' : 'eq',
          value: s.value.replaceAll('*', '%')
        }],
        variableValuesIgnoreCase: true
      };
      if (/\*/.test(s.value)) {
        or.processInstanceBusinessKeyLike = s.value.replaceAll('*', '%');
      } else {
        or.processInstanceBusinessKey = s.value;
      }
      if (/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/.test(s.value)) {
        or.processInstanceId = s.value;
      }
      (r.orQueries = []).push(or);
    }
    if (s.processDefinitionKey) r.processDefinitionKey = s.processDefinitionKey;
    if (s.startedBy) r.startedBy = s.startedBy;
    if (s.startedBefore) r.startedBefore = moment(s.startedBefore, inputDateFormat).toDate();
    if (s.startedAfter) r.startedAfter = moment(s.startedAfter, inputDateFormat).toDate();
    if (s.finishedBefore) r.finishedBefore = moment(s.finishedBefore, inputDateFormat).toDate();
    if (s.finishedAfter) r.finishedAfter = moment(s.finishedAfter, inputDateFormat).toDate();
    if (!s.includeSubprocesses) r.rootProcessInstances = true;
    if (s.stage) {
      const or: ProcInstListReq = {
        variables: this.procStageToStates[s.stage].map(state => (
          {
            name: 'processStatus',
            operator: 'eq',
            value: state
          }
        ))
      };
      (r.orQueries = r.orQueries??[]).push(or);
    }
    r.sorting = [{sortBy: 'startTime', sortOrder: 'desc'}];
    return r;
  }

  private async searchProcesses() {
    try {
      this.setState({searchLoading: true});
      const resp = await getBpmRestService().findProcessInstances(this.prepareRequest(this.state.searchRequest), true, 500);
      this.setState({
        searchLoading: false,
        searchError: null,
        searchResult: resp || [],
        colDefs: this.getColDefs(resp)
      });
      this.saveHistory();
    } catch (err) {
      this.setState({
        searchLoading: false,
        searchError: err.message,
        searchResult: [],
        colDefs: this.getColDefs(null)
      });
    }
  }

  private addHistoryItem(list: any[], item: any) {
    const s = JSON.stringify(item);
    const idx = list.findIndex(item => JSON.stringify(item) === s);
    if (idx == 0) {
      return;
    } 
    if (idx > 0) {
      list.splice(idx, 1);;
    }
    list.splice(0, 0, item);
    if (list.length > 20) {
      list.splice(19, list.length - 20);
    }
  }

  saveHistory() {
    const {history, searchRequest} = this.state;
    const newHistory = [...history];
    this.addHistoryItem(newHistory, searchRequest);
    localStorage.setItem(this.historyStorageKey, JSON.stringify(newHistory));
    this.setState({history: newHistory});
  }

  removeHistoryItem(idx: number) {
    const newHistory = [...this.state.history];
    newHistory.splice(idx, 1);
    localStorage.setItem(this.historyStorageKey, JSON.stringify(newHistory));
    this.setState({history: newHistory});
  }

  selectHistoryItem(value: any, andExec: boolean) {
    this.setState({searchRequest: value, historyIsOpen: false});
    if (andExec && !isEmptyRequest(value)) {
      setTimeout(()=>this.searchProcesses());
    }

  }

  private getProcessName(processType: string): string {
    return this.state.processDefinitions?.find(item => item.value === processType)?.text as string || processType;
  }

  private renderHistoryField(label: string, value: string) {
    return (
      <div className='field'>
        <span className='label'>{label}: </span>
        <span className='value'>{value}</span>
      </div>
    )
  }

  private renderHistoryItem(req: FindProcessRequest) {
    return (
      <div className='history-item'>
        <>
          {req.value && this.renderHistoryField('Value', req.value)}
          {req.processDefinitionKey && this.renderHistoryField('Process type', this.getProcessName(req.processDefinitionKey))}
          {req.stage && this.renderHistoryField('Stage', this.procStageToName[req.stage]??req.stage)}
          {req.startedBy && this.renderHistoryField('Started by', req.startedBy)}
          {req.startedAfter && this.renderHistoryField('Started after', req.startedAfter.replace(/ 00:00$/, ''))}
          {req.startedBefore && this.renderHistoryField('Started before', req.startedBefore.replace(/ 00:00$/, ''))}
          {req.finishedAfter && this.renderHistoryField('Finished after', req.finishedAfter.replace(/ 00:00$/, ''))}
          {req.finishedBefore && this.renderHistoryField('Finished before', req.finishedBefore.replace(/ 00:00$/, ''))}
          {req.includeSubprocesses && this.renderHistoryField('Include sub-s', 'Yes')}
        </>
      </div>
    )
  }

  renderHistory() {
    const list: any[] = this.state.history||[];
    return (
      this.state.procStages && this.state.processDefinitions &&
      <Table compact='very' celled selectable className='history-table'>
        <Table.Body>
          {list.map((item, i) => (
            <Table.Row key={i}>
              <Table.Cell textAlign='center'>
                <Popup
                  trigger={
                    <Button
                      attached='left'
                      size='mini'
                      icon='reply'
                      primary
                      value={item}
                      onClick={ (evt, data: {value: any}) => {this.selectHistoryItem(data.value, false);}}
                    />
                  }
                  content='Fill in the fields'
                  position='top center'
                  size="tiny"
                  inverted
                  mouseEnterDelay={500}    
                />
                <Popup
                  trigger={
                    <Button
                      attached='right'
                      size='mini'
                      icon='search'
                      primary
                      value={item}
                      onClick={ (evt, data: {value: any}) => {this.selectHistoryItem(data.value, true);}}
                    />
                  }
                  content='Perform a search'
                  position='top center'
                  size="tiny"
                  inverted
                  mouseEnterDelay={500}     
                />
              </Table.Cell>
              <Table.Cell style={{ fontSize: 12 }}>
                {this.renderHistoryItem(item)}
              </Table.Cell>
              <Table.Cell textAlign='center'>
                <Button
                  className='history-del-btn'
                  size='mini'
                  icon='delete'
                  idx={i}
                  onClick={ (evt, data: {idx: number}) => {
                    this.removeHistoryItem(data.idx)
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  renderHistoryButton() {
    return (
      <Popup
        on='click'
        open={this.state.historyIsOpen}
        onClose={() => {this.setState({historyIsOpen: false})}}
        onOpen={() => {this.setState({historyIsOpen: true})}}
        wide='very'
        size="tiny"
        position='bottom left'
        trigger={
          <Button basic icon disabled={this.state.history.length===0}>
            Search history
            <Icon name='caret down'/>
          </Button>
        }
      >
        <Popup.Content style={{ maxHeight: 'calc(100vh - 44ex)', overflowY: 'auto' }}>
          {this.renderHistory()}
        </Popup.Content>
      </Popup>
    );
  }

  render() {
    const {searchRequest, searchResult} = this.state;

    if (this.props.processInfoProps) {
      return <ProcessInfo {...this.props.processInfoProps} isFromSearch={!!searchResult}/>
    }

    return (
      <Dimmer.Dimmable as='div' dimmed={this.state.searchLoading}>
        <Dimmer active={this.state.searchLoading} inverted>
          <Loader/>
        </Dimmer>
        <Segment>
          <ErrorMessage error={this.state.formLoadError}/>
          <Form>
            <Form.Group>
              <Popup
                trigger={
                  <Form.Input
                    width='5'
                    placeholder='Enter anything related to a process'
                    icon='search'
                    focus         
                    value={searchRequest.value || ''}
                    onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, value: data.value}})}
                  />
                }
                content={<>
                  Enter anything related to a process: Legal name; Company number; Applicant name; MID; Email etc.<br/>
                  You can use the * symbol to search by partial match.
                </>}
                position='top left'
                size='tiny'
                wide='very'
                inverted
                mouseEnterDelay={500}
              />
              <Form.Dropdown
                width='5'
                options={this.state.processDefinitions || []}
                placeholder='Process type'
                fluid
                selection
                clearable
                closeOnChange
                selectOnBlur={false}
                loading={!this.state.processDefinitions}
                search={true}
                value={searchRequest.processDefinitionKey || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, processDefinitionKey: data.value}})}
              />
             <Form.Dropdown
                width='3'
                options={this.state.procStages || []}
                placeholder='Process stage'
                fluid
                selection
                clearable
                closeOnChange
                selectOnBlur={false}
                loading={!this.state.procStages}
                search={true}
                value={searchRequest.stage || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, stage: data.value}})}
              />
              <Form.Input
                width='3'
                placeholder='Initiator'
                value={searchRequest.startedBy || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, startedBy: data.value}})}
              />
            </Form.Group>
            <Form.Group inline>
              <Form.Field label='Started:'/>
              <DateInput
                width='3'
                localization='en'
                name='Date'
                clearable
                closable={true}
                autoComplete='off'
                dateFormat={inputDateFormat}
                placeholder='Started after'
                iconPosition='left'
                value={searchRequest.startedAfter || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, startedAfter: data.value}})}
              />
              <DateInput
                width='3'
                localization='en'
                name='Date'
                clearable
                closable={true}
                autoComplete='off'
                minDate={searchRequest.startedAfter}
                dateFormat={inputDateFormat}
                placeholder='Started before'
                iconPosition='left'
                value={searchRequest.startedBefore || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, startedBefore: data.value}})}
              />
              <Form.Field/>
              <Form.Field label='Finished:'/>
              <DateInput
                width='3'
                localization='en'
                name='Date'
                clearable
                closable={true}
                autoComplete='off'
                dateFormat={inputDateFormat}
                placeholder='Finished after'
                iconPosition='left'
                value={searchRequest.finishedAfter || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, finishedAfter: data.value}})}
              />
              <DateInput 
                width='3'
                localization='en'
                name='Date'
                clearable
                closable={true}
                minDate={searchRequest.finishedAfter}
                autoComplete='off'
                dateFormat={inputDateFormat}
                placeholder='Finished before'
                iconPosition='left'
                value={searchRequest.finishedBefore || ''}
                onChange={(e, data: {value: string}) => this.setState({searchRequest: {...searchRequest, finishedBefore: data.value}})}
              />
              <Form.Field/>
              <Popup
                trigger={
                  <Form.Checkbox
                    width='3'
                    label='Include subprocesses'     
                    checked={searchRequest.includeSubprocesses}
                    onChange={(e, data: {checked: boolean}) => this.setState({searchRequest: {...searchRequest, includeSubprocesses: data.checked ?? undefined}})}
                  />
                }
                content={<>
                  Choose to search all processes.<br/>
                  If not checked, only top-level processes will be included in the search.
                </>}
                position='top left'
                size='tiny'
                inverted
                mouseEnterDelay={500}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: 0 }}>
              <Form.Field width='16'>
                <Button
                  primary
                  disabled={isEmptyRequest(searchRequest)}
                  onClick={() => {
                    this.searchProcesses();
                  }}
                >
                  Search
                </Button>
                <Button
                  disabled={isEmptyObject(searchRequest) && !searchResult}
                  onClick={() => {
                    this.setState({searchRequest: {}, searchResult: null, colDefs: this.getColDefs(null), searchError: null, searchLoading: false});
                  }}
                >
                  Reset
                </Button>
                {this.renderHistoryButton()}
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>

        <div>
          {searchResult && 
          <>
            <ErrorMessage error={this.state.searchError}/>
            <div className='ag-theme-alpine app-tasklist-grid'>
              <AgGridReact 
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 20, 50, 100, 500]}
                enableCellTextSelection
                enableBrowserTooltips
                onGridReady={evt => {
                  //evt.api.sizeColumnsToFit();
                  evt.api.applyColumnState({state: this.gridColState});
                }}
                onSortChanged={evt => {
                   this.gridColState = evt.columnApi.getColumnState();
                  }}
                onColumnResized={evt => {
                  this.gridColState = evt.columnApi.getColumnState();
                }}
                domLayout='autoHeight'
                overlayNoRowsTemplate={'No data found'}
                defaultColDef={this.defaultColDef}
                columnDefs= {this.state.colDefs} 
                rowData={searchResult}
              />
            </div>
          </>
          }
        </div>
      </Dimmer.Dimmable>
    );
  }


}
