export default function download(services: {$http, $log, Notifications},
    config: {method: string, url: string, params: any, filename: string}
) {
  const {method, url, params, filename} = config;
  const {$http, $log, Notifications} = services;
  $http({
    method: method || 'GET',
    url: url,
    params: params,
    responseType: 'arraybuffer'
  }).then(
    function(resp){
      const blob = new Blob([resp.data], {type: resp.headers('Content-Type') || 'application/octet-stream'});
      saveAs(blob, filename
        || /filename[^;=\\n]*=(([\\'"])(.*)?\2|[^;\\n]*)[\\n;]?/i.exec(resp.headers('Content-Disposition'))[3]
        || url.replace(/.*\//, '')
      )
    },
    function(err){
      if ($log) {
       services.$log.error(err);
      }
      else {
        console.log(err);
      }
      if (Notifications) {
        Notifications.addError({
          status: "Error",
          message: "Failed to download file\n(Status code: " + err.status + ")"
        });
      }
    }
  )
  
  function saveAs(blob, fileName){
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

}
