import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { ProcessInstance, ExtColDef } from '../models';
import { varColDefs } from './ProcListVarCols';

const defValueGetter = (p: ValueGetterParams) => {
  if (p.data.variables) {
    const v = p.data.variables[p.colDef.field];
    return v !== undefined && v !== null ? v : '';
  }
}

export function getVarColDefs(list: ProcessInstance[], dicts: {[key: string]: {[key: string]: string}}): ColDef[] {
  if(!list) {
    return [];
  }
  const keys = varColDefs.map(col => col.field)
  let keysLen = keys.length;
  for(let i = 0, n = list.length; i < n; ++i) {
    const {variables} = list[i];
    if(variables) {
      for(let j = keysLen - 1; j >= 0; --j) {
        const key = keys[j];
        if(variables[key] !== undefined) {
          keys.splice(j, 1);
          keysLen--;
          if (keysLen === 0) {
              break;
          }
        }
      }
    }
  }
  return varColDefs.filter(col => keys.indexOf(col.field) < 0).map(col => {
    const dict = dicts && col.dictionaryKey && dicts[col.dictionaryKey];
    const valueGetter = dict ?
      (p: ValueGetterParams) =>  {
        const v = defValueGetter(p);
        return (v && dict[v]) ?? v;
      } :
      defValueGetter;
      return {...col, valueGetter};
  })
}

export function addColDefs(to: ColDef[], fromList: ColDef[]): ColDef[] {
  const res: ColDef[] = [];
  const from = fromList as ExtColDef[];
  for(let i = 0; i < to.length; i++) {
    for(let j = 0, a = from[j]; j < from.length; a = from[++j]) {
      if(a.insertAt === i) {
        res.push(a)
      }
    }
    res.push(to[i]);
  }
  for(let j = 0, a = from[j]; j < from.length; a = from[++j]) {
    if(isNaN(a.insertAt) || a.insertAt > to.length || a.insertAt < 0) {
      res.push(a)
    }
  }
  return res;
}