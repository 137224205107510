'use strict';

export default [
  function() {
    return {
      consumers: [],

      addError: function(error) {
        this.addMessage({...error, type: 'error'});
      },

      addMessage: function(message) {
        for (var i = this.consumers.length - 1, c; (c = this.consumers[i]); i--) {
          // add to first interested consumer only
          if (c.add(message)) {
            break;
          }
        }
      },

      clearAll: function() {
        for (var i = this.consumers.length - 1, c; (c = this.consumers[i]); i--) {
          c.clear();
        }
      },

      registerConsumer: function(consumer) {
        this.consumers.push(consumer);
      },

      unregisterConsumer: function(consumer) {
        const consumers = this.consumers;
        const idx = consumers.indexOf(consumer);
        if (idx != -1) {
          consumers.splice(idx, 1);
        }
      }
    }
  }
]

