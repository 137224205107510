
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getAuthService } from '../services/AuthService';

interface LoginPageState {
}

export class LoginPage extends React.Component<RouteComponentProps, LoginPageState> {

  constructor (props: any) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    await getAuthService().requestLogin();
    this.props.history.push('/tasklist/groups');
  }

  render() {
    return <></>;
  }

}