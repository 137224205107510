import { AuthService } from '../../services/AuthService';

export default function interceptors($q, $injector, authService: AuthService, baseUrl: string) {

  function url(path: string): string {
    if (/^https?:\/\/.*/i.test(path))
      return path;
    return baseUrl + (/^\//.test(path) ? '' : '/') + path;
  }

  return {
    request: async (config) => {
      config.url = url(config.url);
      const token = await authService.getBearerToken();
      config.headers = {...config.headers, Authorization: 'Bearer ' + token};
      return $q.resolve(config);
    },

    responseError: function(rejection) {
      if (rejection.status === 401) {
        authService.reset();
        const $http = $injector.get('$http');
        return $q.resolve($http(rejection.config));
      }
      return $q.reject(rejection);
    }
  };
}
