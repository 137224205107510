'use strict';

var Q = require('q');
var AbstractClientResource = require('./../abstract-client-resource');
var utils = require('../../utils');

/**
 * No-Op callback
 */
function noop() {}

/**
 * Task Resource
 * @class
 * @memberof CamSDK.client.resource
 * @augments CamSDK.client.AbstractClientResource
 */
var Ext = AbstractClientResource.extend();

/**
 * Path used by the resource to perform HTTP queries
 * @type {String}
 */
Ext.path = 'ext';



Ext.historicFormVariables = function(data, done) {
  const queryData = {
    deserializeValues: data.deserializeValues
  };

  if (data.names) {
    queryData.variableNames = data.names.join(',');
  }

  return this.http.get(this.path + '/history/task/' + data.id + '/form-variables', {
    data: queryData,
    done: done || noop
  });
};


module.exports = Ext;
