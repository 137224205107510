
import React, { ChangeEvent, FormEvent } from 'react';
import { Button, Input, Popup, Form } from 'semantic-ui-react';
import './DevToolbar.css'
import { DevModeSettings, getDevTemplatesBaseUrl } from './DevModeSettings';
import { concatPath } from './utils';

const localStorageKey = 'tasklist/DevToolbar';

export interface PageDevConfig {
  template?: {
    enabled?: boolean;
    url?: string;
  }
}

export type PageType = 'taskList'|'taskForm'|'startForm';

export function getPageDevConfig(pageType: PageType, pageKey: string): PageDevConfig | null {
  const s = localStorage.getItem(`${localStorageKey}/${pageType}/${pageKey}`);
  if (s) {
    return s ? JSON.parse(s) : null;
  }
}

function savePageDevConfig(pageType: string, pageKey: string, config: PageDevConfig) {
  localStorage.setItem(`${localStorageKey}/${pageType}/${pageKey}`, JSON.stringify(config));
}

export interface DevToolbarProps {
  pageType: PageType;
  pageKey: string;
  defaultTemplate?: string;
  formKey?: string;
  deploymentName?: string;
  resourceName?: string;
  className?: string;
  style?: {[key: string]: any};
  onChange: () => void;
  onRefreshTarget: () => void;
}

interface DevToolbarState {
  config?: PageDevConfig;
  baseUrl?: string;
  inputValue?: string;
  inputOpen?: boolean;
  inputModified?: boolean;
  settingsOpen?: boolean;
}


export class DevToolbar extends React.Component<DevToolbarProps, DevToolbarState> {
  
  constructor (props: DevToolbarProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.loadConfig()
  }

  async componentDidUpdate(prevProps: DevToolbarProps){
    if (this.props.pageType !== prevProps.pageType || this.props.pageKey != prevProps.pageKey) {
      this.loadConfig()
    }
  }

  private loadConfig() {
    const config = getPageDevConfig(this.props.pageType, this.props.pageKey);
    const baseUrl = getDevTemplatesBaseUrl();
    this.setState({config, baseUrl});
  }

  private setConfig(config: PageDevConfig) {
    savePageDevConfig(this.props.pageType, this.props.pageKey, config);
    this.setState({config});
  }

  private apply = () => {
    const config = this.state.config;
    this.setConfig({...config, template: {...config?.template, url: this.state.inputValue}});
    this.closeInput();
    this.props.onChange && config?.template?.enabled && this.props.onChange();
  }

  private refreshTarget = () => {
    this.props.onRefreshTarget && this.props.onRefreshTarget();
  }

  private enabledChanged = (evt: ChangeEvent<HTMLInputElement>) =>
  {
    const config = this.state.config;
    this.setConfig({...config, template: {...config?.template, enabled: evt.currentTarget.checked}});
    this.props.onChange && this.props.onChange();
  } 
  
  private urlChanged = (evt: FormEvent<HTMLInputElement>) =>
  {
    this.setState({inputValue: evt.currentTarget.value, inputModified: true});
  }

  private openInput = () => {
    this.setState({
      inputOpen: true, inputValue: this.state.config?.template?.url || this.getDefTemplUrl(),
      inputModified: false
    });
    setTimeout(() => {
      this.input?.focus();
      this.input?.select();
    });
  }

  private closeInput = () => {
    this.setState({inputOpen: false})
  }

  private inputModified = () => this.state.inputModified && (this.state.inputValue||'') !== (this.state.config?.template?.url||'');
  
  private input: Input;
  private setInputElement = (input: Input) => {
    this.input = input;
  };

  private getDefTemplUrl() {
    return this.props.defaultTemplate ? concatPath(this.state.baseUrl, this.props.defaultTemplate) : '';
  }

  private openSettings = () => {
    this.setState({settingsOpen: true});
  }

  private closeSettings = () => {
    this.setState({settingsOpen: false});
  }

  private settingsChanged = () => {
    const baseUrl = getDevTemplatesBaseUrl();
    this.setState({baseUrl});
    const config = this.state.config;
    if (config?.template?.enabled && !config?.template?.url) {
      this.props.onChange && this.props.onChange();
    }
  }

  render() {
    return (
      <div 
        className={`dev-toolbar${!this.props.pageKey ? ' disabled' : ''}${this.props.className ? ' ' + this.props.className : ''}`}
        style={this.props.style}
      >
        <span className='header'></span>

        <label className='checkbox' htmlFor='devToolbarCheckbox1'>
          <input 
            type='checkbox'
            id='devToolbarCheckbox1'
            disabled={!this.props.pageKey}
            checked={this.state.config?.template?.enabled || false}
            onChange={this.enabledChanged}
          />
          Use own template:
        </label>

        <Popup
          trigger={<div
            className={
              'text' + 
              (!this.state.config?.template?.url ? ' placeholder' : '') +
              (this.state.inputOpen ? ' focus' : '')
            }
          >
            { this.state.config?.template?.url || this.getDefTemplUrl() || 'set template URL' }
          </div>}
          disabled={!this.props.pageKey}
          open={this.state.inputOpen}
          onOpen={this.openInput}
          onClose={this.closeInput}
          on='click'
          flowing
          size='small'
        >
          <Form
            className='dev-toolbar-url-editor'
            size='tiny'
            onSubmit={this.apply}
          >
            <Input
              size='small'
              fluid
              ref={this.setInputElement}
              value={this.state.inputValue}
              onChange={this.urlChanged}
              action={
                <Button 
                  primary
                  compact
                  size='small'
                  disabled={!this.inputModified()}
                  onClick={this.apply}
                >
                  Set
                </Button>
              }
            />
          </Form>
        </Popup>

        <Button
          icon='refresh'
          size='mini'
          compact
          disabled={!this.props.pageKey}
          onClick={this.refreshTarget}
        />

        <Button
          icon='cog'
          size='mini'
          compact
          onClick={this.openSettings}
        />
        <DevModeSettings
          open={this.state.settingsOpen}
          onChange={this.settingsChanged}
          onClose={this.closeSettings}
        />

      </div>
    );
  }
}

