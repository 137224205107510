import React from "react"
import { TaskListItem } from "../models"
import { Button, Modal, Form, DropdownItemProps } from 'semantic-ui-react'
import { ErrorMessage } from "./ErrorMessage";
import { getBpmRestService } from "../services/BpmRestService";
import { Toaster } from "../services/Toaster";

interface TasklistAssignmentProps {
  open: boolean;
  mode: 'assign' | 'reassign' | 'unassign';
  tasks: () => TaskListItem[];
  assigneeOptions?: DropdownItemProps[];
  toaster: Toaster;
  onClose: (changed: boolean) => void;
}

interface TasklistAssignmentState {
  assignee?: string;
  executing?: boolean;
  error?: Error;
}

export default class TasklistAssignment extends React.Component<TasklistAssignmentProps, TasklistAssignmentState> {

  constructor(props: TasklistAssignmentProps) {
    super(props);
    this.state = {}
  } 

  private async assign(userId: string) {
    this.setState({executing: true})
    try {
      const taskIds = this.props.tasks().map(item => item.id);
      await getBpmRestService().assignTasks(taskIds, userId);
      this.props.toaster?.add({
        type: 'success',
        title: 'Done',
        description: (taskIds.length > 1 ? `${taskIds.length} tasks`: 'Task') +
          (userId ? ` assigned to ${userId}` : ' unassigned'),
        time: 5000
      });
      this.setState({error: null, assignee: null});
      this.props.onClose(true);
    } catch(e) {
      this.setState({error: e})
    } finally {
      this.setState({executing: false})
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <Modal
          open={this.props.open && (this.props.mode === 'assign' || this.props.mode === 'reassign')}
          size='tiny'
        >
          <Modal.Header>{this.props.mode === 'assign' ? 'Assign' : 'Reassign'}</Modal.Header>
            <Modal.Content>
              <Form>
                <ErrorMessage error={this.state.error}/>
                <Form.Field label='Assign selected tasks to:'/>
                <Form.Dropdown
                  placeholder='Select assignee'
                  selection
                  search
                  selectOnBlur={false}
                  options={this.props.assigneeOptions}
                  loading={!this.props.assigneeOptions}
                  value={this.state.assignee}
                  onChange={(e, data: {value: string}) => this.setState({assignee: data.value})}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                disabled={!this.state.assignee}
                loading={this.state.executing}
                onClick={() => {
                  this.assign(this.state.assignee);
                }}
              >
                Submit
              </Button>
              <Button
                negative
                onClick={() => {
                  this.setState({error: null, assignee: null});
                  this.props.onClose(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Actions>
        </Modal>

        <Modal
          open={this.props.open && this.props.mode === 'unassign'}
          size='mini'
        >
          <Modal.Header>Unassign</Modal.Header>
            <Modal.Content>
              <Form>
                <ErrorMessage error={this.state.error}/>
                <Form.Field label='Unassign selected tasks'/>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                onClick={() => {
                  this.assign(null);
                }}
              >
                Ok
              </Button>
              <Button
                negative
                onClick={() => {
                  this.setState({error: null, assignee: null});
                  this.props.onClose(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Actions>
        </Modal>
      </>
    );
  }
}