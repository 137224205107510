import { getAppConfig } from './AppConfig';
import { BaseRestService } from './BaseRestService';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class ReportsRestService extends BaseRestService {

  protected config = getAppConfig();

  protected getBaseUrl() { return this.config.reportsUrl; }

  async getOTP(): Promise<string> {
    const data = await this.get<{ otp: string; }>('otp/underwriter');
    return data.otp;
  }

}

let instance: ReportsRestService;
export const getReportsRestService = () => {
  return instance || (instance = new ReportsRestService());
};
