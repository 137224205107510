import React, { Fragment } from "react";
import { Modal, Button, Form, TextArea, Input, Message } from 'semantic-ui-react';
import { getBpmRestService } from '../services/BpmRestService';
import { DateInput } from 'semantic-ui-calendar-react';
import { TaskListProcessingField, TaskListProcessingButton } from '../models'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

interface TasklistProcessingProps {
  showModal: boolean,
  fields: TaskListProcessingField[],
  buttons: TaskListProcessingButton[],
  list: any
  selectedRows: any,
  handleModal: (showModal: boolean, updateList: boolean) => void,
}

interface TasklistProcessingState {
  newValues: any,
  showCompleteError: boolean,
  errorCompleteText: string,
  isRequiredFieldsError: boolean,
  notFilled: any
}

export default class TasklistProcessing extends React.Component<TasklistProcessingProps, TasklistProcessingState> {
    constructor(props: any) {
        super(props)
        this.state = {
          newValues: {},
          showCompleteError: false,
          errorCompleteText: '',
          isRequiredFieldsError: false,
          notFilled: []
        }
    }

    async changeList() {
      let validationsPassed = true;
      const notFilledValues = [];
      this.props.fields.forEach(item => {
        if (item.required) {
          const val = this.state.newValues[Object.keys(item)[0]];
          if (!val) {
            validationsPassed = false;
            notFilledValues.push(item);
          }
        }
      });
      this.setState({isRequiredFieldsError: !validationsPassed, notFilled: notFilledValues}, () => {
        if (validationsPassed) {
          const taskIds = this.props.selectedRows.map((row) => row.id);
          const varsList = {};
          for (const [key, value] of Object.entries(this.state.newValues)) {
            varsList[key] = {value: value};
          }
          getBpmRestService().completeTasks(taskIds, varsList).then((result) => {
            if (result.status === 200 || result.status === 204) {
              this.setState({showCompleteError: false, errorCompleteText: ''},() => this.props.handleModal(false, true));
            }
          })
          .catch((error) => {
            this.setState({showCompleteError: true, errorCompleteText: error.message});
          })
        }
      })
    }

    async cancelChanges() {
      this.setState({newValues: {}, isRequiredFieldsError: false}, () => this.props.handleModal(false, false));
    }

    setValue(e, data) {
      const varValues = this.state.newValues;
      if (data.name) {
        varValues[data.name] = data.value;
      }
      this.setState({newValues: varValues})
    }

    handleButtonClick(button) {
      const varValues = this.state.newValues;
      if (button.setVar && button.setValue) {
        varValues[button.setVar] = button.setValue;
      }
      this.setState({newValues: varValues})
    }
    
    render() {
        const messageList =  this.state.notFilled.map((item) => {
          const test = Object.entries(item)
          return `${test[0][0]}: ${test[0][1]}`
        })
        return (
          <Modal
            closeOnEscape
            open={this.props.showModal}
          >
            <Modal.Header>Select a user to forward notification</Modal.Header>
            <Modal.Content>
              <Form>
              {
                this.props.fields.map((field) => {
                  return (
                    <Fragment key={field.name}>
                      {field.type === 'textarea' && (
                        <Form.Field
                          control={TextArea}
                          label={field.label}
                          name={field.name}
                          value={this.state.newValues[field.name] ? this.state.newValues[field.name] : ''}
                          onChange={(e, data) => this.setValue(e, data)}
                        />
                      )}
                      {field.type === 'input' && (
                        <Form.Field
                          control={Input}
                          label={field.label}
                          name={field.name}
                          value={this.state.newValues[field.name] ? this.state.newValues[field.name] : ''}
                          onChange={(e, data) => this.setValue(e, data)}
                        />
                      )}
                      {field.type === 'date' && (
                        <DateInput
                          name={field.name}
                          placeholder={field.label}
                          clearable
                          closable={true}
                          autoComplete='off'
                          dateFormat='YYYY-MM-DD'
                          iconPosition='left'
                          value={this.state.newValues[field.name] ? this.state.newValues[field.name] : ''}
                          onChange={(e, data) => this.setValue(e, data)}
                        />
                      )}
                      {field.type === 'dropdown' && (
                        <Form.Dropdown
                          width='5'
                          options={field.options || []}
                          fluid
                          selection
                          clearable
                          closeOnChange
                          selectOnBlur={false}
                          name={field.name}
                          label={field.label}
                          value={this.state.newValues[field.name] ? this.state.newValues[field.name] : ''}
                          onChange={(e, data) => this.setValue(e, data)}
                        />
                      )}
                    </Fragment>
                  )
                })
              }
              <Form.Group>
                {
                  this.props.buttons.map((button) => {
                    return (
                      <Form.Field
                        key={button.id}
                        control={Button}
                        color={button.color}
                        onClick={() => {this.handleButtonClick(button)}}
                      >
                        {button.label}
                      </Form.Field>
                    )
                  })
                }
              </Form.Group>
              {
                this.state.showCompleteError &&
                  <Message negative>
                    <Message.Header>{this.state.errorCompleteText}</Message.Header>
                  </Message>
              }
              {
                this.state.isRequiredFieldsError &&
                  <Message list={messageList} header='Please, fill all required fields' negative />
              }
            </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                onClick={() => this.cancelChanges()}
              >
                Cancel
              </Button>
              <Button
                positive
                onClick={() => this.changeList()}
              >
                Send
              </Button>
            </Modal.Actions>
          </Modal>
        )
    }
}