
import Q from 'q';
import { getHttpClient } from '../../services/HttpClientWithAuth';

function endSuccess(done, deferred) {
  return function(response) {
    if (deferred) {
      deferred.resolve(response);
    }
    return done ? done(null, response) : undefined;
  };
}
  
function endError(done, deferred) {
  return function(err) {
    if (deferred) {
        deferred.reject(err);
    }
    return done ? done(err, null) : undefined;
  };
}

interface CamHttpClientConfig {
  baseUrl: string;
}

interface CamHttpRequestOptions {
  query: string;
  headers: string;
  data: string;
  done: (err: any, data: any) => any;
}


export default class CamHttpClient {

  private httpClient = getHttpClient();

  constructor(private config: CamHttpClientConfig){}

  private url(path: string): string {
    if (/^(http|https|file):\/\/.*/i.test(path))
      return path;
    if (/^\/.*/.test(path))
      return this.config.baseUrl + path;
    return this.config.baseUrl + '/rest/' + path;
  }

  post(path: string, options: CamHttpRequestOptions) {
    const deferred = Q.defer();
    this.httpClient.request('post', this.url(path), {query: options?.query, headers: options?.headers, body: options.data})
      .then(endSuccess(options?.done, deferred))
      .catch(endError(options?.done, deferred));
    return deferred.promise;
  }

  get(path: string, options: CamHttpRequestOptions) {
    const deferred = Q.defer();  
    this.httpClient.request('get', this.url(path), {query: options?.data, headers: options?.headers})
      .then(endSuccess(options?.done, deferred))
      .catch(endError(options?.done, deferred));
    return deferred.promise;
  }

  load(path: string, options: CamHttpRequestOptions) {
    const deferred = Q.defer();  
    this.httpClient.request('get', this.url(path), {query: options?.data, headers: options?.headers})
      .then(endSuccess(options?.done, deferred))
      .catch(endError(options?.done, deferred));
    return deferred.promise;
  }
      
  put(path: string, options: CamHttpRequestOptions) {
    const deferred = Q.defer();
    this.httpClient.request('post', this.url(path), {query: options?.query, headers: options?.headers, body: options.data})
      .then(endSuccess(options?.done, deferred))
      .catch(endError(options?.done, deferred));
    return deferred.promise;
  };
      
  del(path: string, options: CamHttpRequestOptions) {
    const deferred = Q.defer();
    this.httpClient.request('delete', this.url(path), {query: options?.data, headers: options?.headers})
      .then(endSuccess(options?.done, deferred))
      .catch(endError(options?.done, deferred));
    return deferred.promise;
  }
      
  options(path: string, options: CamHttpRequestOptions) {
    const deferred = Q.defer();
    this.httpClient.request('options', this.url(path), {headers: options?.headers})
      .then(endSuccess(options?.done, deferred))
      .catch(endError(options?.done, deferred));
    return deferred.promise;
  }

}