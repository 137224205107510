import React from 'react';
import { Loader, Container, Segment, Message, Popup, Button} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { toast, ToastOptions } from 'react-semantic-toasts';
import { store as toastStore } from 'react-semantic-toasts/build/toast';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { getBpmRestService } from '../services/BpmRestService';
import { ProcessDefinition } from '../models';
import { ReactAngularBridge } from './ReactAngularBridge';
import './cam-form';
import './StartForm.css'
import { ErrorMessage } from './ErrorMessage';
import { DevToolbar, PageDevConfig, getPageDevConfig } from './DevToolbar';
import { getAppConfig } from '../services/AppConfig';
import { getDevTemplatesBaseUrl } from './DevModeSettings';
import { getCurrentUser } from '../services/CurrentUserService';
import { concatPath } from './utils';

export interface StartFormProps {
  procDefKey?: string;
  history?: any;
}

type FormType = 'embedded' | null;

interface StartFormState {
  loading: boolean;
  loadError?: Error;
  formType?: FormType;
  formKey?: string;
  procDef?: ProcessDefinition;
  userId?: string;
  formInitialized?: boolean;
  formError?: string;
  devMode?: boolean;
  pageKey?: string;
  devConfig?: PageDevConfig;
  defaultDevTemplate?: string;
}

interface NotifOptions {
  type: 'info' | 'success' | 'warning' | 'error';
  status: string;
  message: string;
  duration: number;
}


export class StartForm extends React.Component<StartFormProps, StartFormState> {
  
  private bpmService = getBpmRestService();
  private definedFormKey: string;
  private devMode: boolean;
  private pageKey: string;

  constructor (props: StartFormProps) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    getAppConfig().events.on('developerMode', this.updateDevMode);
    const devMode = this.devMode = getAppConfig().developerMode;
    this.setState({devMode});
    this.load()
  }

  async componentDidUpdate(prevProps: StartFormProps){
    if ( this.props.procDefKey !== prevProps.procDefKey) {
      this.clearNotifications();
      this.load()
    }
  }

  componentWillUnmount() {
    getAppConfig().events.off('developerMode', this.updateDevMode);
    this.clearNotifications(); 
  }

  async load() {
    this.setState({
      loading: true,
      loadError: null,
      procDef: null,
      formType: null,
      formKey: null,
      pageKey: null,
      defaultDevTemplate: null,
      devConfig: null,
      formInitialized: false,
      formError: ''
    })
    try {
      const userId = getCurrentUser().getUserId();
      const procDef = await this.bpmService.getProcessDefinition(this.props.procDefKey);
      this.definedFormKey = await this.bpmService.getStartFormKey(procDef.id);
      const pageKey = this.pageKey = procDef.key; 

      this.setState({ 
        procDef,
        pageKey,
        userId,
        loading: false,
        loadError: null,
      })

      this.updateTemplate();

    } catch (e) {
      this.setState({ loading: false, loadError: e });
    }
  }

  private updateTemplate() {
    let formType: FormType;
    let formKey: string;
    let formInitialized = false;
    const defaultDevTemplate = this.definedFormKey?.replace(/^embedded:/, '').replace(/^deployment:/, '');
    const devConfig = this.devMode ? getPageDevConfig('startForm',this.pageKey) : null;

    if (devConfig?.template?.enabled) {
      formType = 'embedded';
      formKey = devConfig.template.url || (defaultDevTemplate ? concatPath(getDevTemplatesBaseUrl(), defaultDevTemplate) : null);
    } else if (/^embedded:/.test(this.definedFormKey)) {
      formType = 'embedded';
      formKey = this.definedFormKey.replace(/^embedded:/, '');
    } else {
      formType = null;
      formKey = this.definedFormKey;
      formInitialized = true; // no form
    }

    this.setState({
      formType,
      formKey,
      defaultDevTemplate,
      formInitialized,
      devConfig
    })
  }

  private updateDevMode = (devMode: boolean) => {
    this.devMode = devMode;
    this.setState({devMode});
    if (getPageDevConfig('startForm', this.pageKey)?.template?.enabled) {
      this.refreshTemplate();
    }
  }

  private devConfigChanged = () => {
    this.refreshTemplate();
  }

  private refreshTemplate = () => {
    this.setState({formInitialized: false, formType: null, formKey: null}, this.updateTemplate);
  }


  private taskChanged() {

  }

  private onFormCompletionCallback() {
    this.taskChanged();
    this.props.history ? this.props.history.goBack() : window.close();
  }
  
  private onFormInitialized() {
    this.setState({
      formInitialized: true,
      formError: ''
    });
  }

  private onFormInitializationFailed(err: Error) {
    this.setState({
      formInitialized: true,
      formError: `Form initialization error: ${err.message}`
    });
  }

  private close = () => {
    if (this.props.history && this.props.history.length > 1) {
      this.props.history.goBack();
    } else {
      window.close();
    }
  }

  private notifs: ToastOptions[] = [];

  private notification(notif: ToastOptions) {
    const ref = {notif: null};
    const delNotif = () => this.notifs.splice(this.notifs.indexOf(ref.notif), 1)
    toast(notif, delNotif, null, delNotif);
    const item = toastStore.data[toastStore.data.length - 1]
    ref.notif = item;
    this.notifs.push(item);
  }

  private clearNotifications() {
    this.notifs.forEach((item) => toastStore.remove(item));
  }

  render() {
    return (<>
      {this.state.devMode ? 
        <DevToolbar
          pageType='startForm'
          pageKey={this.state.pageKey}
          defaultTemplate={this.state.defaultDevTemplate}
          onChange={this.devConfigChanged}
          onRefreshTarget={this.refreshTemplate}
        /> : ''
      }
      {this.state.loading ?
        <Loader active />  
      :
        <Container fluid className='form-page-container'>
          <div className='app-toolbar'>
            <div>
              <div className='app-page-header'>
                {this.state.procDef?.name || this.state.procDef?.key || '-'}
              </div>
            </div>
            <div>
                <Popup
                  trigger={
                    <Button circular basic size='small' icon='close' className='app-toolbar-btn btn-level-up'
                      onClick={ this.close }
                    />
                  }
                  content='Close form'
                  position='bottom left'
                  size="tiny"
                  inverted
                />
            </div>
          </div>

          <ErrorMessage error={this.state.loadError || this.state.formError}/>

          { !this.state.loadError &&
          <>
            <Segment className={'form-container'} style={{marginTop: '0'}}>
              <Loader active={!this.state.formInitialized} />
              <div style={{display: !this.state.formInitialized ? 'none' : ''}}>
                {
                  !this.state.formType &&
                  <Message warning content='Form not provided!'/>
                }
                { this.state.formType === 'embedded' &&
                  <ReactAngularBridge 
                    appName='cam.tasklist.form'
                    template='<div cam-tasklist-form params="params" handlers="handlers" options="options"/>'
                    bindings={{
                      params: {
                        processDefinitionId: this.state.procDef.id,
                        formKey: this.state.formKey.replace(/^embedded:/, ''),
                        authentication: {name: this.state.userId},
                      },
                      handlers: {
                        onFormCompletionCallback: () => this.onFormCompletionCallback(),
                        onFormInitialized: () => this.onFormInitialized(),
                        onFormInitializationFailed: (err: Error) => this.onFormInitializationFailed(err),
                        onNotification: (notifOpt: NotifOptions) => this.notification({
                          type: notifOpt.type,
                          title: notifOpt.status,
                          description: notifOpt.message,
                          time: notifOpt.duration || 0
                        }),
                        onClearNotifications: () => this.clearNotifications()
                      },
                      options: {
                      }
                    }}
                  />
                }
              </div>
            </Segment>
          </>
          }
        </Container>
      }
    </>)
  }
}
  
