
import React from 'react';
import { getBpmRestService } from '../services/BpmRestService';
import { ColDef, ColumnEvent, ColumnState, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { Group, TaskListItem } from '../models';
import { Popup, Button, Container, DropdownItemProps } from 'semantic-ui-react'
import { AgGridReact } from '@ag-grid-community/react';
import { TaskForm } from './TaskForm'
import { ErrorMessage } from './ErrorMessage';
import { getCurrentUser } from '../services/CurrentUserService';
import './ag-grid-modules';
import './ag-grid.css';
import 'semantic-ui-css/semantic.min.css';
import '../styles.css';
import './Supervisor.css'
import './TasklistGroup.css';
import { Toaster } from '../services/Toaster';
import TasklistAssignment from './TasklistAssignment';
import { formatDateTimeM, compareDates, compareFilterDates, formatAge, highlightDueDate } from './utils';

export interface SupervisorProps {
  teamId?: string;
  taskId?: string;
  query?: string;
  history?: any;
}

interface SupervisorState {
    tasks?: TaskListItem[];
    loadError?: Error;
    loading: boolean;
    reloadDisabled: boolean;
    showFilter?: boolean;
    defaultColDef: ColDef;
    gridColDefs: ColDef[];
    team?: Group;
    assigneeOptions?: DropdownItemProps[];
    showAssign?: boolean;
    showUnassign?: boolean;
    hasSelectedRows?: boolean;
}

export class Supervisor extends React.Component<SupervisorProps, SupervisorState> {
  private toaster = new Toaster();
  private gridApi: GridApi<TaskListItem>;
  private filterState: any;
  private ProcDefMap: {[key: string]: string};
  private dirty: boolean;
  private gridColState: ColumnState[];

  private defaultColDef: ColDef = {
    autoHeight: true,
    cellClass: 'cell-wrap-text',
    resizable: true,
    filter: true,
    floatingFilter: false,
    filterParams: {
      newRowsAction: 'keep',
      suppressMiniFilter: true,
      buttons: ['reset']
    },
    suppressMenu: true
  };

  private columnDefs: ColDef<TaskListItem>[] = [
    {
      colId: 'selected',
      headerName: '',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      width: 50,
      showDisabledCheckboxes: true,
      checkboxSelection: true,
      filter: false
    },
    {
      colId: 'assigneeName',
      headerName: 'Assignee Name',
      headerTooltip: 'Assigned to user',
      field: 'assignee',
      width: 300,
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      colId: 'procDefName',
      headerName: 'Process name',
      valueGetter: params => this.ProcDefMap[params.data.procDefKey],
      width: 280,
      sortable: true,
      cellClass: 'cell-wrap-text',
      filter: 'agTextColumnFilter'
    },
    {
      colId: 'name',
      headerName: 'Task name',
      field: 'name',
      width: 500,
      sortable: true,
      cellRenderer: params => this.getListItemLink(params),
      cellClass: 'cell-wrap-text',
      filter: 'agTextColumnFilter'
    },
    {
      colId: 'created',
      headerName: 'Created',
      field: 'created',
      sortable: true,
      width: 160,
      valueFormatter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2),
      filter: 'agDateColumnFilter',
      filterParams: { 
        newRowsAction: 'keep',
        comparator: compareFilterDates,
        buttons: ['reset'],
        inRangeInclusive: true,
        suppressAndOrCondition: true,
      }
    },
    {
      colId: 'assigned',
      headerName: 'Assigned',
      field: 'assigned',
      sortable: true,
      width: 160,
      valueFormatter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2),
      filter: 'agDateColumnFilter',
      filterParams: { 
        newRowsAction: 'keep',
        comparator: compareFilterDates,
        buttons: ['reset'],
        inRangeInclusive: true,
        suppressAndOrCondition: true,
      }
    },
    {
      colId: 'due',
      headerName: 'Due',
      field: 'due',
      sortable: true,
      width: 160,
      valueFormatter: params => formatAge(params.value),
      tooltipValueGetter: params => formatDateTimeM(params.value),
      comparator: (d1, d2) => compareDates(d1, d2),
      cellStyle: params => highlightDueDate(params.value),
      filter: 'agDateColumnFilter',
      filterParams: { 
        newRowsAction: 'keep',
        comparator: compareFilterDates,
        buttons: ['reset'],
        inRangeInclusive: true,
        suppressAndOrCondition: true
      }
    }
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      reloadDisabled: false,
      defaultColDef: this.defaultColDef,
      gridColDefs: this.columnDefs
    }
  }

  async componentDidMount() {
    try {
      const resp = await getBpmRestService().getProcessDefinitions();
      this.ProcDefMap = {};
      resp.forEach(item => this.ProcDefMap[item.key] = item.name)

      this.update();
    } catch (e) {
      this.setState({ loadError: e })
    }
  }

  async componentDidUpdate(prevProps: SupervisorProps) {
    if (this.props.teamId !== prevProps.teamId) {
      this.update();
      this.toaster.clear();
    } else if (this.dirty && !this.props.taskId) {
      this.dirty = false;
      this.loadList();
    }
  }

  componentWillUnmount(): void {
    this.toaster.clear();
  }

  async update() {
    try {
      const teamId = this.props.teamId;
      const team = (await getCurrentUser().getSupervisedGroups()).find(item => item.id === teamId);
      this.setState({ team, assigneeOptions: null, tasks: null}, this.loadList)
    } catch (e) {
      this.setState({ loadError: e });
    }
  }

  private async loadAssigneeOptions() {
    if (this.state.assigneeOptions) {
      return;
    }
    try {
      const teamId = this.state.team?.id;
      if (teamId) {
        const userOptions = (await getBpmRestService().getUsersFromGroup(teamId))
          .map(user => ({key: user.id, value: user.id, text: `${user.firstName} ${user.lastName} (${user.id})`}));
        this.setState({assigneeOptions: userOptions})
      }
    } catch (e) {
      this.setState({ loadError: e})
    }  
  }

  private async loadList() {
    this.setState({ loading: true, reloadDisabled: true });
    setTimeout(() => this.setState({ reloadDisabled: false }), 4000);
    try {
      const teamId = this.state.team?.id;
      if (teamId) {
        const tasks = await getBpmRestService().getAssignedTasks({userGroups: [teamId]});
        this.setState({tasks});
      } else {
        this.setState({tasks: []})
      }
    } catch (e) {
        this.setState({loadError: e, tasks: []})
    } finally {
        this.setState({loading: false, hasSelectedRows: false});
    }
  }

  private getListItemLink(params: any) {
    const href = `#/tasklist/supervisor/task/${params.data?.id}${this.props.query || ''}`;
    return <a href={href}>{params.value}</a>;
  }

  private toggleFilter = () => {
    const showFilter = !this.state.showFilter;
    const defaultColDef = {...this.defaultColDef, floatingFilter: showFilter};
    const colState = this.gridApi?.getColumnState();
    const gridColDefs = colState ? colState.map(s => {
        const d = this.state.gridColDefs.find(d => d.colId === s.colId);
        d.width = s.width;
        return d;
      }) : this.state.gridColDefs;
    this.setState({showFilter, defaultColDef, gridColDefs}, () => {
      this.gridApi?.applyColumnState({state: colState, applyOrder: true});
    });
    if (this.gridApi) {
      if (showFilter) {
        this.gridApi.setFilterModel(this.filterState);
      } else {
        this.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(null);
      }
    }
  }

  private gridReady = (evt: GridReadyEvent) => {
    this.gridApi = evt.api;
    this.gridApi.applyColumnState({state: this.gridColState, applyOrder: true});
  }

  private selectRow = () => {
    this.setState({hasSelectedRows: this.gridApi?.getSelectedRows().length > 0});
  }

  private colStateChanged = (evt: ColumnEvent) => {
    this.gridColState = evt.api.getColumnState();
  }

  render() {
    return (
      <>
      { this.props.taskId &&
      <TaskForm
        taskId={this.props.taskId}
        history={this.props.history}
        onTaskChanged={() => this.dirty = true}
      /> }
      
      <Container fluid className='page-container' style={{display: this.props.taskId ? 'none' : ''}}>
        <div className='app-toolbar'>
          <div>
            <Popup
              trigger={
                <Button circular basic icon='refresh' size='medium' className='app-toolbar-btn'
                  disabled = { this.state.reloadDisabled }
                  loading = { this.state.loading }
                  onClick = { () => { this.loadList() } }
                />
              }
              content='Refresh the list'
              position='bottom left'
              size="tiny"
              inverted      
            />
            <div className='app-page-header'>
              Tasks assigned to members of {this.state.team?.name || this.state.team?.id || '?'}
             </div>
          </div>
          <div>
            <Popup
              trigger={
                <Button 
                  circular
                  icon='filter'
                  size='medium'
                  className='app-toolbar-btn'
                  color='linkedin'
                  basic={!this.state.showFilter}
                  onClick={() => this.toggleFilter()}
                />
              }
              content={this.state.showFilter ? 'Close filter' : 'Open filter'}
              position='bottom right'
              size="tiny"
              inverted      
            />
          </div>
        </div>

        <ErrorMessage error={this.state.loadError}/>

        <div className='ag-theme-alpine app-tasklist-grid' style={{height: 'calc(100vh - 160px)'}}>
          <AgGridReact
            onGridReady={this.gridReady}
            onSortChanged={this.colStateChanged}
            onColumnResized={this.colStateChanged}
            onColumnMoved={this.colStateChanged}
            onRowSelected={this.selectRow}
            pagination={false}
            domLayout='normal'
            tooltipShowDelay={500}
            rowSelection='multiple'
            enableCellTextSelection={true}
            defaultColDef = {this.state.defaultColDef}
            columnDefs = {this.state.gridColDefs}
            rowData={this.state.tasks}
          /> 
          <div className='processing-bar'>
            <Popup
              trigger={
                <span>
                  <Button
                    disabled={!this.state.hasSelectedRows || this.state.loading}
                    color='green'
                    onClick={() => {
                      this.loadAssigneeOptions();
                      this.setState({showAssign: true})
                    }}
                  >
                    Reassign
                  </Button>
                </span>
              }
              content={this.state.hasSelectedRows ? 'Reassign selected tasks' : 'No tasks selected'}
              position='top left'
              offset={[0, 10]}
              size="tiny"
              inverted      
            />
            <Popup
              trigger={
                <span>
                  <Button
                    disabled={!this.state.hasSelectedRows || this.state.loading}
                    onClick={() => {
                      this.setState({showUnassign: true})
                    }}
                  >
                    Unassign
                  </Button>
                </span>
              }
              content={this.state.hasSelectedRows ? 'Unassign selected tasks' : 'No tasks selected'}
              position='top left'
              offset={[0, 10]}
              size="tiny"
              inverted      
            />
          </div>
        </div>
        <TasklistAssignment
          open={this.state.showAssign || this.state.showUnassign}
          mode={this.state.showAssign ? 'reassign' : 'unassign'}
          toaster={this.toaster}
          tasks={() => this.gridApi.getSelectedRows()}
          assigneeOptions={this.state.assigneeOptions}
          onClose={(changed) => {
            this.setState({showAssign: false, showUnassign: false});
            if (changed) {
              this.loadList();
            }
          }}
        />
      </Container>
      </>
    );
  }
}