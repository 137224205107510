import React from "react";
import { Grid, Header, Segment, Message, Button } from 'semantic-ui-react';
import './ExternalLogin.css';

export interface ExternalLoginProps {
  message?: string,
  caption?: string,
  openLoginPage?: () => void;
  onClose?: () => void;
}

interface ExternalLoginState {
}

export class ExternalLogin extends React.Component<ExternalLoginProps, ExternalLoginState> {

  constructor (props: ExternalLoginProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid textAlign='center'  verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 400 }}>
          <Header as='h2' color='black' textAlign='center'>
            <i className='logo-icon'/>
            Log in to your account
          </Header>
          <Segment basic>
            {this.props.message &&
              <Message>
                {this.props.message}
              </Message>
            }
            <Button
                fluid
                color='linkedin'
                size='large'
                onClick={() => this.props.openLoginPage()}
                active={false}
              >
                {this.props.caption||'Go to login page'}
              </Button>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }

}