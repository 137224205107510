import { getAuthService } from './AuthService';
import { getBpmRestService } from './BpmRestService';
import { User, Group } from '../models/Identity';

export class CurrentUserService {

  private cache: {
    userId?: string,
    user?: User,
    groups?: Group[],
    supervisedGroups?: Group[]
  } = {};

  private checkCache() {
    const userId = getAuthService().getUserId();
    if (userId !== this.cache.userId) {
      this.cache = {userId};
    }
  }

  getUserId() {
    this.checkCache();
    return this.cache.userId;
  }


  async getUser(): Promise<User | null> {
    const userId = this.getUserId();
    if (!userId) {
      return null;
    }
    if (this.cache.user) {
      return this.cache.user;
    }
    const user = await getBpmRestService().getUser(userId);
    if(userId !== this.getUserId()) {
      return this.getUser();
    }
    return this.cache.user = user;
  }
  

  async getUserGroups(): Promise<Group[] | null>  {
    const userId = this.getUserId();
    if (!userId) {
      return null;
    }

    if (this.cache.groups) {
      return this.cache.groups;
    }

    const groups = await getBpmRestService().getUserGroups(userId);
    if(userId !== this.getUserId()) {
      return this.getUserGroups();
    }
    return this.cache.groups = groups;
  }


  async getSupervisedGroups(): Promise<Group[] | null>  {
    const userId = this.getUserId();
    if (!userId) {
      return null;
    }

    if (this.cache.supervisedGroups) {
      return this.cache.supervisedGroups;
    }

    const groups = await this.getUserGroups();

    const svGroupIds = groups.filter((item) => /-teamlead$/.test(item.id)).map(item => item.id.replace(/-teamlead$/, '-team'));
    if (svGroupIds.length === 0) {
      const svGroups = [];
      return this.cache.supervisedGroups = svGroups;
    } 
    
    let svGroups = groups.filter((item) => svGroupIds.includes(item.id));
    if(svGroups.length === svGroupIds.length) {
      return this.cache.supervisedGroups = svGroups;
    }
    
    svGroups = await getBpmRestService().getGroups(svGroupIds);
    if (userId !== this.getUserId()) {
      return this.getSupervisedGroups();
    }
    return this.cache.supervisedGroups = svGroups;
  }

}

let instance: CurrentUserService;
export const getCurrentUser = () => {
  return instance || (instance = new CurrentUserService());
};