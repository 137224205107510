window['jQuery'] = require('jquery');
import React from 'react';
import angular from 'angular';

interface IComponentProps {
  appName: string; // The angular module name
  template: string; // The angular root component considering bindings are set on $rootScope `<my-component></my-component>`
  bindings?: IComponentBindings; // The optional bindings object
}

interface IComponentBindings {
    [key:string]: any
}

const angularStylesheet = `<style>
@charset "UTF-8";
[ng\\:cloak],[ng-cloak],[data-ng-cloak],[x-ng-cloak],.ng-cloak,.x-ng-cloak,.ng-hide:not(.ng-hide-animate){display:none !important;}
ng\\:form{display:block;}
.ng-animate-shim{visibility:hidden;}
.ng-anchor{position:absolute;}
</style>
`;

export class ReactAngularBridge extends React.Component<IComponentProps, Record<string, never>> {
  protected ref: React.RefObject<HTMLDivElement> = React.createRef();

  protected $rootScope: angular.IRootScopeService;

  protected $shadowRoot: ShadowRoot;

  public get $element(): HTMLDivElement | null {
      return this.ref.current;
  }

  public componentDidMount(): void {
    this.createApp().bootstrap();
  }

  public componentDidUpdate(prevProps: IComponentProps): void {
    this.updateBindings(this.props.bindings, prevProps.bindings);
  }

  public componentWillUnmount(): void {
    this.destroyNgApp();
  }

  public render(): React.ReactNode {
    return (
      <div ref={this.ref}/>
    );
  }

  protected createApp(): ReactAngularBridge {
      if (!this.$element) 
        this.onElementMissing();

      angular.module(this.props.appName)
          .run(['$rootScope', ($rootScope: angular.IRootScopeService) => {
              this.$rootScope = $rootScope;
              this.updateBindings(this.props.bindings);
          }]);

      this.$shadowRoot = this.$element.attachShadow({ mode: 'open' });

      this.$shadowRoot.innerHTML = angularStylesheet + this.props.template;

      return this;
  }

  protected bootstrap(): ReactAngularBridge {
      if (!this.$element)
        this.onElementMissing();
      angular.bootstrap(this.$shadowRoot.children[1], [this.props.appName]);
      return this;
  }

  protected destroyNgApp(): ReactAngularBridge {
      this.$rootScope.$destroy();
      if (this.$shadowRoot) {
        while (this.$shadowRoot.firstChild) {
          this.$shadowRoot.removeChild(this.$shadowRoot.firstChild);
        }
      }
      return this;
  }

  protected updateBindings(bindings: IComponentBindings, prevBindings: IComponentBindings = {}): ReactAngularBridge {
      if (bindings) {
        let changed = false;
        Object.getOwnPropertyNames(bindings).forEach((key: string) => {
          if (bindings[key] !=  prevBindings[key]) {
            this.$rootScope[key] = bindings[key];
            changed = true;
          }
        });
        if (changed) {
          const phase = this.$rootScope.$$phase;
          if (phase !== '$apply' && phase !== '$digest') {
            this.$rootScope.$apply();
          }
        }
      }
      return this;
  }

  protected onElementMissing(): void {
      throw new Error(`Could not create the angularJS application due to a missing element reference`);
  }
}
