import { ModuleRegistry } from '@ag-grid-community/core';     // @ag-grid-community/core will always be implicitly available
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
//import { CsvExportModule } from "@ag-gridC-community/csv-export";
//import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
// import { MenuModule } from "@ag-grid-enterprise/menu";
// import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
// import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
// import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
//import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
// import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
//import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
// import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
//import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
// import { StatusBarModule } from "@ag-grid-enterprise/status-bar";

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
   // RowGroupingModule,
   // SetFilterModule,
   // MultiFilterModule,
   // MenuModule,
   // RangeSelectionModule,
   // ClipboardModule,
   // StatusBarModule,
]);
