import JSON5 from 'json5';
import { BaseBpmRestService } from './BaseBpmRestService';
import { TaskGroup, TaskGroupMetadata, TaskDefKey, TaskListItem, TaskInstance, TaskWork, ProcessDefinition,
  ProcessInstance, ProcInstListReq, ResourceRef, ProcInfoViewResource, VariableValue, VariablesMap, Incident, User, Group, Dictionaries, TaskListTemplate } from '../models';

type UserSortBy = 'userId' | 'firstName' | 'lastName' | 'email';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class BpmRestService extends BaseBpmRestService {

  getTaskGroups(claimedOnly: boolean): Promise<TaskGroup[]> {
    if (claimedOnly) return this.get('ext/tasklist/groups?claimedOnly=true')
    return this.get('ext/tasklist/groups')
  }

  getTaskGroupMetadata(groupId: string, procDefKey: string, taskDefKey: string): Promise<TaskGroupMetadata> {
    return this.get('ext/tasklist/group-definition', {groupId, procDefKey, taskDefKey});
  }

  getTaskGroup(taskDefKeyList: TaskDefKey[], variables: string, needBusinessKey: boolean , claimedOnly: boolean): Promise<TaskListItem[]> {
    const taskDefKeys = taskDefKeyList.map(item => item.procDefKey + ':' + item.taskDefKey).join(',');
    return this.get('ext/tasklist/group-items', {taskDefKeys, variables, needBusinessKey, claimedOnly});
  }

  getDeploymentResource(deploymentId: string, resourceId: string) {
    return this.get(`deployment/${deploymentId}/resources/${resourceId}/data`)
  }

  getTaskListTemplate(deploymentId: string, resourceId: string): Promise<TaskListTemplate> {
    return this.getDeploymentResource(deploymentId, resourceId);
  }

  getTaskListCustomTemplate(url: string): Promise<TaskListTemplate> {
    return this.get(url);
  }

  getAssignedTasks(assignedTo: {userGroups?: string[], users?: string[]}): Promise<TaskListItem[]> {
    return this.get('ext/tasklist/assigned', {userGroups: assignedTo.userGroups?.join(','), users: assignedTo.users?.join(',')});
  }

  getCompletedTasks(userId: string, finishedAfter: Date, finishedBefore: Date): Promise<TaskInstance[]> {
    return this.get('history/task', {finished: true, taskDeleteReason: 'completed', taskAssignee: userId, finishedAfter, finishedBefore});
  }

  setTaskViewed(taskId: string, viewed: boolean) {
    return this.post(`ext/task/${taskId}/viewed`, null, viewed);
  }

  getTaskFormKey(taskId: string): Promise<any> {
    return this.get(`task/${taskId}/form`);
  }

  getTaskWork(taskId: string): Promise<TaskWork[]> {
    return this.get(`ext/task/${taskId}/work`);
  }

  completeTasks(taskIds: string[], variables: any): Promise<any> {
    return this.post('ext/tasklist/complete', null, {taskIds, variables});
  }

  getStartableProcesses(): Promise<ProcessDefinition[]> {
    return this.get('ext/process-definition/startable-in-tasklist');
  }

  getProcessDefinition(procDefKey: string): Promise<ProcessDefinition> {
    return this.get(`process-definition/key/${procDefKey}`);
  }

  getProcessDefinitionById(processDefinitionId: string): Promise<ProcessDefinition> {
    return this.get('process-definition', {processDefinitionId}).then(list => list.length ? list[0] : null);
  }

  getStartFormKey(processDefinitionId: string): Promise<string> {
    return this.get(`process-definition/${processDefinitionId}/startForm`).then(form => form.key);
  }

  getProcessDefinitionXml(processDefinitionId: string): Promise<string> {
    return this.get(`process-definition/${processDefinitionId}/xml`).then(resp => resp.bpmn20Xml);
  }

  getProcessDefinitions(): Promise<ProcessDefinition[]> {
    return this.get('process-definition', {latestVersion: true});
  }

  findProcessInstances(params: ProcInstListReq, withVariables: boolean, maxResults: number): Promise<ProcessInstance[]> {
    return this.post('ext/history/process-instance', {maxResults, withVariables}, params);
  }

  getProcessInstance(id: string): Promise<ProcessInstance> {
    return this.get<ProcessInstance>(`history/process-instance/${id}`).then((procInst) => {
      procInst.startTime && (procInst.startTime = new Date(procInst.startTime));
      procInst.endTime && (procInst.endTime = new Date(procInst.endTime));
      return procInst;
    });
  }

  getProcessInstances(ids: string[]): Promise<ProcessInstance[]> {
    if(ids.length === 0) {
      return Promise.resolve([]);
    }
    return this.post<ProcessInstance[]>('history/process-instance', null, {processInstanceIds: ids}).then((list) => {
      list.forEach((procInst) => {
        procInst.startTime && (procInst.startTime = new Date(procInst.startTime));
        procInst.endTime && (procInst.endTime = new Date(procInst.endTime));
      });
      return list;
    });
  }

  getChildProcessInstances(id: string): Promise<ProcessInstance[]> {
    return this.post<ProcessInstance[]>(`history/process-instance`, {}, {superProcessInstanceId: id, sortBy: 'startTime', sortOrder: 'asc'}).then((list) => {
      list.forEach((procInst) => {
        procInst.startTime && (procInst.startTime = new Date(procInst.startTime));
        procInst.endTime && (procInst.endTime = new Date(procInst.endTime));
      });
      return list;
    });
  }

  getProcessInstanceVariables(processInstanceId: string, variableNames: string[]): Promise<VariablesMap> {
    const queryParams: any = {};
    queryParams.deserializeValues = false;
    if (variableNames) {
      queryParams.variableNames = variableNames.join(',');
    }
    return this.get(`ext/history/process-instance/${processInstanceId}/variables`, queryParams).then(
      vars => {
        Object.entries(vars).forEach(entry => {
          const v = entry[1] as VariableValue;
          if((v.type === 'Json' || v.type === 'Object') && v.value) {
            v.value = JSON.parse(v.value);
          } else if (v.type === 'Date') {
            v.value = new Date(v.value);
          }
        });
        return vars;
      }
    );
  }

  getProcessInstanceIncidents(processInstanceId: string): Promise<Incident[]> {
    return this.get(`ext/process-instance/${processInstanceId}/incidents`, {recursive: true}).then((list: Incident[]) => {
      list.forEach((item) => {
        item.incidentTimestamp && (item.incidentTimestamp = new Date(item.incidentTimestamp));
        if (item.causeIncidentId && item.causeIncidentId !== item.id) {
          item.causeIncident = list.find(el => el.id === item.causeIncidentId);
        }
        if (item.rootCauseIncidentId && item.rootCauseIncidentId !== item.id) {
          item.rootCauseIncident = list.find(el => el.id === item.rootCauseIncidentId);
        }
      });
      return list.filter(item => item.processInstanceId === processInstanceId);
    });
  }

  getProcessInstanceTasks(processInstanceId: string): Promise<TaskInstance[]> {
    return this.get<TaskInstance[]>('history/task', {processInstanceId, sortBy: 'startTime', sortOrder: 'asc'}).then(
      list => {
        let completed = false;
        list.forEach((item) => {
          item.startTime && (item.startTime = new Date(item.startTime));
          item.endTime && (item.endTime = new Date(item.endTime));
          item.due && (item.due = new Date(item.due));
          item.followUp && (item.followUp = new Date(item.followUp));
          if (item.deleteReason === 'completed') {
            completed = true;
          }
        });
        if (completed) {
          return this.get<any[]>('history/user-operation', {processInstanceId, entityType: 'Task', operationType: 'Complete'}).then(
            opList => {
              list.forEach((item) => {
                if (item.deleteReason === 'completed') {
                  item.performer = opList.find(op => op.taskId === item.id)?.userId;
                }
              });
              return list;   
            }

          );
        }
        return list;
      }
    );
  }

  getTask(taskId: string, historic: boolean): Promise<TaskInstance> {
    return this.get<TaskInstance>(`ext/${historic ? 'history/' : ''}task/${taskId}`).then(
      task => {
        task.startTime && (task.startTime = new Date(task.startTime));
        task.endTime && (task.endTime = new Date(task.endTime));
        task.due && (task.due = new Date(task.due));
        task.followUp && (task.followUp = new Date(task.followUp));
        return task;
      }
    );
  }

  claimTask(taskId: string, userId: string): Promise<void> {
    return this.post(`task/${taskId}/claim`, null, {userId});
  }

  unclaimTask(taskId: string): Promise<void> {
    return this.post(`task/${taskId}/unclaim`, null, null);
  }

  assignTask(taskId: string, userId: string) {
    return this.post(`task/${taskId}/assignee`, null, {userId})
  }

  claimTasks(taskIds: string[], userId: string): Promise<void> {
    return this.post(`ext/tasklist/claim`, {userId}, taskIds);
  }

  unclaimTasks(taskIds: string[]): Promise<void> {
    return this.post(`ext/tasklist/unclaim`, null, taskIds);
  }

  assignTasks(taskIds: string[], userId: string): Promise<void> {
    return this.post(`ext/tasklist/assign`, null, {taskIds, userId});
  }

  getActivityInstances(processInstanceId: string): Promise<TaskInstance[]> {
    return this.get('history/activity-instance', {processInstanceId});
  }

  getResource(deploymentId: string, resourceId: string): Promise<string> {
    return this.get(`deployment/${deploymentId}/resources/${resourceId}/data`);
  }

  getProcessInfoViewResource(processDefinitionId: string): Promise<ProcInfoViewResource> {
    return this.get<ResourceRef>(`ext/process-definition/${processDefinitionId}/process-info-view-resource`).then((resRef) => {
      const res: ProcInfoViewResource = resRef;
      if (!(resRef.deploymentId && resRef.resourceId)) {
        return res;
      }
      return this.getResource(resRef.deploymentId, resRef.resourceId).then((resBody) => {
        if (/\.json5$/.test(resRef.resourceKey)) {
          res.template = JSON5.parse(resBody);
        } else {
          res.template = JSON.parse(resBody);
        }
        res.template.dataView.sections.forEach(section => {
          section.items.forEach(item => {
            if (item.formatter) {
              //eslint-disable-next-line no-new-func
              item.formatter = new Function(`return (${item.formatter})`)();
            }
            item.variable = item.variable ? (item as any).variable.split(',') : [];
          })
        });
        return res;
      })
    });
  }

  getUser(id: string): Promise<User> {
    return this.get('user', {id}).then(list => list[0]);
  }

  getUsers(ids: string[], sortBy: UserSortBy = 'userId'): Promise<User[]> {
    const idIn = ids.join(',');
    return this.get('user', {idIn, sortBy, sortOrder: 'asc'});
  }
  
  getUsersFromGroup(groupId: string, sortBy: UserSortBy = 'userId'): Promise<User[]> {
    return this.get('user', {memberOfGroup: groupId, sortBy, sortOrder: 'asc'})
  }

  getUsersFromGroups(groupIds: string[], sortBy: UserSortBy = 'userId'): Promise<User[]> {
    return this.get('ext/user', {memberOfAnyGroup: groupIds.join(','), includeMembership: true, sortBy, sortOrder: 'asc'});
  }

  getUserGroups(member: string): Promise<Group[]> {
    return this.get('group', {member})
  }

  getGroups(ids: string[]): Promise<Group[]> {
    return this.get('group', {idIn: ids.join(',')})
  }

  getDictionaries(types: string[], includeDeleted: boolean): Promise<Dictionaries> {
    return this.get('/ext/dictionary/entries', {types: types?.join(','), includeDeleted});
  }

}

let instance: BpmRestService;
export const getBpmRestService = () => {
  return instance || (instance = new BpmRestService());
};
